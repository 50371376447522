import DocumentHandler from "./DocumentHandler";

const PizZip = require("pizzip");
const Docxtemplater = require("docxtemplater");

export const MICROSOFT_DOC_TEMPLATE_START_DELIMITER = "<";
export const MICROSOFT_DOC_TEMPLATE_END_DELIMITER = ">";

export default class WordHandler extends DocumentHandler {
  public generateDocument(content: ArrayBuffer | string, data: any): any {
    return new Promise((resolve, reject) => {
      try {
        const zip = new PizZip(content);
        const doc = new Docxtemplater(zip, {
          paragraphLoop: true,
          linebreaks: true,
          delimiters: {
            start: MICROSOFT_DOC_TEMPLATE_START_DELIMITER,
            end: MICROSOFT_DOC_TEMPLATE_END_DELIMITER,
          },
          nullGetter: this.nullGetter,
          syntax: {
            allowUnopenedTag: true,
          },
        });
        doc.setData(data);

        doc.render();
        const out = doc.getZip().generate({
          type: "arraybuffer",
          mimeType:
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
          compression: "DEFLATE",
        });
        resolve(out);
      } catch (error) {
        reject(error);
      }
    });
  }

  private nullGetter(part: any) {
    if (!part.module) {
      return `<${part.value}>`;
    }
    if (part.module === "rawxml") {
      return "";
    }
    return "";
  }
}
