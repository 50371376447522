import {
  CostTableReturn,
  CostWithPermissions,
} from "src/accurasee-backend-types/app/cost/cost.types";
import { MUIDataTableColumnDef } from "mui-datatables";
import { useGetArticlesQuery } from "src/redux/services/ArticleService";
import { useGetCompanyQuery } from "src/redux/services/CompanyService";
import { useGetCostsTableQuery } from "src/redux/services/CostService";
import { useGetReportTemplateYFiltersQuery } from "src/redux/services/ReportTemplateFilterService";
import { useGetSubcontractsQuery } from "src/redux/services/SubcontractService";
import { useParams } from "react-router-dom";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import BuilderColumnOptionsSingle from "src/components/Builders/Table/BuilderColumnOptionsSingle";
import CostsSubtable from "./CostSubtable";
import ForwardCostsModal from "src/components/Complex/Modals/ForwardCostsModal";
import getStructure, {
  GetStructureExtraProps,
} from "src/screens/App/Contracts/[contractId]/Costs/components/ContractCostsTableStructure";
import MuiDataTableWrapper from "src/components/Basic/Mixed/Tables/MuiDataTableWrapper";
import ReassignCostsModal from "src/components/Complex/Modals/ReassignCostModal";
import SubTableContainer from "src/components/Basic/Mixed/Tables/SubTableContainer";
import useFormTable from "src/hooks/useFormTable";
import useMUIDataTableOptionsServerSide from "src/hooks/useMUIDataTableOptionsServerSide";

const getForwardableCosts = (costs?: CostTableReturn[]) => {
  return (
    costs
      ? costs.flatMap((cost) =>
          cost.costs.filter(
            (cost) =>
              !cost?.forwardedToLinkedInvoiceDataId &&
              !cost?.subcontractId &&
              !!cost.articleId,
          ),
        )
      : []
  ) as CostWithPermissions[];
};

const getReassignableCosts = (costs?: CostTableReturn[]) => {
  return (
    costs
      ? costs.flatMap((cost) =>
          cost.costs.filter(
            (cost) =>
              !cost?.subcontractId &&
              cost.costParent === "voucher" &&
              cost.costType === "supplier-invoice",
          ),
        )
      : []
  ) as CostWithPermissions[];
};

const ContractCostsTable = () => {
  const { t } = useTranslation();
  const { contractId } = useParams<{ contractId: string }>();
  const [openForwardModal, setOpenForwardModal] = useState(false);
  const [openReassignModal, setOpenReassignModal] = useState(false);

  const { data: articlesQueryResult, isLoading: isLoadingArticles } =
    useGetArticlesQuery(undefined);

  const {
    data: reportFiltersQuery,
    isLoading: isAllReportTemplateYFiltersLoading,
  } = useGetReportTemplateYFiltersQuery(undefined);
  const reportYFilters =
    reportFiltersQuery?.data?.filter(
      (filter) => filter.transactionType === "cost",
    ) || [];

  const { data: subContractQueryResult, isLoading: isLoadingSubcontracts } =
    useGetSubcontractsQuery({ contractId });

  const articles = articlesQueryResult?.data || [];
  const subcontracts = subContractQueryResult?.data || [];

  const [fileDownloading, setFileDownloading] = useState({});

  const { data: companyQuery, isLoading: isLoadingCompany } =
    useGetCompanyQuery(undefined);

  const {
    options,
    data: costs,
    isLoading: isLoadingCosts,
  } = useMUIDataTableOptionsServerSide<CostTableReturn>({
    dataName: "contracts",
    defaultPaginationOptions: [10, 15, 50],
    filterInit: {
      base: {
        sortBy: "transactionDate",
        sort: "desc",
      },
      match: {
        contractId,
      },
    },
    filterOptions: {
      selectableRows: "none",
      selectToolbarPlacement: "none",
      searchPlaceholder: `${t("Search for")} ${t(
        "Description",
      ).toLowerCase()}, ${t("Account number").toLowerCase()} ${t("or")} ${t(
        "Account name",
      ).toLowerCase()}...`,
      customButtonsOnToolbar: [
        {
          actionFunction: () => setOpenForwardModal(true),
          showButton: ({ data }) => getForwardableCosts(data).length > 0,
          title: "Forward costs to invoices",
        },
        {
          actionFunction: () => setOpenReassignModal(true),
          showButton: ({ data }) =>
            getReassignableCosts(data).length > 0 && subcontracts.length > 0,
          title: "Re-assign costs to ATA",
        },
      ],
    },
    subTableFunction: (rowData: any, rowMeta: any) => {
      const subCosts = costs[rowMeta.dataIndex].costs;

      return (
        <SubTableContainer>
          <CostsSubtable articles={articles} subCosts={subCosts} />
        </SubTableContainer>
      );
    },
    triggerChangeOnFilterInit: ["contractId"],
    useGetDataQuery: useGetCostsTableQuery,
  });

  const { structure: costTableStructure } = useFormTable<
    CostTableReturn,
    GetStructureExtraProps
  >({
    extraProps: {
      reportYFilters,
      articles,
      currencyCode: companyQuery?.company?.currencyCode || "SEK",
      fileDownloading,
      setFileDownloading,
    },
    initialFormData: costs,
    getStructure,
  });

  const costColumns: MUIDataTableColumnDef[] = costTableStructure.items.map(
    (item) =>
      BuilderColumnOptionsSingle({
        data: costs,
        disabled: true,
        item,
      }),
  );

  const forwardableCosts = getForwardableCosts(costs);
  const reAssignableCosts = getReassignableCosts(costs);

  return (
    <>
      <MuiDataTableWrapper
        isFetching={isLoadingCosts || isLoadingCompany}
        data={costs || []}
        columns={costColumns}
        options={options}
        tableType="main-with-filter"
        noBackground={true}
      />
      <ForwardCostsModal
        articles={articles}
        costs={forwardableCosts}
        extraProps={{
          articles,
          fileDownloading,
          setFileDownloading,
        }}
        isLoading={isLoadingCosts || isLoadingArticles}
        handleCloseModal={() => setOpenForwardModal(false)}
        openModal={openForwardModal}
      />
      <ReassignCostsModal
        articles={articles}
        costs={reAssignableCosts}
        extraProps={{
          articles,
          fileDownloading,
          setFileDownloading,
          subcontracts,
        }}
        isLoading={
          isAllReportTemplateYFiltersLoading ||
          isLoadingCosts ||
          isLoadingArticles ||
          isLoadingSubcontracts
        }
        handleCloseModal={() => setOpenReassignModal(false)}
        openModal={openReassignModal}
      />
    </>
  );
};

export default ContractCostsTable;
