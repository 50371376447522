/**
 *Created by Mikael Lindahl on 2023-03-15
 */

import parseRestApiErrorMessage from "../utils/parseRestApiErrorMessage";
import updateRowTotals from "../utils/updateRowTotals";
import useTranslation, { TFunction } from "src/hooks/useTranslationWrapper";
import { SerializedError } from "@reduxjs/toolkit";
import { Types } from "mongoose";
import { updateIndexRows } from "../utils/updateIndexRows";
import { useSnackbar } from "notistack";
import { useState } from "react";
import {
  InvoicePlan,
  InvoicePlanRow,
} from "../accurasee-backend-types/app/invoiceplan/invoiceplan.types";
import {
  useApprovePlannedInvoiceMutation,
  useExportPlannedInvoiceMutation,
  useMergePlannedInvoicesMutation,
  usePatchPlannedInvoiceMutation,
} from "../redux/services/PlannedinvoiceService";
import { PlannedInvoiceRow } from "../accurasee-backend-types/app/plannedinvoice/plannedinvoice.types";

type UseExportInvoice = {
  invoicePlan?: InvoicePlan | undefined;
  plannedInvoiceId?: Types.ObjectId;
  selectedRows?: InvoicePlanRow[];
};

type MutationResponse =
  | { data: any }
  | { error: { status: any; data: any } | SerializedError };

const useExportInvoice = (props?: UseExportInvoice) => {
  const [t] = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [patchPlannedInvoice] = usePatchPlannedInvoiceMutation();
  const [approvePlannedInvoice] = useApprovePlannedInvoiceMutation();
  const [exportPlannedInvoice] = useExportPlannedInvoiceMutation();
  const [mergePlannedInvoices] = useMergePlannedInvoicesMutation();

  const [isExportInvoiceLoading, setIsExportInvoiceLoading] = useState(false);

  const checkForErrorInResponse = ({
    errors,
    response,
    triggerThrow = true,
    step,
    t,
  }: {
    errors: any[];
    response: MutationResponse;
    triggerThrow?: boolean;
    step: "patch" | "approve" | "export" | "merge" | "revert";
    t: TFunction;
  }) => {
    if ("error" in response) {
      const msg = parseRestApiErrorMessage(response.error);

      errors.push(new Error(`${t("Failed with")} ${t(step)}: ${msg}`));

      if (triggerThrow) {
        throw new Error("response failed");
      }
    }
  };

  // Those functions are to make a selected row become a Planned invoice row
  const onExportInvoice = async (propsOnExportInvoice?: {
    plannedInvoiceId?: Types.ObjectId;
    plannedInvoice?: { _id?: Types.ObjectId; invoiceRows: PlannedInvoiceRow[] };
    onlySave?: boolean;
  }) => {
    const _plannedInvoiceId =
      propsOnExportInvoice?.plannedInvoiceId ||
      propsOnExportInvoice?.plannedInvoice?._id ||
      props?.plannedInvoiceId;

    const rowIds = _plannedInvoiceId
      ? [_plannedInvoiceId]
      : props?.selectedRows?.reduce((v, r) => {
          if (r.plannedInvoiceId !== undefined) {
            v.push(r.plannedInvoiceId);
          }
          return v;
        }, [] as Types.ObjectId[]);
    setIsExportInvoiceLoading(true);

    updateIndexRows(propsOnExportInvoice?.plannedInvoice?.invoiceRows || []);

    // Update all rows, since also index rows can have changed
    updateRowTotals(propsOnExportInvoice?.plannedInvoice?.invoiceRows || []);

    const errors: any[] = [];

    try {
      if (propsOnExportInvoice?.plannedInvoice) {
        const { _id, invoiceRows } = propsOnExportInvoice.plannedInvoice;
        const responsePathPlannedInvoice = await patchPlannedInvoice({
          id: _id,
          invoiceRows,
        });

        checkForErrorInResponse({
          errors,
          response: responsePathPlannedInvoice,
          step: "patch",
          t,
        });

        if (propsOnExportInvoice.onlySave) {
          setIsExportInvoiceLoading(false);
          return enqueueSnackbar(t("Saved changes to invoice rows"), {
            variant: "success",
          });
        } else {
          enqueueSnackbar(t("Saved changes to invoice rows"), {
            variant: "info",
          });
        }
      }

      let plannedInvoiceId: any;
      if (rowIds === undefined || rowIds.length === 0) {
        console.error("No rows selected for export");
        setIsExportInvoiceLoading(false);
        return enqueueSnackbar(t("No rows selected for export"), {
          variant: "info",
        });
      } else if (rowIds.length === 1) {
        // one selected row
        plannedInvoiceId = rowIds[0];
      } else if (props?.invoicePlan) {
        // multiple selected row
        const responseMergedPlannedInvoice = await mergePlannedInvoices({
          invoicePlanId: props.invoicePlan?._id,
          plannedInvoiceIds: rowIds,
        });

        checkForErrorInResponse({
          errors,
          response: responseMergedPlannedInvoice,
          step: "merge",
          t,
        });

        if ("data" in responseMergedPlannedInvoice) {
          plannedInvoiceId = responseMergedPlannedInvoice?.data.data._id;
        }
      } else {
        console.error("Missing invoicePlan and/or plannedInvoiceId");
        return;
      }

      const responseApprovePlannedInvoice = await approvePlannedInvoice({
        id: plannedInvoiceId,
        approved: {
          approved: true,
          updateInvoiceDate:
            props?.invoicePlan?.type !== "periodic" &&
            props?.invoicePlan?.type !== "singleinvoice",
        },
      });

      checkForErrorInResponse({
        errors,
        response: responseApprovePlannedInvoice,
        step: "approve",
        t,
      });

      enqueueSnackbar(t("Invoice approved"), {
        variant: "info",
      });

      const responseExportPlannedInvoice =
        await exportPlannedInvoice(plannedInvoiceId);

      checkForErrorInResponse({
        errors,
        response: responseExportPlannedInvoice,
        triggerThrow: false,
        step: "export",
        t,
      });

      console.log(errors, "errors");

      if (errors.length > 0) {
        throw new Error("response failed");
      }

      enqueueSnackbar(t("Invoice exported"), {
        variant: "success",
      });

      return setIsExportInvoiceLoading(false);
    } catch (error: any) {
      console.log(error);
      setIsExportInvoiceLoading(false);

      for (const error of errors) {
        console.error(error);
        enqueueSnackbar(`${error.message}`, {
          variant: "error",
        });
      }
      if (error.message !== "response failed") {
        enqueueSnackbar(t("Could not approve invoice"), {
          variant: "error",
        });
      }
    }
  };

  return { isExportInvoiceLoading, onExportInvoice };
};

export default useExportInvoice;
