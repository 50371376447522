/**
 *Created by Mikael Lindahl on 2023-02-01
 */

import { useRef, useState } from "react";
import { OnSubmit } from "./useOnSubmit";

type UseOnFormSubmitCustom<DataType = any> = {
  onSubmit: OnSubmit;
  onSuccess?: () => void;
};

const useOnFormSubmitCustom = <DataType = any>(
  props?: UseOnFormSubmitCustom<DataType>,
) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const refForm = useRef<any>();

  const onFormSubmit = async () => {
    if (props) {
      setIsSubmitting(true);
      const { status } = await props.onSubmit();
      setIsSubmitting(false);
      if (props.onSuccess && status === "success") {
        props.onSuccess();
      }
    }
  };

  return { isSubmitting, onFormSubmit, refForm };
};

export default useOnFormSubmitCustom;
