import { Typography } from "@mui/material";
import { useGetReportTemplatesQuery } from "src/redux/services/ReportTemplateService";
import Page from "src/components/Basic/Mixed/Pages/Page";
import Report from "./Report";
import { Fragment } from "react";

const Reports = () => {
  const { data: dataQuery, isLoading } = useGetReportTemplatesQuery(undefined);

  const reportTemplates = dataQuery?.data || [];

  return (
    <Page
      label={"Reports"}
      breadcrumbs={[{ label: "Setup" }, { label: "Reports" }]}
      isFullWidth={false}
      isLoading={isLoading}
    >
      {reportTemplates?.length === 0 ? (
        <Typography>{"No report template exists ..."}</Typography>
      ) : (
        reportTemplates?.map((template) => (
          <Fragment key={template.name}>
            <Report data={template} />
          </Fragment>
        ))
      )}
    </Page>
  );
};

export default Reports;
