import { ReportGetRequestQuery } from "src/accurasee-backend-types/app/report/report.types";
import { useGetReportTemplatesQuery } from "src/redux/services/ReportTemplateService";
import InsightReport from "./InsightReport";
import Page from "src/components/Basic/Mixed/Pages/Page";

const InsightReports = () => {
  const { data: dataQuery, isLoading } = useGetReportTemplatesQuery(undefined);
  const reportTemplates = dataQuery?.data || [];

  const baseFilter: Partial<ReportGetRequestQuery> = {
    // Default is this fiscal year, for ex. from 1.1.2023 - today's date
    startDate: new Date(new Date().getFullYear(), 0, 1),
    endDate: new Date(),
    resolution: "month",
  };

  return (
    <Page
      label="Reports"
      sx={{ borderRadius: "8px" }}
      breadcrumbs={[{ label: "Insights" }, { label: "Reports" }]}
      isLoading={isLoading}
    >
      {reportTemplates.length > 0 ? (
        reportTemplates?.map((reportTemplate) => (
          <InsightReport
            key={reportTemplate.name}
            reportTemplateId={String(reportTemplate._id)}
            reportTemplateName={reportTemplate.name}
            baseFilter={baseFilter}
          />
        ))
      ) : (
        <InsightReport
          reportTemplateName="Main report"
          baseFilter={baseFilter}
        />
      )}
    </Page>
  );
};

export default InsightReports;
