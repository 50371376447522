import { escape } from "src/utils/translate";
import { IncomeWithPermissions } from "src/accurasee-backend-types/app/income/income.types";
import { MUIDataTableColumnDef } from "mui-datatables";
import { useEffect } from "react";
import { useGetCompanyQuery } from "src/redux/services/CompanyService";
import { useGetContractIncomesQuery } from "src/redux/services/ContractService";
import { useGetContractQuery } from "src/redux/services/ContractService";
import { useGetReportTemplateYFiltersQuery } from "src/redux/services/ReportTemplateFilterService";
import { useMUIDatTableOptions } from "src/hooks/useMUIDataTableOptions";
import { useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import BuilderColumnOptionsSingle from "src/components/Builders/Table/BuilderColumnOptionsSingle";
import getStructure, {
  GetStructureExtraProps,
} from "./ContractIncomesStructure";
import MuiDataTableWrapper from "src/components/Basic/Mixed/Tables/MuiDataTableWrapper";
import Page from "src/components/Basic/Mixed/Pages/Page";
import useFormTable from "src/hooks/useFormTable";
import useTranslation from "src/hooks/useTranslationWrapper";

const ContractIncomes = () => {
  const [t] = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const { contractId } = useParams<{ contractId: string }>();
  const { data: contract, isLoading: isLoadingContract } =
    useGetContractQuery(contractId);
  const { data: companyQuery, isLoading: isLoadingCompany } =
    useGetCompanyQuery(undefined);
  const {
    data: contractIncomes,
    isLoading,
    isError,
  } = useGetContractIncomesQuery({
    contractId,
  });
  const {
    data: reportFiltersQuery,
    isLoading: isAllReportTemplateYFiltersLoading,
  } = useGetReportTemplateYFiltersQuery(undefined);
  const reportYFilters =
    reportFiltersQuery?.data?.filter(
      (filter) => filter.transactionType === "income",
    ) || [];

  useEffect(() => {
    if (isError) {
      enqueueSnackbar(t("Could not load Contract incomes"), {
        variant: "error",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError]);

  const incomes = contractIncomes?.data || [];

  const { structure } = useFormTable<
    IncomeWithPermissions,
    GetStructureExtraProps
  >({
    extraProps: {
      reportYFilters,
      currencyCode: companyQuery?.company?.currencyCode || "SEK",
    },
    initialFormData: incomes,
    getStructure,
  });

  const columns: MUIDataTableColumnDef[] = structure.items.map((item) =>
    BuilderColumnOptionsSingle({
      data: incomes,
      disabled: true,
      item,
    }),
  );

  const { options } = useMUIDatTableOptions({
    defaultPageSizeOptions: [10, 15, 50],
    pagination: contractIncomes && contractIncomes?.data?.length >= 10,
    dataFetchResult: { data: contractIncomes, isLoading },
  });

  return (
    <Page
      label={"Incomes"}
      breadcrumbs={[
        { label: "Contracts", link: "/app/contracts" },
        {
          label: `Contract${escape(` ${contract?.projectExternalId || ""}`)}`,
          link: `/app/contracts/${contractId}`,
        },
        {
          label: "Incomes",
        },
      ]}
      isLoading={
        isLoading ||
        isAllReportTemplateYFiltersLoading ||
        isLoadingCompany ||
        isLoadingContract
      }
    >
      <MuiDataTableWrapper
        data={incomes}
        columns={columns}
        options={options}
        tableType="main-with-filter"
        noBackground={true}
      />
    </Page>
  );
};

export default ContractIncomes;
