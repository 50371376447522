/**
 *Created by Mikael Lindahl on 2023-11-20
 */
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import { SerializedError } from "@reduxjs/toolkit";

const ErrorLoading = ({
  error,
  name,
}: {
  error:
    | { message: string }
    | { status: any; data: any }
    | SerializedError
    | undefined;
  name: string;
}) => {
  return (
    <Box>
      <Typography>{`Could not load ${name}`}</Typography>
      <Typography color="error">
        {error !== undefined && "data" in error
          ? error?.data?.error?.message
          : error}
      </Typography>
    </Box>
  );
};

export default ErrorLoading;
