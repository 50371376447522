import ErrorPage from "../components/Screens/ErrorPage/ErrorPage";
import LanguageDetector from "i18next-browser-languagedetector";
import React, { useEffect, useState } from "react";
import clone from "../utils/clone";
import getCookie from "../utils/getCookie";
import i18next from "i18next";
import { Catalog } from "../accurasee-backend-types/app/translation/translation.types";
import { I18nextProvider, initReactI18next } from "react-i18next";
import { LinearProgress } from "@mui/material";
import { RootState } from "../redux/reducers";
import { getNestedObjectData } from "../utils/nestedData";
import { useDispatch, useSelector } from "react-redux";
import { useGetCatalogQuery } from "../redux/services/TranslationService";
import {
  deleteMissingTranslations,
  MissingTranslationsState,
} from "../redux/slices/missingTranslations";

const cookieLanguage = getCookie("i18next");
const localStorageLanguage = window.localStorage.getItem("i18nextLng");
const locallyStoredLanguage =
  cookieLanguage ||
  localStorageLanguage ||
  window.navigator.language.split("-")[0] ||
  "en";

export interface TranslationContextType {
  missingTranslations: MissingTranslationsState;
  // Add other attributes here if needed
}

export const TranslationContext = React.createContext<TranslationContextType>({
  missingTranslations: [] as MissingTranslationsState,
});

export const addCatalogToTranslation = (catalog: Catalog) => {
  for (let language in catalog) {
    for (let namespace in catalog[language]) {
      const resource = catalog[language][namespace];

      i18next.addResourceBundle(language, namespace, clone(resource));
    }
  }
};

const TranslationProvider = ({ children }: { children: React.ReactNode }) => {
  const dispatch = useDispatch();
  const user = useSelector<RootState, any>(({ user }) => {
    return user.user;
  });

  const missingTranslations = useSelector<RootState, MissingTranslationsState>(
    ({ missingTranslations }) => missingTranslations,
  );

  const contextValue: TranslationContextType = {
    missingTranslations: missingTranslations,
    // Add other attributes here if needed
  };

  const language = user?.locale || locallyStoredLanguage;

  const {
    data: dataQuery,
    isLoading,
    error,
    isError,
  } = useGetCatalogQuery(language);

  const catalog = dataQuery?.data;

  const [hasLoadedTranslations, setHasLoadedTranslations] = useState(false);

  useEffect(() => {
    if (catalog && i18next.isInitialized) {
      addCatalogToTranslation(catalog);
    }

    if (!hasLoadedTranslations && catalog && !i18next.isInitialized) {
      i18next
        .use(LanguageDetector)
        .use(initReactI18next)
        .init({
          resources: clone(catalog), // add this line
          fallbackLng: "en",
          debug: false,
          load: "languageOnly",
          ns: ["common"],
          defaultNS: "common",
          keySeparator: false,
          nsSeparator: false, // Default :, need to set to false otherwise keys can not include :
          interpolation: {
            escapeValue: false,
            formatSeparator: ",",
          },
          react: {
            useSuspense: false,
          },
          detection: {
            order: ["cookie", "localStorage"],
            lookupQuerystring: "lng",
            lookupCookie: "i18next",
            lookupLocalStorage: "i18nextLng",

            // cache user language on
            caches: ["localStorage", "cookie"],
          },
        });

      setHasLoadedTranslations(true);
    }

    if (catalog) {
      const remove = [];
      for (let missing of missingTranslations) {
        if (
          getNestedObjectData({
            data: catalog,
            key: `en.${missing.namespace}.${missing.key}`,
          }) !== undefined
        ) {
          remove.push({ key: missing.key, namespace: missing.namespace });
        }
      }

      dispatch(deleteMissingTranslations(remove));
    }
  }, [catalog]);

  useEffect(() => {
    if (i18next.isInitialized) {
      if (user?.locale && i18next.language !== user?.locale) {
        i18next.changeLanguage(user.locale).catch((err) => console.error(err));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.locale, i18next.isInitialized]);

  if (isError) {
    console.log(error);
    return (
      <ErrorPage
        title={"Error loading page"}
        subtitle={
          "The server may be down. Please check again in a couple of minutes to see if it is upp. If problem persist please contact support"
        }
        statusCode={500}
      />
    );
  }
  if (isLoading || !hasLoadedTranslations) return <LinearProgress />;

  return (
    <TranslationContext.Provider value={contextValue}>
      <I18nextProvider i18n={i18next}>{children}</I18nextProvider>
    </TranslationContext.Provider>
  );
};

export default TranslationProvider;
