/**
 *Created by Mikael Lindahl on 2024-06-03
 */
import { CollectionName } from "../../accurasee-backend-types/app/general/collection.types";
import { getProjectPath } from "../../context/MicrosoftDriveProvider/Utils/PathHelper";
import _ from "lodash";
import { MicrosoftDriveService } from "../../context/MicrosoftDriveProvider/Lib/MicrosoftDriveService";
import moment from "moment-timezone";
import { useCallback, useEffect, useState } from "react";
//@ts-ignore
import mime from "mime-types";

type MicrosoftDocumentMetadata = {
  documentId: number;
  group: string;
  type: string;
  fileName: string;
};

type MicrosoftFileDataValue = {
  "@odata.etag": string;
  createdBy: {
    application: {
      displayName: string;
      id: string;
    };
    user: {
      displayName: string;
      email: string;
      id: string;
    };
  };
  createdDateTime: string;
  description?: string;
  file: {
    hashes: {
      quickXorHash: string;
    };
    mimeType: string;
  };
  folder?: any;
  id: string;
  lastModifiedDateTime?: string;
  name: string;
  parentReference: {
    driveId: string;
    driveType: string;
    id: string;
    name: string;
    path: string;
    siteId: string;
  };
  webDavUrl: string;
  webUrl: string;
};

type MicrosoftFileData = {
  value: MicrosoftFileDataValue[];
};

export type MicrosoftDriveData = {
  id: string;
  author: string;
  created: string;
  documentId: number;
  edited: string;
  fileName: string;
  format: string;
  group?: string;
  origin: MicrosoftFileDataValue;
  shared: boolean;
  type?: string;
};

const useMicrosoftDriveList = ({
  collectionName,
  leafFolder,
  service,
}: {
  collectionName: CollectionName;
  leafFolder?: string;
  service: MicrosoftDriveService | null;
}) => {
  const [data, setData] = useState<MicrosoftDriveData[]>([]);
  const [groupDocuments, setGroupDocuments] = useState<
    Partial<MicrosoftDriveData>
  >({});
  const [noGroupDocuments, setNoGroupDocuments] = useState<
    MicrosoftDriveData[]
  >([]);
  const [isLoading, setIsLoading] = useState(true);
  const [noRecords, setNoRecords] = useState(false);
  const [isError, setIsError] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const [trigger, setTrigger] = useState(false);

  const invalidate = useCallback(() => {
    setTrigger((prevState) => !prevState); // Toggle the state to trigger the useEffect
  }, []);

  useEffect(() => {
    if (service !== null) {
      const projectPath = getProjectPath({
        collectionName,
        config: service.config,
        leafFolder,
      });

      service
        .getFiles(projectPath)
        .then((fileData: MicrosoftFileData) => {
          const fileDataFiltered = fileData?.value?.filter((e) => !e.folder);
          const data = _.sortBy(fileDataFiltered, ["createdDateTime"]).map(
            (file, index) => {
              const description = file.description;
              let metadata = {} as MicrosoftDocumentMetadata;
              try {
                if (description) {
                  metadata = JSON.parse(decodeURIComponent(description));
                }
              } catch (e) {}

              return {
                author: file.createdBy?.user?.displayName,
                created: moment(file.createdDateTime).format(
                  "YYYY-MM-DD HH:mm",
                ),
                documentId: index + 1,
                edited: moment(file.lastModifiedDateTime).format(
                  "YYYY-MM-DD HH:mm",
                ),
                fileName: file.name,
                format: mime.extension(file.file?.mimeType),
                group: metadata?.group,
                id: file.id,
                origin: file,
                shared: true,
                type: metadata?.type,
              };
            },
          );

          setData(data);
          setNoRecords(data.length === 0);

          let groupDocs = {};
          let noGroupDocs = [] as MicrosoftDriveData[];
          for (let document of data) {
            if (document.group) {
              if (groupDocs[document.group]) {
                groupDocs[document.group].push(document);
              } else {
                groupDocs[document.group] = [document];
              }
            } else {
              noGroupDocs.push(document);
            }
          }

          setGroupDocuments(groupDocs);
          setNoGroupDocuments(noGroupDocs);
          setIsLoading(false);
        })
        .catch((e: Error) => {
          setIsLoading(false);

          if (
            e.message.includes(
              "Resource not found for the segment 'children'",
            ) ||
            e.message.includes("The resource could not be found")
          ) {
            setNoRecords(true);
            return;
          } else {
            setIsError(true);
            setError(e);
          }
        });
    }
  }, [collectionName, leafFolder, service, trigger]);

  return {
    data,
    error,
    groupDocuments,
    invalidate,
    isError,
    isLoading,
    noGroupDocuments,
    noRecords,
  };
};

export default useMicrosoftDriveList;
