import ApprovePlannedInvoicesModalWithForm from "../../Modals/ApprovePlannedInvoicesModalWithForm";
import BuilderColumnOptionsSingle from "src/components/Builders/Table/BuilderColumnOptionsSingle";
import Constants from "src/constants/Constants";
import InvoiceRowsSubtable from "../InvoiceRowsSubtable";
import MuiDataTableWrapper from "src/components/Basic/Mixed/Tables/MuiDataTableWrapper";
import SectionForTableWithToolbar from "../../../Basic/Simple/Sections/SectionForTableWithToolbar";
import SubTableContainer from "src/components/Basic/Mixed/Tables/SubTableContainer";
import useOnSubmit from "src/hooks/useOnSubmit";
import useTranslation from "src/hooks/useTranslationWrapper";
import { MUIDataTableColumnDef } from "mui-datatables";
import { PlannedInvoiceGetList } from "src/accurasee-backend-types/app/plannedinvoice/plannedinvoice.types";
import { StructureItemTable } from "src/components/Builders/Table/CommonBuilderTableTypes";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import {
  getCustomMinMaxFilterListOptions,
  getCustomMinMaxfilterOptions,
} from "../../Containers/Filterhelper";
import {
  useDeletePlannedInvoiceMutation,
  useGetPlannedInvoicesQuery,
} from "src/redux/services/PlannedinvoiceService";
import { Filter } from "src/hooks/useMUIDataTableFilterStates";
import useMUIDataTableOptionsServerSide from "src/hooks/useMUIDataTableOptionsServerSide";

interface InvoiceTableProps {
  label?: string;
  plannedInvoiceFilter: Filter;
  useColumns: string[];
  redirectFoundationPath?: string;
}

const PlannedInvoicesTable = ({
  label,
  plannedInvoiceFilter,
  useColumns,
  redirectFoundationPath,
}: InvoiceTableProps) => {
  const [t] = useTranslation();
  const location = useLocation();
  const { contractId } = useParams<{ contractId?: string }>();
  const [deletePlannedInvoice] = useDeletePlannedInvoiceMutation();

  const [openModal, setOpenModal] = useState(false);
  const [plannedInvoice, setPlannedInvoice] = useState<PlannedInvoiceGetList>();

  const onSubmit = useOnSubmit({
    apiMutations: { delete: deletePlannedInvoice },
    name: "planned invoice",
  });

  const {
    options,
    data: plannedInvoices,
    isLoading: isPlannedInvoicesLoading,
  } = useMUIDataTableOptionsServerSide<PlannedInvoiceGetList>({
    dataName: "planned invoices",
    filterInit: {
      base: {
        ...plannedInvoiceFilter,
      },
      match: {
        contractId,
      },
    },
    filterOptions: {
      selectableRows: "multiple",
      searchPlaceholder: `${t("Search for")} ${t("Number").toLowerCase()}, ${t(
        "Name",
      ).toLowerCase()}, ${t("Customer name").toLowerCase()} ${t("or")} ${t(
        "Status",
      ).toLowerCase()}...`,
      deleteFunction: (rowsDeleted, newTableData): void | false => {
        const plannedInvoicesToDelete = rowsDeleted.data.map(
          (row) => plannedInvoices[row.dataIndex],
        );

        for (const plannedInvoice of plannedInvoicesToDelete) {
          if (!plannedInvoice?.permissions?.deleteRights) {
            enqueueSnackbar(
              `${t("Could not delete - deletion rights are missing")}`,
              {
                variant: "error",
              },
            );
            return false;
          } else if (plannedInvoice?._id) {
            onSubmit({ data: { delete: plannedInvoice?._id } });
          }
        }
      },
    },
    subTableFunction: (rowData: any, rowMeta: any) => {
      const plannedInvoice = plannedInvoices[rowMeta.dataIndex];
      return (
        <SubTableContainer>
          <InvoiceRowsSubtable
            currencyCode={plannedInvoice.currencyCode}
            invoiceRows={plannedInvoice.invoiceRows}
            useColumns={[
              "itemDescription",
              "quantity",
              "pricePerUnit",
              "discount",
              "totalExcludingVAT",
              "percentVAT",
              "total",
              "isIndex",
            ]}
          />
        </SubTableContainer>
      );
    },
    triggerChangeOnFilterInit: contractId ? ["contractId"] : [],
    useGetDataQuery: useGetPlannedInvoicesQuery,
  });

  const { enqueueSnackbar } = useSnackbar();

  const plannedInvoiceTableStructure: {
    items: StructureItemTable<PlannedInvoiceGetList>[];
  } = {
    items: [
      {
        dataName: "numberFromContract",
        headerLabel: "#",
        type: "link",
        redirectLink: (props) =>
          (redirectFoundationPath || location.pathname) +
          "/" +
          String(props.data[props.rowIndex]._id),
        getValue: ({ data, rowIndex }) => {
          const invoice = data[rowIndex];
          return invoice.projectExternalId && !contractId
            ? invoice.projectExternalId + "-" + invoice.number
            : invoice.number;
        },
      },
      {
        dataName: "number",
        headerLabel: "#",
        type: "link",
        sort: true,
        redirectLink: (props) =>
          (redirectFoundationPath || location.pathname) +
          "/" +
          String(props.data[props.rowIndex]._id),
      },
      {
        type: "link",
        redirectLink: (props) =>
          (redirectFoundationPath || location.pathname) +
          "/" +
          String(props.data[props.rowIndex]._id),
        dataName: "name",
        headerLabel: "Name",
        sort: true,
        alignColumnContent: "start",
      },
      {
        type: "text",
        dataName: "projectExternalId", // Allow to sort on number instead on name
        headerLabel: "Contract",
        sort: true,
        getValue: ({ data, rowIndex }) => {
          const invoice = data[rowIndex];
          const name =
            invoice?.projectExternalId + " - " + invoice?.contractName;
          const maxLength = Constants.MAX_WIDTH_NUMBER_OF_CHARS_IN_TABLE;
          return name.length > maxLength
            ? name.slice(0, maxLength) + "..."
            : name;
        },
      },
      {
        type: "date",
        dataName: "dueDate",
        headerLabel: "Due date",
        sort: true,
        filter: true,
        filterType: "custom",
        customFilterListOptions: getCustomMinMaxFilterListOptions(
          t("Due date"),
        ),
        filterOptions: getCustomMinMaxfilterOptions(t("Due date"), "date"),
      },
      {
        type: "date",
        dataName: "invoiceDate",
        headerLabel: "Invoice date",
        sort: true,
        filter: true,
        filterType: "custom",
        customFilterListOptions: getCustomMinMaxFilterListOptions(
          t("Invoice date"),
        ),
        filterOptions: getCustomMinMaxfilterOptions(t("Invoice date"), "date"),
      },
      {
        type: "text",
        dataName: "customerName",
        headerLabel: "Customer name",
        sort: true,
        alignColumnContent: "start",
        getValue: ({ data, rowIndex }) => {
          const name = data[rowIndex]?.customerName || "";
          const maxLength = Constants.MAX_WIDTH_NUMBER_OF_CHARS_IN_TABLE;
          return name.length > maxLength
            ? name.slice(0, maxLength) + "..."
            : name;
        },
      },
      {
        type: "badge",
        dataName: "status",
        headerLabel: "Status",
        filterOptions: {
          names: [
            "Upcoming",
            "Ready to invoice",
            "Requires input",
            "Late",
            "Exported",
            // "Approved",
          ],
        },
        filter: true,
        sort: true,
        alignColumnContent: "center",
      },
      {
        type: "text_currency_integer",
        dataName: "totalExcludingVAT",
        headerLabel: "Tot Excl. VAT",
        sort: true,
        getIsEstimate: ({ data, rowIndex }) => {
          return data[rowIndex].invoiceRows.some(
            (row) => row.isQuantityEstimate || row.isPricePerUnitEstimate,
          );
        },
        filter: true,
        filterType: "custom",
        customFilterListOptions: getCustomMinMaxFilterListOptions(
          t("Total excl. VAT"),
        ),
        filterOptions: getCustomMinMaxfilterOptions(
          t("Total excl. VAT"),
          "number",
        ),
        whiteSpace: "nowrap",
        alignColumnContent: "end",
      },
      {
        type: "text_currency_integer",
        dataName: "totalVAT",
        headerLabel: "VAT",
        sort: true,
        getIsEstimate: ({ data, rowIndex }) => {
          return data[rowIndex].invoiceRows.some(
            (row) => row.isQuantityEstimate || row.isPricePerUnitEstimate,
          );
        },
        whiteSpace: "nowrap",
        alignColumnContent: "end",
      },
      {
        type: "text_currency_integer",
        dataName: "total",
        headerLabel: "Total",
        sort: true,
        getIsEstimate: ({ data, rowIndex }) => {
          return data[rowIndex].invoiceRows.some(
            (row) => row.isQuantityEstimate || row.isPricePerUnitEstimate,
          );
        },
        filter: true,
        filterType: "custom",
        customFilterListOptions: getCustomMinMaxFilterListOptions(
          t("Total amount"),
        ),
        filterOptions: getCustomMinMaxfilterOptions(
          t("Total amount"),
          "number",
        ),
        whiteSpace: "nowrap",
        alignColumnContent: "end",
      },
      {
        dataName: "export",
        cellLabel: ({ data, rowIndex }) =>
          data[rowIndex]?.approved ? "Approved" : "Approve",
        type: "button",
        permissionType: "exportRights",
        onClick: ({ data, rowIndex }) => {
          setPlannedInvoice(data[rowIndex]);
          setOpenModal(true);
        },
        disabled: ({ data, rowIndex }) => data[rowIndex]?.approved,
        showCellWhen: ({ data, rowIndex }) =>
          data[rowIndex].status !== "exported",
      },
    ],
  };

  let plannedInvoiceColumns: MUIDataTableColumnDef[] =
    plannedInvoiceTableStructure.items
      .map((item) =>
        BuilderColumnOptionsSingle({
          data: plannedInvoices,
          disabled: true,
          item,
        }),
      )
      .filter((column: any) => useColumns.includes(column.name));

  if (label) {
    return (
      <SectionForTableWithToolbar label={label}>
        <MuiDataTableWrapper
          columns={plannedInvoiceColumns}
          data={plannedInvoices}
          isFetching={isPlannedInvoicesLoading}
          options={options}
          tableType="main-with-filter"
        />
        <ApprovePlannedInvoicesModalWithForm
          plannedInvoice={plannedInvoice}
          openModal={openModal}
          handleCloseModal={() => setOpenModal(false)}
        />
      </SectionForTableWithToolbar>
    );
  }
  return (
    <>
      <MuiDataTableWrapper
        columns={plannedInvoiceColumns}
        data={plannedInvoices}
        isFetching={isPlannedInvoicesLoading}
        noBackground={true}
        options={options}
        tableType="main-with-filter"
      />
      <ApprovePlannedInvoicesModalWithForm
        plannedInvoice={plannedInvoice}
        openModal={openModal}
        handleCloseModal={() => setOpenModal(false)}
      />
    </>
  );
};

export default PlannedInvoicesTable;
