import {
  useGetPlannedInvoicesTotalAndSumQuery,
  useGetPlannedInvoicesTotalAndSumThisYearQuery,
} from "src/redux/services/PlannedinvoiceService";
import { getYear } from "date-fns";
import { Grid } from "@mui/material";
import { useGetCustomerInvoicesTotalAndSumThisYearQuery } from "src/redux/services/CustomerInvoiceService";
import { useGetNumberOfContractsQuery } from "src/redux/services/ContractService";
import InvoicesTable from "src/components/Complex/Tables/Invoices/CustomerInvoicesTable";
import Page from "src/components/Basic/Mixed/Pages/Page";
import PlannedInvoicesTable from "src/components/Complex/Tables/Invoices/PlannedInvoicesTable";
import SectionContainer from "src/components/Basic/Mixed/Sections/SectionContainer";
import toCurrency from "src/utils/toCurrency";
import useTranslation from "src/hooks/useTranslationWrapper";
import WidgetWithContent from "src/components/Basic/Mixed/Widget/WidgetWithContent";

export default function Dashboard() {
  const [t] = useTranslation();
  const { data: numerOfActiveContracts, isLoading: isFetchingContracts } =
    useGetNumberOfContractsQuery({});

  const {
    data: plannedAmountThisYear,
    isLoading: isFetchingplannedAmountThisYear,
  } = useGetPlannedInvoicesTotalAndSumThisYearQuery({});

  const { data: plannedAmount, isLoading: isFetchingplannedAmount } =
    useGetPlannedInvoicesTotalAndSumQuery(undefined);

  const {
    data: customerinvoicesThisYear,
    isLoading: isFetchingInvoicedInvoices,
  } = useGetCustomerInvoicesTotalAndSumThisYearQuery(undefined);

  const plannedInvoiceFilter = {
    exported: false,
    status: ["ready_to_invoice", "requires_input", "late"],
    pageSize: 10,
    sortBy: ["invoiceDate", "projectExternalId"],
  };

  const customerInvoiceFilter = {
    pageSize: 10,
    sortBy: "invoiceNumber",
    sort: "desc",
    status: ["fullypaid", "invoicedoverdue", "invoiced"],
  };

  const isLoading =
    isFetchingContracts ||
    isFetchingInvoicedInvoices ||
    isFetchingplannedAmountThisYear ||
    isFetchingplannedAmount;

  return (
    <Page label="Dashboard" labelSize="large" isLoading={isLoading}>
      <SectionContainer columnSpacing={4}>
        <Grid item lg={3} md={6} sm={12} xs={12} rowSpacing={4}>
          <WidgetWithContent
            title={t("Number of contracts")}
            mainNumber={numerOfActiveContracts?.count}
            mainNumberDesc={t("Active contracts")}
            mode="dark"
          />
        </Grid>
        <Grid item lg={3} md={6} sm={12} xs={12} rowSpacing={4}>
          <WidgetWithContent
            title={t("Invoiced", { ns: "header" }) + " " + getYear(new Date())}
            mainNumber={toCurrency({
              value: customerinvoicesThisYear?.sum,
              currencyCode: "SEK",
              toInteger: true,
            })}
            mainNumberDesc={t("Amount invoiced")}
            subNumber={customerinvoicesThisYear?.count}
            subNumberDesc={t("Number of invoices")}
          />
        </Grid>
        <Grid item lg={3} md={6} sm={12} xs={12} rowSpacing={4}>
          <WidgetWithContent
            title={t("Planned invoices") + " " + getYear(new Date())}
            mainNumber={toCurrency({
              value: plannedAmountThisYear?.sum,
              currencyCode: "SEK",
              toInteger: true,
            })}
            mainNumberDesc={t("Amount to invoice")}
            subNumber={plannedAmountThisYear?.count}
            subNumberDesc={t("Number of invoices")}
          />
        </Grid>
        <Grid item lg={3} md={6} sm={12} xs={12} rowSpacing={4}>
          <WidgetWithContent
            title={t("Planned invoices total")}
            mainNumber={toCurrency({
              value: plannedAmount?.sum,
              currencyCode: "SEK",
              toInteger: true,
            })}
            mainNumberDesc={t("Amount to invoice")}
            subNumber={plannedAmount?.count}
            subNumberDesc={t("Number of invoices")}
          />
        </Grid>
      </SectionContainer>
      <PlannedInvoicesTable
        label={"Requires action"}
        plannedInvoiceFilter={plannedInvoiceFilter}
        useColumns={[
          "customerName",
          "dueDate",
          "export",
          "invoiceDate",
          "numberFromContract",
          "projectExternalId",
          "status",
          "total",
          "totalExcludingVAT",
          "totalVAT",
        ]}
        redirectFoundationPath={"/app/operations/invoices/planned"}
      />
      <InvoicesTable
        label={"Successfully invoiced"}
        customerInvoiceFilter={customerInvoiceFilter}
        includeProjectNumber={true}
        useColumns={[
          "customerName",
          "dueDate",
          "invoiceDate",
          "invoiceNumber",
          "projectExternalId",
          "status",
          "total",
          "totalExcludingVAT",
          "totalVAT",
        ]}
        redirectFoundationPath={`/app/operations/invoices/customer`}
      />
    </Page>
  );
}
