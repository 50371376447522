/**
 *Created by Mikael Lindahl on 2023-02-22
 */

import getDisabled from "../Utils/getDisabled";
import getIconByType from "../Utils/getIconByType";
import getShowCellWhen from "../Utils/getShowCellWhen";
import { CommonBuilderTableTypes } from "../CommonBuilderTableTypes";
import ButtonCore from "../../../Basic/Simple/Buttons/ButtonCore";
import ButtonSecondary from "../../../Basic/Simple/Buttons/ButtonSecondary";
import IconButtonCore from "../../../Basic/Simple/Buttons/IconButtonCore";
type IconColumnItemTProps = CommonBuilderTableTypes;

const ActionRowColumnItem = (props: IconColumnItemTProps) => {
  const t = props.t;
  const commonCallbackProps = {
    item: props.item,
    data: props.data,
    rowIndex: props.rowIndex,
  };
  const itemDisabled = getDisabled(commonCallbackProps);

  return getShowCellWhen(commonCallbackProps) ? (
    <>
      {props.item?.actionRowOptions?.map((option, index) => {
        switch (option.elementType) {
          case "button":
            return (
              <ButtonCore
                color={option.buttonColor || "primary"}
                isLoading={
                  option.buttonIsFetching &&
                  option.clickedRowIndex === props.rowIndex
                }
                key={index}
                onClick={() => option && option.onClick(commonCallbackProps)}
                variant={option.buttonVariant || "outlined"}
                permissions={props.data[props.rowIndex]?.permissions}
                permissionType={option.permissionType}
              >
                {t(option.buttonText || "")}
              </ButtonCore>
            );
          case "button_secondary":
            return (
              <ButtonSecondary
                key={index}
                isLoading={
                  option.buttonIsFetching &&
                  option.clickedRowIndex === props.rowIndex
                }
                onClick={() => option && option.onClick(commonCallbackProps)}
                permissions={props.data[props.rowIndex]?.permissions}
                permissionType={option.permissionType}
              >
                {t(option.buttonText || "")}
              </ButtonSecondary>
            );
          case "icon":
            const { icon } = getIconByType(option.iconType);
            return (
              <IconButtonCore
                key={index}
                disabled={itemDisabled}
                onClick={() =>
                  option.onClick && option.onClick(commonCallbackProps)
                }
                permissions={props.data[props.rowIndex]?.permissions}
                permissionType={option.permissionType}
                useDeleteDialog={!!option.useDeleteDialog}
              >
                {icon}
              </IconButtonCore>
            );
        }
      })}
    </>
  ) : (
    <></>
  );
};

export default ActionRowColumnItem;
