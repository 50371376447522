import { ContactPerson } from "src/accurasee-backend-types/app/contact_person/contact_person.types";
import {
  ContractOffer,
  ContractOfferGrouping,
} from "src/accurasee-backend-types/app/contract_offer/contract_offer.types";
import { ContractType } from "src/accurasee-backend-types/app/contracttype/contracttype.types";
import { Currency } from "src/accurasee-backend-types/app/currency/currency.types";
import { Customer } from "src/accurasee-backend-types/app/customer/customer.types";
import { CustomField } from "src/accurasee-backend-types/app/custom_field/custom_field.types";
import { ReturnUser } from "src/accurasee-backend-types/app/user/user.types";
import { StructureContainer } from "src/components/Builders/Container/CommonBuilderContainerTypes";
import { UseFormContainerGetStructure } from "src/hooks/useFormContainer";
import clone from "src/utils/clone";
import toUpdateData from "src/utils/toUpdateData";
import { Types } from "mongoose";
import { customerStatusValues } from "../../../../accurasee-backend-types/app/general/global.types";
import { dicToList, listToDic } from "../../../../utils/transform";
import getContractOfferExternalId from "src/utils/getContractOfferExternalId";
import getSelectOptions from "../../../../utils/getSelectOptions";
import { escape } from "src/utils/translate";
export type GetStructureExtraProps = {
  contacts?: ContactPerson[];
  contractTypes?: ContractType[];
  currencies?: Currency[];
  customers?: Customer[];
  customFields?: CustomField[];
  grouping?: ContractOfferGrouping;
  users?: ReturnUser[];
  isContactModalOpen: boolean;
  isCustomerModalOpen: boolean;
  setIsContactModalOpen: (v: boolean) => void;
  setIsCustomerModalOpen: (v: boolean) => void;
};

export type ItemsTypes = "info" | "details" | "customer";

export const getStructure: UseFormContainerGetStructure<
  Partial<ContractOffer>,
  GetStructureExtraProps,
  ItemsTypes
> = ({ setFormData, extraProps, t }) => {
  const structureFromCustomField: StructureContainer<
    Partial<ContractOffer>,
    ItemsTypes
  > = {
    items:
      extraProps?.customFields?.map((customField) => ({
        disabled: extraProps?.grouping === "archived",
        itemType: "details",
        required: customField.required,
        type: customField.fieldType === "list" ? "selector" : "text_input",
        dataName: customField.name,
        label: escape(customField?.name),
        options:
          customField.fieldType === "list"
            ? getSelectOptions({
                data: customField?.selectOptionValues?.map((value) => ({
                  label: value,
                  value,
                })),
              })
            : undefined,
        setFormData,
      })) || [],
  };

  const structure: StructureContainer<Partial<ContractOffer>, ItemsTypes> = {
    items: [
      {
        itemType: "info",
        type: "autocomplete",
        dataName: "contractTypeId",
        label: "Contract type",
        options: getSelectOptions({
          data: extraProps?.contractTypes,
          label: (c) => c.name,
          value: (c) => String(c._id),
        }),
        disabled: true,
        gridProps: { md: 6 },
      },
      {
        itemType: "info",
        type: "text",
        dataName: "offerId",
        gridProps: { md: 6 },
        label: "Offer id",
        getValue: ({ data }) => getContractOfferExternalId(data),
      },
      {
        itemType: "info",
        validate: (props) => {
          if (
            props.data?.name &&
            (props.data?.name.length <= 1 || props.data?.name.length > 49)
          ) {
            return false;
          } else {
            return true;
          }
        },
        getErrorText: (props) => {
          if (props.data?.name && props.data?.name.length <= 1) {
            return "To short";
          } else if (props.data?.name && props.data?.name.length > 49) {
            return "To long (max length 50 characters)";
          } else {
            return "";
          }
        },
        required: true,
        type: "text_input",
        dataName: "name",
        label: "Offer name",
        setFormData,
        disabled: extraProps?.grouping === "archived",
      },
      {
        itemType: "info",
        type: "text_input",
        dataName: "description",
        label: "Description",
        minRows: 3,
        multiline: true,
        sx: {
          "& .MuiInputBase-multiline": { height: "80px", padding: "8px" },
        },
        setFormData,
        disabled: extraProps?.grouping === "archived",
      },
      {
        itemType: "info",
        type: "autocomplete",
        dataName: "ownerIds",
        label: "Responsible by",
        selectionOptions: { multiple: true },
        disabled: extraProps?.grouping === "archived",
        options: getSelectOptions({
          data: extraProps?.users,
          label: (user) => `${user.firstName} ${user.lastName}`,
          value: (user) => String(user?._id),
        }),
        setFormDataCustom: (props) => {
          let ownerIds: Types.ObjectId[];
          if (typeof props.value === "string") {
            ownerIds = [props.value as unknown as Types.ObjectId];
          } else {
            ownerIds = props.value;
          }
          setFormData({ ...props.data, ownerIds });
        },
      },
      {
        itemType: "info",
        type: "date",
        dataName: "createdAt",
        label: "Created",
        gridProps: { md: 6 },
        disabled: true,
      },
      {
        itemType: "info",
        type: "date",
        dataName: "deadline",
        label: "Deadline",
        gridProps: { md: 6 },
        setFormData,
        disabled: extraProps?.grouping === "archived",
      },
      {
        itemType: "info",
        type: "selector_color",
        dataName: "customerStatus",
        label: "Status",
        options: getSelectOptions({
          data: customerStatusValues.map((value) => ({
            label: t(value),
            value: value,
          })),
        }),
        setFormData,
      },
      {
        itemType: "details",
        type: "number_input_currency",
        dataName: "offerPrice",
        label: "Offer price",
        setFormData,
        disabled: extraProps?.grouping === "archived",
      },
      {
        itemType: "details",
        type: "autocomplete",
        dataName: "currencyCode",
        label: "Currency",
        options: getSelectOptions({
          data: extraProps?.currencies,
          label: (c) => `${c.code} (${c.description})`,
          value: (c) => c.code,
        }),
        setFormData,
        disabled: extraProps?.grouping === "archived",
      },
      ...structureFromCustomField.items,
      {
        itemType: "customer",
        type: "autocomplete_add",
        disabled: extraProps?.grouping === "archived",
        onClick: () => {
          extraProps?.setIsCustomerModalOpen(!extraProps?.isCustomerModalOpen);
        },
        dataName: "customerId",
        label: "Customer",
        options: getSelectOptions({
          data: extraProps?.customers,
          label: (c) => c.name,
          value: (c) => String(c._id),
        }),
        setFormDataCustom: (props) => {
          let newData = { ...props.data, customerId: props.value };

          // contactPerson needs re-selecting
          if (newData?.contactPersonId) {
            delete newData.contactPersonId;
          }

          setFormData(newData as ContractOffer);
        },
      },
      {
        itemType: "customer",
        type: "text",
        dataName: "customerId",
        label: "Organisation no",
        disabled: extraProps?.grouping === "archived",
        getValue: ({ data }) =>
          extraProps?.customers?.find((item) => item._id === data?.customerId)
            ?.organisationNumber,
      },
      {
        itemType: "customer",
        type: "autocomplete_add",
        onClick: () => {
          extraProps?.setIsContactModalOpen(!extraProps?.isContactModalOpen);
        },
        dataName: "contactPersonId",
        label: "Customer contact person",
        options: ({ data }) =>
          getSelectOptions({
            data: extraProps?.contacts?.filter(
              (contact) => contact.customerId === data?.customerId,
            ),
            label: (c) => `${c.firstName} ${c.lastName}`,
            value: (c) => String(c._id),
          }),
        setFormData,
        // Able to select only if a customer is selected
        disabled: ({ data }) => !data?.customerId,
      },
      {
        disabled: extraProps?.grouping === "archived",
        itemType: "customer",
        type: "text",
        dataName: "tel",
        label: "Telephone",
        getValue: ({ data }) =>
          extraProps?.contacts?.find(
            (item) => item._id === data?.contactPersonId,
          )?.phone,
      },
      {
        disabled: extraProps?.grouping === "archived",
        itemType: "customer",
        type: "text",
        dataName: "email",
        label: "Email",
        getValue: ({ data }) =>
          extraProps?.contacts?.find(
            (item) => item._id === data?.contactPersonId,
          )?.email,
      },
    ],
  };
  return structure;
};

export const toSubmitData = ({
  data,
  initData,
  customFields,
}: {
  data: Partial<ContractOffer> | undefined;
  initData: Partial<ContractOffer> | undefined;
  customFields: CustomField[] | undefined;
}) => {
  if (!data) {
    return;
  }
  const newData = clone(data);
  // Create request data
  let partialData: Partial<ContractOffer>;
  if (newData._id) {
    partialData = toUpdateData<Partial<ContractOffer>>({
      data: newData,
      initData,
    });
  } else {
    partialData = newData;
  }

  let isCustomFieldUpdate = false;
  const dicCustomFieldData = listToDic(
    clone(data?.customFieldsData) || [],
    (e) => e.customFieldId,
  );

  // Restructure customFieldsData
  customFields?.forEach((customField) => {
    if (Object.keys(partialData).includes(customField.name)) {
      isCustomFieldUpdate = true;
      if (dicCustomFieldData[String(customField?._id)]) {
        dicCustomFieldData[String(customField?._id)].value =
          partialData[customField.name];
      } else if (customField._id) {
        dicCustomFieldData[String(customField?._id)] = {
          customFieldId: customField._id,
          value: partialData[customField.name],
        };
      }

      // @ts-ignore
      if (dicCustomFieldData[String(customField?._id)]?._id) {
        // @ts-ignore
        delete dicCustomFieldData[String(customField?._id)]?._id;
      }

      delete partialData[customField.name];
    }
  });

  if (isCustomFieldUpdate) {
    partialData.customFieldsData = dicToList(dicCustomFieldData);
  }

  return partialData;
};
