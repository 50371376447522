import fileDownload from "js-file-download";
import { useSnackbar } from "notistack";
import { useState } from "react";
import useTranslation from "src/hooks/useTranslationWrapper";
export function usePdfDownloader(getPdf, filename) {
  const { enqueueSnackbar } = useSnackbar();
  const [t] = useTranslation();
  const [isFetchingPdf, setIsFetchingPdf] = useState(false);
  const downloadPdf = async () => {
    setIsFetchingPdf(true);
    try {
      const pdfRes = await getPdf();

      if (pdfRes?.data) {
        fileDownload(pdfRes.data, filename || "invoice.pdf", "application/pdf");
      }
      setIsFetchingPdf(false);
    } catch (error) {
      setIsFetchingPdf(false);
      enqueueSnackbar(t("Could not Download pdf"), { variant: "error" });
    }
  };
  return { downloadPdf, isFetchingPdf };
}
