import {
  DisplayData,
  FilterType,
  MUIDataTableColumn,
  MUIDataTableOptions,
  MUIDataTableState,
} from "mui-datatables";
import { useContext, useEffect } from "react";
import { UserStateContext } from "src/context/UserStateProvider";
import clone from "src/utils/clone";

const useTableFilterSettings = ({
  id,
  onFilterChange: _onFilterChange,
  onChangeRowsPerPage: _onChangeRowsPerPage,
}: {
  id: string;
  onFilterChange?: MUIDataTableOptions["onFilterChange"];
  onChangeRowsPerPage?: MUIDataTableOptions["onChangeRowsPerPage"];
}) => {
  const { userState, setUserState } = useContext(UserStateContext);

  useEffect(() => {
    const newState = clone(userState);
    if (
      newState.filter?.tables &&
      !newState.filter.tables?.hasOwnProperty(id)
    ) {
      newState.filter.tables[id] = {};
    }
    setUserState(newState);
  }, []);

  const onFilterChange = (
    changedColumn: string | MUIDataTableColumn | null,
    filterList: MUIDataTableState["filterList"],
    type: FilterType | "chip" | "reset",
    changedColumnIndex: number,
    _displayData: DisplayData,
  ) => {
    const columnName = changedColumn?.toString() || "";
    const updatedFilter = filterList[changedColumnIndex];
    const newUserState = clone(userState);

    newUserState.filter.tables[id] = {
      ...newUserState?.filter?.tables?.[id],
      [columnName]: updatedFilter,
    };

    if (_onFilterChange) {
      _onFilterChange(
        changedColumn,
        filterList,
        type,
        changedColumnIndex,
        _displayData,
      );
    }

    setUserState(newUserState);
  };

  const onSetFilterList = (columns: any) => {
    if (typeof columns === "string") return;

    for (const c of columns) {
      if (typeof c === "string") continue;

      if (userState?.filter?.tables?.[id]) {
        if (c.options) {
          c.options.filterList = userState.filter.tables[id][c.name];
        }
      }
    }
  };

  const onChangeRowsPerPage = (rowsPerPage: number) => {
    if (rowsPerPage) {
      const newUserState = {
        ...userState,
        rowsPerPage: {
          ...userState.rowsPerPage,
          [id]: rowsPerPage,
        },
      };

      if (_onChangeRowsPerPage) {
        _onChangeRowsPerPage(rowsPerPage);
      }

      setUserState(newUserState);
    }
  };

  return {
    onFilterChange,
    onSetFilterList,
    onChangeRowsPerPage,
  };
};

export default useTableFilterSettings;
