import { UserFormTableGetStructure } from "src/hooks/useFormTable";
import { MicrosoftDriveData } from "src/hooks/useMicrosoftDrive/useMicrosoftDriveList";
import { MicrosoftDriveService } from "src/context/MicrosoftDriveProvider/Lib/MicrosoftDriveService";
import getSelectOptions from "../../../../utils/getSelectOptions";

export type GetStructureExtraProps = {
  groups?: string[];
  invalidate: () => void;
  service: MicrosoftDriveService | null;
  types?: string[];
};

export type ItemType = "main" | "edit";

export const getStructure: UserFormTableGetStructure<
  MicrosoftDriveData,
  GetStructureExtraProps,
  ItemType
> = ({ extraProps }) => {
  return {
    items: [
      {
        itemType: "main",
        dataName: "",
        getValue: ({ rowIndex }) => rowIndex + 1,
        headerLabel: "#",
        type: "text",
      },
      {
        itemType: "main",
        dataName: "group",
        headerLabel: "Group",
        type: "autocomplete",
        options: getSelectOptions({
          data: extraProps?.groups,
        }),
        setFormDataCustom: ({ value, data, rowIndex }) => {
          const metadata = {
            description: encodeURIComponent(
              JSON.stringify({ group: value, type: data[rowIndex].type }),
            ),
          };

          extraProps?.service
            ?.addMetadata({ id: data[rowIndex].id, metadata })
            .then(() => {
              extraProps?.invalidate();
            });
        },
      },
      {
        itemType: "main",
        dataName: "type",
        headerLabel: "Type",
        type: "autocomplete",
        options: getSelectOptions({ data: extraProps?.types }),
        setFormDataCustom: ({ value, data, rowIndex }) => {
          const metadata = {
            description: encodeURIComponent(
              JSON.stringify({ group: data[rowIndex].group, type: value }),
            ),
          };

          extraProps?.service
            ?.addMetadata({ id: data[rowIndex].id, metadata })
            .then(() => {
              extraProps?.invalidate();
            });
        },
      },
      {
        itemType: "main",
        dataName: "author",
        headerLabel: "Author",
        type: "text",
      },
      {
        itemType: "main",
        dataName: "fileName",
        headerLabel: "Filename",
        type: "link",
        onClick: (props) => {
          const data = props.data[props.rowIndex];
          extraProps?.service?.openURL(
            data.origin.webDavUrl,
            data.origin.webUrl,
            data.origin.name,
            data.origin,
          );
        },
      },
      {
        itemType: "main",
        dataName: "created",
        headerLabel: "Created",
        type: "text",
      },
      {
        itemType: "main",
        dataName: "updated",
        headerLabel: "Updated",
        type: "text",
      },
    ],
  };
};

export default getStructure;
