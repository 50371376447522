/**
 *Created by Mikael Lindahl on 2024-02-07
 */

import { PlannedInvoiceRow } from "../accurasee-backend-types/app/plannedinvoice/plannedinvoice.types";

export const updateIndexRows = (invoiceRows: PlannedInvoiceRow[]) => {
  const indexRowByArticleId = invoiceRows.reduce(
    (prev: { [key: string]: PlannedInvoiceRow }, current) => {
      if (current.indexRow) {
        current.pricePerUnit = 0;
        prev[String(current.articleId)] = current;
      }
      return prev;
    },
    {},
  );

  const rowsWithIndex = invoiceRows.filter((row) => row.isIndex);

  // Always update index row price and never quantity
  for (const row of rowsWithIndex) {
    const keys = Object.keys(indexRowByArticleId);

    // Either there is only one index row or there is one for each article
    const key = keys.length === 1 ? keys[0] : String(row.articleId);
    const indexRow = indexRowByArticleId[key];

    // Can happen when edit row for export. Index and total is then calculated in useExportInvoice
    if (!indexRow) continue;

    indexRow.pricePerUnit =
      (indexRow.pricePerUnit || 0) +
      (row.pricePerUnit || 0) *
        (row.quantity || 0) *
        (1 - (row.discount || 0) * 0.01);
  }
};
