import {
  SupplierWithPermissions,
  SupplierWithTotalsPurchased,
} from "src/accurasee-backend-types/app/supplier/supplier.types";
import { backendApi } from "../api";
import { docListTags, docGetTag } from "./utils";
import { SupplierInvoiceGetList } from "src/accurasee-backend-types/app/supplierinvoice/supplierinvoice.types";

const CACHE_TAG = "Suppliers";
const supplierApi = backendApi.injectEndpoints({
  endpoints: (build) => ({
    getSuppliers: build.query<
      { data?: SupplierWithPermissions[]; metadata: any },
      any
    >({
      query: (params) => ({ url: "/suppliers", params }),
      providesTags: docListTags(CACHE_TAG),
    }),
    getSuppliersTable: build.query<
      { data?: SupplierWithTotalsPurchased[]; metadata: any },
      any
    >({
      query: (params) => ({ url: "/suppliers/table", params }),
      providesTags: docListTags(CACHE_TAG),
    }),
    getSupplier: build.query<SupplierWithPermissions, string>({
      query: (id) => ({ url: `/suppliers/${id}` }),
      providesTags: docGetTag(CACHE_TAG),
    }),
    getSupplierInvoices: build.query<
      { data?: SupplierInvoiceGetList[]; metadata: any },
      any
    >({
      query: (params) => ({ url: "/supplierinvoices", params }),
      providesTags: docListTags("SupplierInvoices"),
    }),
  }),
});

export const {
  useGetSupplierQuery,
  useGetSuppliersQuery,
  useGetSuppliersTableQuery,
  useGetSupplierInvoicesQuery,
} = supplierApi;

export default supplierApi;
