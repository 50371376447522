import { useState } from "react";
import { Types } from "mongoose";
import { useSnackbar } from "notistack";
import { PlannedInvoiceWithPermissions } from "src/accurasee-backend-types/app/plannedinvoice/plannedinvoice.types";
import { SubcontractWithPermissions } from "src/accurasee-backend-types/app/subcontracts/subcontract.types";
import { TermsOfPayment } from "src/accurasee-backend-types/app/termsofpayment/termsofpayment.types";

import parseRestApiErrorMessage from "src/utils/parseRestApiErrorMessage";
import {
  useApprovePlannedInvoiceMutation,
  useExportPlannedInvoiceMutation,
  useMergeSubContractsMutation,
} from "src/redux/services/PlannedinvoiceService";
import useTranslation from "src/hooks/useTranslationWrapper";

const useExportSubContracts = ({
  selectedSubcontracts,
  plannedInvoices,
  termsOfPayments,
}: {
  selectedSubcontracts: SubcontractWithPermissions[];
  plannedInvoices: PlannedInvoiceWithPermissions[];
  termsOfPayments: TermsOfPayment[];
}) => {
  const [t] = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [approvePlannedInvoice] = useApprovePlannedInvoiceMutation();
  const [exportPlannedInvoice] = useExportPlannedInvoiceMutation();
  const [mergeSubContracts] = useMergeSubContractsMutation();

  const [isExportSubContractsLoading, setIsExportSubContractsLoading] =
    useState(false);

  const onExportSubContracts = async () => {
    setIsExportSubContractsLoading(true);

    const plannedInvoiceIds = selectedSubcontracts?.reduce((v, r) => {
      r?.plannedInvoiceIds?.forEach((id) => {
        const plannedInvoice = plannedInvoices?.find(
          (plannedInvoice) =>
            String(plannedInvoice._id) === String(id) &&
            !plannedInvoice?.exported,
        );
        if (plannedInvoice) {
          v.push(id);
        }
      });
      return v;
    }, [] as Types.ObjectId[]);

    // No rows found
    if (plannedInvoiceIds.length === 0) {
      console.error("No rows selected for export");
      setIsExportSubContractsLoading(false);
      return enqueueSnackbar("No rows selected for export", {
        variant: "info",
      });
    }

    // Default: plannedInvoiceId of first element
    let plannedInvoiceId: any = plannedInvoiceIds[0];

    // To merge if there are multiple rows selected
    if (plannedInvoiceIds.length > 1) {
      const subcontractIds = selectedSubcontracts?.reduce((v, r) => {
        if (r._id !== undefined) {
          v.push(r._id);
        }
        return v;
      }, [] as Types.ObjectId[]);

      const mergedPlannedInvoice = await mergeSubContracts({
        subcontractIds,
        plannedInvoiceIds,
      });

      if ("error" in mergedPlannedInvoice) {
        const msg = parseRestApiErrorMessage(mergedPlannedInvoice.error);
        setIsExportSubContractsLoading(false);
        return enqueueSnackbar(
          "Could not merge subcontract(s). Error: " + msg,
          {
            variant: "error",
          },
        );
      }

      if ("data" in mergedPlannedInvoice) {
        plannedInvoiceId = mergedPlannedInvoice?.data.data._id;
      }
    }

    // To approve
    const resApprovePlannedInvoice = await approvePlannedInvoice({
      id: plannedInvoiceId,
      approved: { approved: true, updateInvoiceDate: true },
    });

    if ("error" in resApprovePlannedInvoice) {
      const msg = parseRestApiErrorMessage(resApprovePlannedInvoice.error);
      setIsExportSubContractsLoading(false);
      return enqueueSnackbar(
        "Could not approve subcontract(s). Error: " + msg,
        {
          variant: "error",
        },
      );
    }

    enqueueSnackbar("Subcontract(s) approved", {
      variant: "success",
    });

    // To export subcontract(s)
    try {
      const resExportPlannedInvoice =
        await exportPlannedInvoice(plannedInvoiceId);

      if ("error" in resExportPlannedInvoice) {
        const defaultMsg = t("Subcontract(s) couldn't be exported");
        const subMsg = parseRestApiErrorMessage(resExportPlannedInvoice.error);
        const msg = subMsg ? defaultMsg + ": " + subMsg : defaultMsg;

        enqueueSnackbar(msg, {
          variant: "error",
        });

        // TO revert approved subcontract(s) if export progress is failed
        const resRevertApprovePlannedInvoice = await approvePlannedInvoice({
          id: plannedInvoiceId,
          approved: { approved: false, updateInvoiceDate: false },
        });

        if ("error" in resRevertApprovePlannedInvoice) {
          const defaultMsg = t("Couldn't revert approval");
          const subMsg = parseRestApiErrorMessage(
            resRevertApprovePlannedInvoice.error,
          );
          const msg = subMsg ? defaultMsg + ": " + subMsg : defaultMsg;
          return enqueueSnackbar(msg, {
            variant: "error",
          });
        }

        setIsExportSubContractsLoading(false);
        return enqueueSnackbar("Subcontract(s) approval reverted", {
          variant: "success",
        });
      }

      setIsExportSubContractsLoading(false);

      return enqueueSnackbar("Subcontract(s) exported", {
        variant: "success",
      });
    } catch (error: any) {
      setIsExportSubContractsLoading(false);
      return enqueueSnackbar(
        "Could not export subcontract(s). Error: " + error.message,
        {
          variant: "error",
        },
      );
    }
  };

  return { isExportSubContractsLoading, onExportSubContracts };
};

export default useExportSubContracts;
