import {
  AccountingSettingKey,
  getStructure,
  GetStructureExtraProps,
  toSubmitData,
} from "./AccountingSettingsStructure";
import { useGetAccountsQuery } from "src/redux/services/AccountService";
import { Grid, Typography } from "@mui/material";
import { useGetDimensionsQuery } from "src/redux/services/DimensionService";
import { useTranslation } from "react-i18next";
import BuildItems from "src/components/Builders/Container/BuildItems";
import ButtonClear from "src/components/Basic/Simple/Buttons/ButtonClear";
import ButtonGroup from "src/components/Basic/Simple/Buttons/ButtonGroup";
import ButtonUpdate from "src/components/Basic/Simple/Buttons/ButtonUpdate";
import SectionTight from "src/components/Basic/Mixed/Sections/SectionTight";
import useFormContainer from "src/hooks/useFormContainer";
import useOnFormSubmit from "src/hooks/useOnFormSubmit";
import { AccountingAdjustment } from "src/accurasee-backend-types/app/company_config/company_config.types";
import {
  useGetCompanyConfigQuery,
  useUpdateCompanyConfigsMutation,
} from "src/redux/services/CompanyConfigsService";

const AccountingSettings = ({
  accountingSettingKey,
  label,
}: {
  accountingSettingKey: AccountingSettingKey;
  label: string;
}) => {
  const [t] = useTranslation();

  const { data: accountsQuery, isLoading: isLoadingAccounts } =
    useGetAccountsQuery(undefined);
  const accounts = accountsQuery?.data || [];

  const { data: dimensionsQuery, isLoading: isLoadingDimensions } =
    useGetDimensionsQuery(undefined);
  const dimensions = dimensionsQuery?.data;

  const { data: companyConfigQuery, isLoading } =
    useGetCompanyConfigQuery(undefined);
  const accountSettings = companyConfigQuery?.data?.accountingSettings || {};
  const accountingSettingData = accountSettings?.[accountingSettingKey];

  const initialFormData: Partial<
    AccountingAdjustment & { dimensionTemp: string }
  > = {
    ...accountingSettingData,
    ...(accountingSettingData && accountingSettingData?.dimension
      ? {
          dimensionTemp: `${String(accountingSettingData.dimension?.dimensionId)}.${String(accountingSettingData.dimension?.dimensionItemId)}`,
        }
      : {}),
  };

  console.log("initialFormData", initialFormData);

  const [updateCompany] = useUpdateCompanyConfigsMutation();

  const { formData, isFormValid, setFormData, structure, setHasTriedToSubmit } =
    useFormContainer<
      Partial<AccountingAdjustment & { dimensionTemp: string }>,
      GetStructureExtraProps
    >({
      extraProps: { accounts, dimensions },
      getStructure,
      initialFormData,
    });
  const commonPropsBuildItem: any = { data: formData };

  const submitData = toSubmitData({ data: formData });
  console.log("formData", formData);
  console.log("submitData", submitData);

  const { isSubmitting, onFormSubmit, refForm } = useOnFormSubmit<
    AccountingAdjustment & { dimensionTemp: string }
  >({
    submitProps: {
      apiMutations: { update: updateCompany },
      data: {
        update: {
          accountingSettings: {
            ...accountSettings,
            [accountingSettingKey]: submitData,
          },
        },
      },
      dataId: String(companyConfigQuery?.data?._id),
      name: `Account settings - ${label}`,
    },
  });

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        onFormSubmit().catch((e) => console.error(e));
      }}
      ref={refForm}
    >
      <SectionTight
        isLoading={isLoading || isLoadingAccounts || isLoadingDimensions}
        label={`${label} accounting settings`}
        subLabel={`Edit accounts for adjustments related to ${label}`}
        rightBoxArea={
          <ButtonGroup>
            <ButtonClear
              id={`${label}-clear-button`}
              onClick={() => {
                setFormData(initialFormData);
              }}
            />
            <ButtonUpdate
              id={`${label}-update-button`}
              initialSubmitData={initialFormData}
              isValid={isFormValid}
              isSubmitting={isSubmitting}
              onSubmit={() => {
                refForm.current.requestSubmit();
                setHasTriedToSubmit(true);
              }}
              submitData={formData}
            />
          </ButtonGroup>
        }
      >
        <Grid item xs={12}>
          <Typography
            sx={{ fontWeight: 600, paddingTop: "var(--space-8px)" }}
            color="var(--black-7)"
            variant="body2"
          >
            {t("Dimension")}
          </Typography>
        </Grid>
        {BuildItems({
          items: structure.items.filter(
            (item) => item.itemType === "dimension",
          ),
          ...commonPropsBuildItem,
        })}
        <Grid item xs={12}>
          <Typography
            sx={{ fontWeight: 600, paddingTop: "var(--space-12px)" }}
            color="var(--black-7)"
            variant="body2"
          >
            {t("Accounts")}
          </Typography>
        </Grid>
        {BuildItems({
          items: structure.items.filter((item) => item.itemType === "account"),
          ...commonPropsBuildItem,
        })}
      </SectionTight>
    </form>
  );
};

export default AccountingSettings;
