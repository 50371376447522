/**
 *Created by Mikael Lindahl on 2023-03-08
 */
import { dicToList } from "./transform";

const parseRestApiErrorMessage = (error: any) => {
  let msg = "";

  if (error.data?.error?.code === 110) {
    let messageDic = {};

    for (let detail of error.data.error.details) {
      messageDic[detail.message] = detail.message;
    }
    let messages = dicToList(messageDic);
    msg = messages.join(". ");
  } else if (error?.data?.error?.message) {
    msg =
      typeof error?.data?.error?.message === "string"
        ? error?.data?.error?.message
        : error?.data?.error?.message?.message;
  } else if (typeof error.data === "string") {
    msg = error.data;
  }
  return msg;
};

export default parseRestApiErrorMessage;
