/**
 *Created by Mikael Lindahl on 2024-08-12
 */

import { SelectOption } from "./getSelectOptions";

const getIsInActiveWarning = (options: SelectOption[]) => {
  return `Note: ${options
    ?.filter((o) => o.isActive === false)
    .map((o) => o.label)
    .join(", ")} is not active`;
};

export default getIsInActiveWarning;
