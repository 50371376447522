import { useParams } from "react-router-dom";

import Page from "src/components/Basic/Mixed/Pages/Page";
import SectionContainer from "src/components/Basic/Mixed/Sections/SectionContainer";

import { useGetContractQuery } from "src/redux/services/ContractService";

import Cashflow from "../../../../../components/Complex/Graph/Cashflow";
import RevenueVsOperatingCosts from "../../../../../components/Complex/Graph/RevenueVsOperatingCosts";
import ResultYearToDate from "../../../../../components/Complex/Graph/ResultYearToDate";
import { escape } from "../../../../../utils/translate";

const ContractInsights = () => {
  const { contractId } = useParams<{ contractId: string }>();
  const { data: contract, isLoading } = useGetContractQuery(contractId);

  return (
    <Page
      label={"Contract insights"}
      breadcrumbs={[
        { label: "Contracts", link: "/app/contracts" },
        {
          label: `Contract${escape(` ${contract?.projectExternalId || ""}`)}`,
          link: `/app/contracts/${contractId}`,
        },
        {
          label: "Insights",
        },
      ]}
      isLoading={isLoading}
    >
      <SectionContainer>
        <Cashflow contractId={contractId} />
        <RevenueVsOperatingCosts contractId={contractId} />
        <ResultYearToDate contractId={contractId} />
      </SectionContainer>
    </Page>
  );
};

export default ContractInsights;
