import BuilderColumnOptionsSingle from "src/components/Builders/Table/BuilderColumnOptionsSingle";
import InvoiceRowsSubtable from "../InvoiceRowsSubtable";
import MuiDataTableWrapper from "src/components/Basic/Mixed/Tables/MuiDataTableWrapper";
import useMUIDataTableOptionsServerSide from "../../../../hooks/useMUIDataTableOptionsServerSide";
import useTranslation from "src/hooks/useTranslationWrapper";
import { LinearProgress } from "@mui/material";
import { MUIDataTableColumnDef } from "mui-datatables";
import { SupplierInvoiceGetList } from "src/accurasee-backend-types/app/supplierinvoice/supplierinvoice.types";
import { useGetSupplierInvoicesQuery } from "src/redux/services/SupplierService";
import { useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useState } from "react";
import {
  filterSupplierInvoiceRows,
  getStructure,
} from "./SupplierInvoiceTableStructure";
import SubTableContainer from "src/components/Basic/Mixed/Tables/SubTableContainer";

export type SupplerInvoiceFilterBase = {
  sortBy?: string;
  sort?: "asc" | "desc";
};

interface SupplierInvoiceTableProps {
  supplierInvoiceFilterBase?: SupplerInvoiceFilterBase;
  useColumns: string[];
}

const SupplierInvoiceTable = ({
  supplierInvoiceFilterBase,
  useColumns,
}: SupplierInvoiceTableProps) => {
  const { contractId } = useParams<{ contractId: string }>();
  const [t] = useTranslation();

  const {
    options,
    data: supplierInvoices,
    isLoading,
    isFetching,
  } = useMUIDataTableOptionsServerSide<SupplierInvoiceGetList>({
    dataName: "supplier invoices",
    defaultPaginationOptions: [10, 15, 50],
    expandableRowsOnClick: false,
    filterInit: {
      base: {
        pageSize: 15,
        ...supplierInvoiceFilterBase,
      },
      match: { contractIds: contractId ? [contractId] : [] },
    },
    triggerChangeOnFilterInit: contractId ? ["contractIds"] : [],
    subTableFunction: (rowData: any, rowMeta: any) => {
      const supplierInvoice = supplierInvoices[rowMeta.dataIndex];
      const filteredRows = filterSupplierInvoiceRows({
        contractId,
        supplierInvoice,
      });
      return (
        <SubTableContainer>
          <InvoiceRowsSubtable
            currencyCode={supplierInvoice.currencyCode}
            invoiceRows={filteredRows}
            useColumns={[
              "accountDescription",
              ...(contractId ? [] : ["projectExternalId"]),
              "quantity",
              "pricePerUnit",
              "total",
            ]}
          />
        </SubTableContainer>
      );
    },
    useGetDataQuery: useGetSupplierInvoicesQuery,
  });

  const { enqueueSnackbar } = useSnackbar();
  const [fileDownloading, setFileDownloading] = useState(false);

  const structure = getStructure({
    extraProps: {
      enqueueSnackbar,
      fileDownloading,
      contractId,
      setFileDownloading,
    },
    t,
  });

  let supplierInvoiceColumns: MUIDataTableColumnDef[] = structure.items
    .map((item) =>
      BuilderColumnOptionsSingle({
        data: supplierInvoices,
        disabled: true,
        item,
      }),
    )
    .filter((column: any) => useColumns.includes(column.name));

  if (isLoading) {
    return <LinearProgress />;
  }

  return (
    <MuiDataTableWrapper
      data={supplierInvoices}
      columns={supplierInvoiceColumns}
      isFetching={isFetching}
      options={options}
      tableType="main-with-filter"
      noBackground={true}
    />
  );
};

export default SupplierInvoiceTable;
