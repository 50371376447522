import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export type UserState = {
  table: Record<string, Record<string, boolean>>;
  filter: Record<string, Record<string, any>> & {
    tables: Record<string, Record<string, any>>;
  };
  rowsPerPage: Record<string, number>;
};

export const initialUserState = {
  table: {},
  filter: {
    mainReport: {}, // Insight reports
    contractReports: {},
    tables: {},
  },
  rowsPerPage: {}, // tables - rowsPerPage
} as UserState;

const userStateSlice = createSlice({
  name: "userState",
  initialState: initialUserState,
  reducers: {
    updateUserState(state, action: PayloadAction<UserState>) {
      return { ...state, ...action.payload };
    },
  },
});

export const { updateUserState } = userStateSlice.actions;
export default userStateSlice.reducer;
