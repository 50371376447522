/**
 *Created by Julius Abelson on 2023-02-23
 */

import { CommonBuilderTableTypes } from "../CommonBuilderTableTypes";
import StatusCell from "src/components/Complex/Tables/StatusCell";
import getValue from "../Utils/getValue";
import getShowCellWhen from "../Utils/getShowCellWhen";
import { Box } from "@mui/material";

type BadgeColumnItemTProps = CommonBuilderTableTypes;
/**
 * Use this column item for values that supposed to be a status pill
 * @param props
 * @returns A status cell
 */

const BadgeColumnItem = (props: BadgeColumnItemTProps) => {
  const commonCallbackProps = {
    item: props.item,
    data: props.data,
    rowIndex: props.rowIndex,
  };

  // To display multiplae badges
  if (props.item?.selectionOptions?.multiple) {
    const values =
      getValue({
        data: props.data,
        item: props.item,
        rowIndex: props.rowIndex,
      }) || [];
    return getShowCellWhen(commonCallbackProps) ? (
      <Box sx={{ display: "flex", columnGap: "var(--space-8px)" }}>
        {values?.map((v: string) => (
          <StatusCell
            status={v}
            additionalText={
              (props.item.badgeAdditionalText &&
                props.item.badgeAdditionalText(commonCallbackProps)) ||
              ""
            }
            justifyContent="start"
            stylingType="success-3"
          />
        ))}
      </Box>
    ) : (
      <></>
    );
  }

  return getShowCellWhen(commonCallbackProps) ? (
    <StatusCell
      status={getValue({
        data: props.data,
        item: props.item,
        rowIndex: props.rowIndex,
      })}
      additionalText={
        (props.item.badgeAdditionalText &&
          props.item.badgeAdditionalText(commonCallbackProps)) ||
        ""
      }
    />
  ) : (
    <></>
  );
};

export default BadgeColumnItem;
