/**
 * This is to remove keys with empty string as value
 */

const removeKeysWithEmptyStringAsValue = (data: { [key: string]: any }) => {
  const result: { [key: string]: any } = {};

  Object.keys(data).forEach((key) => {
    if (data[key] !== "") {
      result[key] = data[key];
    }
  });

  return result;
};

export default removeKeysWithEmptyStringAsValue;
