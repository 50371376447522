/**
 *Created by Mikael Lindahl on 2023-11-29
 */

import { useState } from "react";

export type FormGlue<T = any, TSubmit = any> = {
  formData: T;
  formDataInitial: T;
  hasTriedToSubmit: boolean;
  isFormValid: boolean;
  setFormData: (data: T) => void;
  setFormDataInitial: (data: T) => void;
  setHasTriedToSubmit: (value: boolean) => void;
  setIsFormValid: (value: boolean) => void;
  setSubmitData: (data: TSubmit) => void;
  setSubmitDataInitial: (data: TSubmit) => void;
  submitData: TSubmit | undefined;
  submitDataInitial: TSubmit | undefined;
};

const useFormGlue = <T = any, TSubmit = any>() => {
  const [hasTriedToSubmit, setHasTriedToSubmit] = useState(false);
  const [isFormValid, setIsFormValid] = useState(true);
  const [submitData, setSubmitData] = useState<TSubmit>();
  const [submitDataInitial, setSubmitDataInitial] = useState<TSubmit>();

  return {
    hasTriedToSubmit,
    isFormValid,
    setHasTriedToSubmit,
    setIsFormValid,
    setSubmitData,
    setSubmitDataInitial,
    submitData,
    submitDataInitial,
  } as FormGlue<T, TSubmit>;
};

export default useFormGlue;
