import isURL from "validator/es/lib/isURL";
import { StructureItemContainer } from "../components/Builders/Container/CommonBuilderContainerTypes";
import { StructureItemTable } from "../components/Builders/Table/CommonBuilderTableTypes";

const validUrl = <DataType = any>(
  value: any,
  itemComponentType?:
    | StructureItemContainer<DataType>["type"]
    | StructureItemTable<DataType>["type"],
) => {
  let isValidUrl = true;

  if (
    value !== undefined &&
    itemComponentType &&
    ["url_input"].includes(itemComponentType)
  ) {
    isValidUrl = isURL(value, { require_protocol: false });
  }

  return isValidUrl;
};

export default validUrl;
