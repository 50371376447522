import BuildItems from "src/components/Builders/Container/BuildItems";
import ButtonCancel from "src/components/Basic/Simple/Buttons/ButtonCancel";
import ButtonGroup from "src/components/Basic/Simple/Buttons/ButtonGroup";
import ButtonSave from "src/components/Basic/Simple/Buttons/ButtonSave";
import ConsentDialog from "src/components/Basic/Mixed/Dialogs/ConsentDialog";
import DialogContentWrapper from "src/components/Basic/Simple/Dialogs/DialogContentWrapper";
import ModalWrapper from "src/components/Basic/Mixed/Modals/ModalWrapper";
import parseRestApiErrorMessage from "src/utils/parseRestApiErrorMessage";
import toUpdateData from "src/utils/toUpdateData";
import useFormContainer from "src/hooks/useFormContainer";
import useOnFormSubmit from "src/hooks/useOnFormSubmit";
import useTranslation from "src/hooks/useTranslationWrapper";
import { AccessUserRole } from "src/accurasee-backend-types/app/access_user_role/access_user_role.types";
import { Box, Typography, Grid } from "@mui/material";
import { ReturnUser } from "src/accurasee-backend-types/app/user/user.types";
import { getStructure } from "./EditUserModalStructure";
import {
  useGetUserQuery,
  useUpdateUserByIdMutation,
} from "src/redux/services/UserService";
import { logout as logoutUser } from "src/redux/actions/user";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { WarningAmber as WarningAmberIcon } from "@mui/icons-material";

interface EditUserProps {
  data: ReturnUser | undefined;
  handleCloseModal: () => void;
  openModal: boolean;
  userRoles: AccessUserRole[];
}

const EditUserModalWithForm = ({
  data,
  handleCloseModal,
  openModal,
  userRoles,
}: EditUserProps) => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // This is to open a modal that routes user to Transfer Ownerships page
  const [errorMessage, setErrorMessage] = useState("");

  const [updateUser] = useUpdateUserByIdMutation();
  const { data: dataUser, isLoading } = useGetUserQuery(undefined);
  const initialFormData = data;

  const {
    formData,
    helperText,
    isFormValid,
    setFormData,
    setHasTriedToSubmit,
    structure,
  } = useFormContainer({
    initialFormData,
    getStructure,
    extraProps: {
      user: dataUser?.user,
      userRoles,
    },
  });

  const submitData = toUpdateData<ReturnUser>({
    data: formData as ReturnUser,
    initData: data,
  });

  const isMe = dataUser?.user?._id === data?._id;

  const { isSubmitting, onFormSubmit, refForm } = useOnFormSubmit({
    submitProps: {
      apiMutations: {
        update: updateUser,
      },
      data: {
        update: submitData,
      },
      dataId: data?._id ? data._id.toString() : undefined,
      name: "User",
    },
    onPostSubmit: (result) => {
      if (result?.error?.update) {
        const errMsg = parseRestApiErrorMessage(result?.error?.update);
        setErrorMessage(errMsg);
      }
    },
    onSuccess: () => {
      if (!isMe) {
        handleCloseModal();
      } else {
        dispatch(logoutUser());
      }
    },
  });

  const commonPropsBuildItem = {
    data: formData,
    helperText,
  };

  return (
    <>
      <ModalWrapper
        handleCloseModal={handleCloseModal}
        openModal={openModal}
        label={"EDIT USER"}
      >
        <Box sx={{ width: "600px" }}>
          <form
            onSubmit={async (e) => {
              e.preventDefault();
              await onFormSubmit({ action: "update" });
            }}
            ref={refForm}
          >
            <Grid container rowSpacing={2} columnSpacing={1}>
              {BuildItems({
                items: structure.items,
                ...commonPropsBuildItem,
              })}
            </Grid>
            <Box
              sx={{
                display: "flex",
                marginTop: "var(--space-24px)",
                flexDirection: "row-reverse",
              }}
            >
              <ButtonGroup>
                <ButtonCancel
                  onClick={() => {
                    setFormData(data);
                    handleCloseModal();
                  }}
                />
                <ButtonSave
                  dialogContent={
                    isMe && (
                      <DialogContentWrapper
                        title="Are you sure you want change user roles for your own user?"
                        message={"You will be logged out if you do this."}
                      />
                    )
                  }
                  initialSubmitData={{}}
                  isSubmitting={isSubmitting}
                  isValid={isFormValid && !isLoading}
                  onSubmit={() => {
                    refForm.current.requestSubmit();
                    setHasTriedToSubmit(true);
                  }}
                  submitData={submitData}
                />
              </ButtonGroup>
            </Box>
          </form>
        </Box>
      </ModalWrapper>
      {errorMessage ===
        "User is owner of some contracts, deletion not allowed" && (
        <ConsentDialog
          handleClose={() => setErrorMessage("")}
          open={
            errorMessage ===
            "User is owner of some contracts, deletion not allowed"
          }
          consentButtons={[
            { label: t("No") },
            {
              label: t("Yes"),
              onClick: () => {
                navigate("/app/operations/transferownerships");
              },
            },
          ]}
        >
          <WarningAmberIcon
            sx={{
              color: "var(--orange-1)",
              display: "block",
              height: "40px",
              margin: "auto",
              marginBottom: "var(--space-24px)",
              width: "40px",
            }}
          />
          <Typography align="center" color="var(--black-7)">
            {t("User is an owner of contracts, deletion is not allowed.")}
          </Typography>
          <Typography align="center" color="var(--black-7)">
            {t("Do you want to transfer contract ownerships?")}
          </Typography>
        </ConsentDialog>
      )}
    </>
  );
};

export default EditUserModalWithForm;
