/**
 *Created by Mikael Lindahl on 2023-11-23
 */
import { UseFormContainerGetStructure } from "src/hooks/useFormContainer";
import { StructureContainer } from "src/components/Builders/Container/CommonBuilderContainerTypes";
import { AccessUserRole } from "src/accurasee-backend-types/app/access_user_role/access_user_role.types";
import { ReturnUser } from "src/accurasee-backend-types/app/user/user.types";
export type GetStructureExtraProps = {
  user: ReturnUser;
  userRoles?: AccessUserRole[];
};
export const getStructure: UseFormContainerGetStructure<
  ReturnUser,
  GetStructureExtraProps
> = ({ setFormData, extraProps, t }) => {
  const structure: StructureContainer<ReturnUser> = {
    items: [
      {
        type: "text",
        required: false,
        dataName: "firstName",
        label: "First name",
        gridProps: { md: 6 },
        disabled: true,
      },
      {
        type: "text",
        required: false,
        dataName: "lastName",
        label: "Last name",
        gridProps: { md: 6 },
        disabled: true,
      },
      {
        type: "text",
        required: true,
        dataName: "email",
        label: "Email",
      },
      {
        type: "selector",
        dataName: `companies.${extraProps?.user?.activeCompanyId}.roleIds`,
        label: t("Role(s)"),
        selectionOptions: { multiple: true },
        options: extraProps?.userRoles?.map((role: any) => {
          return {
            label: role.name,
            value: role._id,
            isActive: role.active,
          };
        }),
        setFormData,
      },
      {
        type: "switch",
        dataName: "active",
        label: "Active",
        getValue: (props) => {
          return Boolean(props.data?.active);
        },
        setFormData,
      },
    ],
  };
  return structure;
};
