/**
 *Created by Mikael Lindahl on 2023-02-21
 */

import { addRow, toData, toSubmitData } from "./utils/InvoicePlanRow";
import { Box } from "@mui/material";
import { GetSingleContractWithPermissions } from "src/accurasee-backend-types/app/contracts/contract.types";
import { ContractArticle } from "src/accurasee-backend-types/app/contracttype/contracttype.types";
import { findById } from "src/components/Complex/Tables/utils";
import { FormGlue } from "src/hooks/useFormGlue";
import {
  getStructure,
  GetStructureExtraProps,
} from "./InvoicePlanRowsGetStructure";
import { Grid, IconButton, Tooltip } from "@mui/material";
import { InvoicePlanTemplate } from "src/accurasee-backend-types/app/invoiceplantemplate/invoiceplantemplate.types";
import {
  INVOICE_PLAN_TYPE,
  InvoicePlanRow,
  InvoicePlanRowExtended,
  InvoicePlanWithPermissions,
} from "src/accurasee-backend-types/app/invoiceplan/invoiceplan.types";
import { listToDic } from "src/utils/transform";
import { MUIDataTableColumnDef } from "mui-datatables";
import { ReturnUser } from "src/accurasee-backend-types/app/user/user.types";
import { useCreatePlannedInvoicesFromInvoiceplanMutation } from "src/redux/services/PlannedinvoiceService";
import { useGetArticlesQuery } from "src/redux/services/ArticleService";
import { useGetCustomersQuery } from "src/redux/services/CustomerService";
import { useMUIDataTableOptions } from "src/hooks/useMUIDataTableOptions";
import { useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { useState } from "react";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import BuilderColumnOptionsSingle from "src/components/Builders/Table/BuilderColumnOptionsSingle";
import ButtonGroup from "src/components/Basic/Simple/Buttons/ButtonGroup";
import ButtonPrimary from "src/components/Basic/Simple/Buttons/ButtonPrimary";
import clone from "src/utils/clone";
import InvoicesTotals from "src/components/Complex/Tables/InvoicesTotals";
import MilestoneInfoBox from "./components/MilestoneInfoBox";
import MuiDataTableWrapper from "src/components/Basic/Mixed/Tables/MuiDataTableWrapper";
import OffsetModal from "src/components/Complex/Modals/OffsetModal";
import PeriodicPlannedInvoicesCreateDialogButton from "src/components/Screens/PlannedInvoiceBase/utils/PeriodicPlannedInvoicesCreateDialogButton";
import Section from "src/components/Basic/Simple/Sections/Section";
import useExportInvoice from "src/hooks/useExportInvoice";
import useFormTable from "src/hooks/useFormTable";
import useMarkInvoiceRowsAsInvoiced from "src/hooks/useMarkInvoiceRowsAsInvoiced";
import useTranslation from "src/hooks/useTranslationWrapper";

type InvoiceplanRowsRewriteTProps = {
  contract?: GetSingleContractWithPermissions;
  formGlue?: FormGlue<InvoicePlanRow[]>;
  isFetching?: boolean;
  invoicePlanInit?: InvoicePlanWithPermissions;
  invoicePlanTemplates: InvoicePlanTemplate[];
  isValid?: boolean;
};

const InvoicePlanRows = (props: InvoiceplanRowsRewriteTProps) => {
  const [t] = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const { user } = useSelector(
    (state: { user: { user: ReturnUser } }) => state.user,
  );

  const [offsetRowIndex, setOffsetRowIndex] = useState<number>();
  const [openModel, setOpenModal] = useState(false);
  const [selectedRows, setSelectedRows] = useState<InvoicePlanRow[]>([]);

  const [createPlannedinvoicesFromInvoiceplan] =
    useCreatePlannedInvoicesFromInvoiceplanMutation();

  const { data: articlesWithMetaData, isLoading: isLoadingArticles } =
    useGetArticlesQuery(undefined);
  const articlesBase = articlesWithMetaData?.data;

  const { data: customersRes, isLoading: isLoadingCustomers } =
    useGetCustomersQuery(undefined);
  const customers = customersRes?.data;

  const contractArticleDic = listToDic(
    props.contract?.contractArticles as ContractArticle[],
    (c) => c.articleId,
  );

  const customer = findById(customers, props.invoicePlanInit?.customerId);

  const articles = articlesBase?.map((a) => {
    if (contractArticleDic[String(a._id)]) {
      return { ...a, ...contractArticleDic[String(a._id)], _id: a._id }; // _id is overwritten by row._id
    } else {
      return a;
    }
  });

  const { isExportInvoiceLoading, onExportInvoice } = useExportInvoice({
    invoicePlan: props.invoicePlanInit,
    selectedRows,
  });

  const { isMarkAsInvoicedLoading, onMarkAsInvoiced } =
    useMarkInvoiceRowsAsInvoiced({
      invoicePlan: props.invoicePlanInit,
      selectedInvoiceRows: selectedRows,
    });

  const isPerodic = props.invoicePlanInit?.type === INVOICE_PLAN_TYPE.periodic;
  const isMilestone =
    props.invoicePlanInit?.type === INVOICE_PLAN_TYPE.milestone;
  const isSingleInvoice =
    props.invoicePlanInit?.type === INVOICE_PLAN_TYPE.singleinvoice;

  const initialData: InvoicePlanRowExtended[] = toData(
    props?.invoicePlanInit?.invoiceRows,
  );

  const { formData, helperText, setFormData, setHelperText, structure } =
    useFormTable<InvoicePlanRowExtended, GetStructureExtraProps>({
      extraProps: {
        articles,
        customer,
        invoicePlan: props.invoicePlanInit,
        setOffsetRowIndex,
        setOpenModal,
      },
      formGlue: props.formGlue,
      getStructure,
      initialFormData: initialData,
      toSubmitData,
    });

  let columns: MUIDataTableColumnDef[] = structure.items.map((item) =>
    BuilderColumnOptionsSingle<InvoicePlanRowExtended>({
      data: formData,
      helperText,
      item,
    }),
  );

  const { options } = useMUIDataTableOptions({
    pagination: formData.length >= 10,
    filterOptions: isMilestone
      ? {
          isRowSelectable: (dataIndex) =>
            formData[dataIndex].status !== "exported",
          selectableRows: "multiple",
          selectFunction: (_, current) => {
            setSelectedRows(current.map((c) => formData[c.dataIndex]));
          },
          selectToolbarPlacement: "none",
        }
      : undefined,
  });

  const isNotPerodic =
    props?.invoicePlanInit &&
    props?.invoicePlanInit.type !== INVOICE_PLAN_TYPE.periodic;
  const isCreatePlannedInvoicesDisabled =
    props?.invoicePlanInit?.locked ||
    !props?.invoicePlanInit?.invoiceRows ||
    props?.invoicePlanInit?.invoiceRows?.length < 1 ||
    !props.isValid;
  const whyExportInvoicesDisabled = !props.isValid
    ? "Disabled because you have unsaved changes or your data is invalid"
    : props?.invoicePlanInit?.locked
      ? "Disabled because invoice plan is locked"
      : props?.invoicePlanInit?.invoiceRows !== undefined &&
          props?.invoicePlanInit?.invoiceRows?.length < 1
        ? "Disabled because there is no invoice rows"
        : isNotPerodic && selectedRows.length === 0
          ? "Disabled because no rows are selected"
          : "";

  return !!props.invoicePlanInit &&
    props.invoicePlanInit.type !== "runningrate" ? (
    <Section
      label={isMilestone ? "milestones" : "invoice rows"}
      isLoading={isLoadingArticles || isLoadingCustomers}
    >
      {isMilestone ? (
        <MilestoneInfoBox invoicePlan={props.invoicePlanInit} />
      ) : (
        <></>
      )}
      <MuiDataTableWrapper
        title={"Data table"}
        data={formData || []}
        columns={columns}
        options={options}
      />
      <Grid
        item
        xs={12}
        sx={{
          paddingTop: "2rem",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "start",
        }}
      >
        <Box>
          <IconButton
            disableRipple
            color="primary"
            onClick={() =>
              addRow({
                contract: props.contract,
                customer,
                formData,
                helperText,
                invoicePlan: props.invoicePlanInit,
                setFormData,
                setHelperText,
              })
            }
          >
            <AddCircleIcon fontSize="large" />
            <span style={{ fontSize: "16px", paddingLeft: "7px" }}>
              {t("Add row")}
            </span>
          </IconButton>

          <Box sx={{ display: "flex", marginTop: 3 }}>
            <Box sx={{ marginRight: "8px" }}>
              <Tooltip title={whyExportInvoicesDisabled}>
                <div>
                  <ButtonGroup>
                    {isNotPerodic && !isSingleInvoice && (
                      <ButtonPrimary
                        disabled={selectedRows.length === 0 || !props.isValid}
                        isLoading={isExportInvoiceLoading}
                        onClick={async () => {
                          onExportInvoice().catch((e) => console.error(e));
                        }}
                      >
                        {t("Export selected invoice(s)")}
                      </ButtonPrimary>
                    )}
                    {isSingleInvoice &&
                      (props.invoicePlanInit.status === "ongoing" ||
                        props.invoicePlanInit.status === "partiallyinvoiced" ||
                        props.invoicePlanInit.status === "upcoming") && (
                        <ButtonPrimary
                          disabled={!props.isValid}
                          isLoading={isExportInvoiceLoading}
                          onClick={async () => {
                            setSelectedRows(initialData);

                            try {
                              const result =
                                await createPlannedinvoicesFromInvoiceplan({
                                  id: props.invoicePlanInit?._id,
                                });
                              if ("error" in result) {
                                throw result.error;
                              }
                              enqueueSnackbar(t("Created Planned invoices"), {
                                variant: "success",
                              });

                              onExportInvoice({
                                plannedInvoice: {
                                  _id: result.data.data[0]._id,
                                  invoiceRows: initialData,
                                },
                              }).catch((e) => console.error(e));
                            } catch (error: any) {
                              enqueueSnackbar(
                                error.data.error.message
                                  ? error.data.error.message
                                  : t("Could not Create invoices"),
                                {
                                  variant: "error",
                                },
                              );
                            }
                          }}
                        >
                          {t("Create and approve invoice(s)")}
                        </ButtonPrimary>
                      )}
                    {(isPerodic || isSingleInvoice) && (
                      <PeriodicPlannedInvoicesCreateDialogButton
                        disabled={isCreatePlannedInvoicesDisabled}
                        invoiceplan={{
                          ...props.invoicePlanInit,
                          invoiceRows: toSubmitData({ data: formData }),
                        }}
                        contractId={String(props?.invoicePlanInit?.contractId)}
                      />
                    )}
                    {isMilestone && user.isSuperAdmin && (
                      <ButtonPrimary
                        disabled={selectedRows.length === 0 || !props.isValid}
                        isLoading={isMarkAsInvoicedLoading}
                        onClick={async () => {
                          onMarkAsInvoiced().catch((e) => console.error(e));
                        }}
                      >
                        {t("Mark as invoiced")}
                      </ButtonPrimary>
                    )}
                  </ButtonGroup>
                </div>
              </Tooltip>
            </Box>
          </Box>
        </Box>
        <Box sx={{ marginLeft: "auto", width: "fit-content" }}>
          <InvoicesTotals
            invoicePlan={props.invoicePlanInit}
            invoiceRows={formData}
          />
        </Box>
      </Grid>
      <OffsetModal
        openModal={openModel}
        data={
          offsetRowIndex !== undefined
            ? formData[offsetRowIndex].offset
            : undefined
        }
        handleCloseModal={() => {
          setOffsetRowIndex(undefined);
          setOpenModal(false);
        }}
        onChange={({ data }) => {
          let newFormData = [...clone(formData)];

          if (offsetRowIndex !== undefined) {
            newFormData[offsetRowIndex].offset = data;

            setFormData(newFormData);
          }
        }}
      />
    </Section>
  ) : (
    <></>
  );
};

export default InvoicePlanRows;
