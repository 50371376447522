/**
 *Created by Mikael Lindahl on 2023-11-28
 */

import { UserFormTableGetStructureOptional } from "src/hooks/useFormTable";
import Constants from "src/constants/Constants";
import { ContractTypeWithPermissions } from "src/accurasee-backend-types/app/contracttype/contracttype.types";
import { ContractInsightWithPermissions } from "../../../accurasee-backend-types/app/insight/insight.types";

interface GetStructureExtraProps {
  contractTypes?: ContractTypeWithPermissions[];
}

export const getStructure: UserFormTableGetStructureOptional<
  ContractInsightWithPermissions,
  GetStructureExtraProps
> = (props) => {
  return {
    items: [
      {
        dataName: "number",
        headerLabel: "#",
        type: "link",
        sort: true,
        redirectLink: (props) =>
          "/app/contracts/" +
          String(props.data[props.rowIndex].contractId) +
          "/insights",
      },
      {
        type: "text",
        dataName: "projectName",
        headerLabel: "Project",
        sort: true,
        getValue: ({ data, rowIndex }) => {
          const name = data[rowIndex]?.projectName || "";
          const maxLength = Constants.MAX_WIDTH_NUMBER_OF_CHARS_IN_TABLE;
          return name.length > maxLength
            ? name.slice(0, maxLength) + "..."
            : name;
        },
      },
      {
        dataName: "name",
        headerLabel: "Description",
        type: "link",
        sort: true,
        redirectLink: (props) =>
          "/app/contracts/" +
          String(props.data[props.rowIndex].contractId) +
          "/insights",
        getValue: ({ data, rowIndex }) => {
          const name = data[rowIndex]?.name || "";
          const maxLength = Constants.MAX_WIDTH_NUMBER_OF_CHARS_IN_TABLE;
          return name.length > maxLength
            ? name.slice(0, maxLength) + "..."
            : name;
        },
        sx: { minWidth: "378px" },
      },
      {
        type: "badge",
        dataName: "status",
        headerLabel: "Status",
        sort: true,
        filter: true,
        filterOptions: {
          names: [
            "Upcoming",
            "Ongoing",
            "Finished",
            "Locked",
            "Archived",
            "Invoiced",
            "Partially invoiced",
            "Cancelled",
          ],
        },
        alignColumnContent: "center",
      },
      {
        type: "text",
        dataName: "customerName",
        headerLabel: "Customer",
        sort: true,
        getValue: ({ data, rowIndex }) => {
          const name = data[rowIndex]?.customerName || "";
          const maxLength = Constants.MAX_WIDTH_NUMBER_OF_CHARS_IN_TABLE;
          return name.length > maxLength
            ? name.slice(0, maxLength) + "..."
            : name;
        },
      },
      {
        type: "text",
        dataName: "contractTypeId",
        headerLabel: "Contract type",
        sort: true,
        getValue: ({ data, rowIndex }) => {
          const result = props?.extraProps?.contractTypes?.find(
            (e) => e._id === data[rowIndex]?.contractTypeId,
          );
          return result?.name || "";
        },
      },
      {
        type: "text_currency",
        dataName: "yearToDate",
        headerLabel: "Result year to date",
        sort: true,
        getValue: ({ data, rowIndex }) => {
          const { costsThisYear, incomesThisYear } = data[rowIndex];
          const isNumber =
            typeof costsThisYear === "number" &&
            typeof incomesThisYear === "number";
          return isNumber ? incomesThisYear - costsThisYear : 0;
        },
      },
      {
        type: "text_currency",
        dataName: "result",
        headerLabel: "Results",
        sort: true,
        getValue: ({ data, rowIndex }) => {
          const { costs, incomes } = data[rowIndex];
          const isNumber =
            typeof costs === "number" && typeof incomes === "number";
          return isNumber ? incomes - costs : 0;
        },
      },
      {
        type: "text_currency",
        dataName: "incomes",
        headerLabel: "Incomes",
        sort: true,
      },
      {
        type: "text_currency",
        dataName: "costs",
        headerLabel: "Costs",
        sort: true,
      },
      {
        type: "text_currency",
        dataName: "planned_invoices",
        headerLabel: "Planned invoices",
        sort: true,
      },
      {
        type: "date",
        dataName: "startDate",
        sort: true,
        headerLabel: "Start date",
      },
      {
        type: "date",
        dataName: "endDate",
        sort: true,
        headerLabel: "End date",
      },
    ],
  };
};
