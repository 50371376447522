/**
 *Created by Mikael Lindahl on 2023-03-21
 */

import _c from "src/constants/Constants";
import clone from "src/utils/clone";
import sort from "src/utils/sort";
import { ContactPerson } from "src/accurasee-backend-types/app/contact_person/contact_person.types";
import { ContractType } from "src/accurasee-backend-types/app/contracttype/contracttype.types";
import { CreationCategory } from "../ContractCreateBase";
import { Customer } from "src/accurasee-backend-types/app/customer/customer.types";
import { DimensionExtra } from "src/redux/services/DimensionService";
import { ProjectListReturn } from "src/accurasee-backend-types/app/project/project.types";
import { StructureContainer } from "src/components/Builders/Container/CommonBuilderContainerTypes";
import { UseFormContainerGetStructure } from "src/hooks/useFormContainer";
import { dicToList, listToDic } from "src/utils/transform";
import { escape } from "src/utils/translate";
import { lessOrEqualThan, setFormDataDatePair } from "src/utils/date";
import { useState } from "react";
import {
  Contract,
  ContractFeatures,
  DimensionItemContract,
} from "src/accurasee-backend-types/app/contracts/contract.types";
import {
  getFirstAvailableNumber,
  getNumberPart,
  getValidProjectExternals,
  guessNumberPart,
  guessPrefixPart,
  isWithinNumberSeries,
  onErrorNumberSeries,
} from "src/utils/NumberSeries";
import getDimensionItems, {
  ContractCreate,
  ContractExtended,
  TmpDimensionItems,
} from "src/utils/getDimensionItems";
import {
  ProjectExternal,
  ProjectSeries,
  ReturnCompanyWithPermissions,
} from "src/accurasee-backend-types/app/company/company.types";
import getSelectOptions from "../../../../utils/getSelectOptions";
import getSelectOptionsDayWeekMonth from "../../../../utils/getSelectOptionsDayWeekMonth";

export type GetStructureExtraProps = {
  contacts?: ContactPerson[];
  company: ReturnCompanyWithPermissions | undefined;
  contractType?: ContractType | undefined;
  contractTypes?: ContractType[];
  creationCategory: CreationCategory;
  currencyCode: string;
  customers?: Customer[];
  dimensions?: DimensionExtra[];
  isContactModalOpen: boolean;
  isCustomerModalOpen: boolean;
  isFromOffer: boolean;
  projects?: ProjectListReturn[];
  projectExternals: ProjectExternal[] | undefined;
  setContractType: (v: ContractType) => void;
  setCurrencyCode: (v: string) => void;
  setIsContactModalOpen: (v: boolean) => void;
  setIsCustomerModalOpen: (v: boolean) => void;
};

export type ItemsTypes =
  | "additional_contract_data"
  | "info"
  | "info_contract"
  | "info_contract_offer";

export const getNumberSeriesWithFirstAvailableNumber = ({
  projectExternals,
  projectNumberSeries,
  projectNumberSeriesId,
}: {
  projectExternals?: ProjectExternal[];
  projectNumberSeries?: ProjectSeries[];
  projectNumberSeriesId?: string;
}) => {
  const series = getProjectNumberSeries({
    projectNumberSeries,
    projectNumberSeriesId,
  });

  if (!series) {
    return;
  }

  let validProjectExternals = getValidProjectExternals({
    projectExternals,
    series,
  });

  const firstAvailableNumber = getFirstAvailableNumber(
    validProjectExternals?.map((p) => p.parts.number) || [],
    Number(series.firstNumber),
    Number(series.lastNumber),
  );

  return {
    firstAvailableNumber,
    firstAvailableProjectExternalId: `${series.prefix || ""}${
      firstAvailableNumber || ""
    }`,
    ...series,
    localExistingExternal:
      validProjectExternals?.filter((p) => p.isProject) || [],
  };
};

const getProjectNumberSeries = ({
  projectNumberSeries,
  projectNumberSeriesId,
}: {
  projectNumberSeries?: ProjectSeries[];
  projectNumberSeriesId?: string;
}) => {
  return projectNumberSeries?.find(
    (p) => projectNumberSeriesId && p.id === projectNumberSeriesId,
  );
};

export const getContractFeatures = (props: {
  creationCategory?: CreationCategory;
  contractType?: ContractType;
  totalPrice?: number;
}) => {
  const { bkConnected, houseWork, serviceOrderContract } =
    props?.contractType?.contractTypeFeatures || {};

  return props?.contractType?.contractTypeFeatures &&
    props?.creationCategory === "contract"
    ? {
        contractFeatures: {
          bkConnected,
          houseWork,
          serviceOrderContract,
          ...(props?.totalPrice ? { totalPrice: props?.totalPrice } : {}), // Will be ignored in BE if contractType.contractTypeFeature.totalPrice is false
        } as ContractFeatures,
      }
    : {};
};

export const useContractCreateStructure = () => {
  const [selectedNumberSeries, setSelectedNumberSeries] = useState<
    ProjectSeries | undefined
  >();

  const [prefix, setPrefix] = useState<string | undefined>();
  const [useProjectId, setUseProjectId] = useState<boolean>(false);

  const getStructure: UseFormContainerGetStructure<
    ContractCreate,
    GetStructureExtraProps,
    ItemsTypes
  > = ({ setFormData, extraProps, t }) => {
    const dimensionItems = getDimensionItems<ContractCreate, ItemsTypes>({
      contractDimensions: extraProps?.contractType?.contractDimensions,
      dimensions: extraProps?.dimensions,
      itemType: "additional_contract_data",
      setFormData,
    });

    const structure: StructureContainer<ContractCreate, ItemsTypes> = {
      items: [
        {
          itemType: "info",
          type: "switch",
          label: `Use existing project that is not a contract  or contract offer`,
          dataName: "useProjectId",
          getValue: () => useProjectId,
          setFormDataCustom: ({ data, value }) => {
            setUseProjectId(value);
            setSelectedNumberSeries(undefined);
            setPrefix(undefined);
            if (data) {
              setFormData({
                ...data,
                projectExternalId: "",
                projectId: undefined,
                contractTypeId: undefined,
                customerId: undefined,
              });
            }
          },
        },
        {
          itemType: "info",
          showWhen: () => useProjectId,
          required: true,
          type: "autocomplete",
          dataName: "projectId",
          label: "Project",
          options: getSelectOptions({
            data: sort(
              extraProps?.projects?.filter(
                (p) => !p.hasContract && !p.hasContractOffer,
              ) || [],
              (p) => {
                const numberPart = guessNumberPart(p.externalId)
                  .toString()
                  .padStart(10, "0");
                const prefixPart = guessPrefixPart(p.externalId);

                return prefixPart + numberPart;
              },
            ).map((item) => {
              return {
                label: `${item.externalId} ${item.name}`,
                value: String(item._id),
              };
            }),
          }),
          setFormDataCustom: (props) => {
            const project = extraProps?.projects
              ?.filter((p) => !p.hasContract && !p.hasContractOffer)
              .find((item) => item._id === props.value);

            const newData: Partial<ContractExtended> = {
              ...props.data,
              projectExternalId: project?.externalId,
              projectId: props.value,
              ...(extraProps?.creationCategory === "contract"
                ? {
                    startDate: project?.startDate || props.data?.startDate,
                    endDate: project?.endDate || props.data?.endDate,
                  }
                : {}),
            };

            setFormData(newData as ContractExtended);
          },
        },
        {
          dataName: "contractTypeId",
          gridProps: { md: 6 },
          itemType: "info",
          label: "Contract type (Use from contract offer)",
          getValue: (props) =>
            extraProps?.contractTypes?.find(
              (c) => c._id === props.data?.contractTypeId,
            )?.name || "",
          showWhen: () => !!extraProps?.isFromOffer,
          type: "text",
        },
        {
          dataName: "contractTypeId",
          gridProps: { md: 6 },
          itemType: "info",
          label: "Contract type",
          required: true,
          type: "autocomplete",
          showWhen: () => !extraProps?.isFromOffer,
          setFormDataCustom: (props) => {
            const contractType = extraProps?.contractTypes?.find(
              (item) => item._id === props.value,
            );
            const project = extraProps?.projects?.find(
              (item) => item._id === props.data?.projectId,
            );

            const series = getNumberSeriesWithFirstAvailableNumber({
              projectExternals: extraProps?.projectExternals,
              projectNumberSeries: extraProps?.company?.projectNumberSeries,
              projectNumberSeriesId: contractType?.projectNumberSeriesId,
            });

            let projectExternalId: string = "";

            if (project) {
              projectExternalId = project.externalId;
              setSelectedNumberSeries(undefined);
            } else if (series && series.firstAvailableNumber !== null) {
              projectExternalId = `${series.prefix || ""}${
                series.firstAvailableNumber || ""
              }`;
            }

            setSelectedNumberSeries(series);
            setPrefix(series?.prefix);

            const newData: Partial<ContractExtended> = {
              ...props.data,
              ...(!extraProps?.isFromOffer ? { projectExternalId } : {}),
              contractTypeId: props.value,
              ...getContractFeatures({
                creationCategory: extraProps?.creationCategory,
                contractType,
                totalPrice: props.data?.contractFeatures?.totalPrice,
              }),
            };

            if (contractType) {
              extraProps?.setContractType(contractType);
            }

            setFormData(newData as ContractExtended);
          },
          options: getSelectOptions({
            data: extraProps?.contractTypes,
            label: (c) => c.name,
            value: (c) => String(c._id),
          }),
        },
        {
          itemType: "info",
          required: ({ data }) =>
            !data?.contractFeatures?.serviceOrderContract ||
            extraProps?.creationCategory === "contract_offer",
          type: "autocomplete_add",
          onClick: () => {
            extraProps?.setIsCustomerModalOpen(
              !extraProps?.isCustomerModalOpen,
            );
          },
          dataName: "customerId",
          label: "Customer",
          setFormDataCustom: (props) => {
            const customer = extraProps?.customers?.find(
              (item) => item._id === props.value,
            );
            if (customer) {
              extraProps?.setCurrencyCode(customer.currencyCode);
            }

            const newData: Partial<ContractExtended> = {
              ...props.data,
              customerId: props.value,
            };

            setFormData(newData as ContractExtended);
          },
          options: getSelectOptions({
            data: extraProps?.customers,
            label: (c) => c.name,
            value: (c) => String(c._id),
          }),
          gridProps: { md: 6 },
        },
        {
          dataName: "projectExternalId",
          gridProps: { md: 6 },
          itemType: "info",
          label: "Project id  (Use id from project)",
          showWhen: () => useProjectId,
          type: "text",
        },
        {
          dataName: "projectExternalId",
          gridProps: { md: 6 },
          itemType: "info",
          label: "Project id  (Use from contract offer)",
          showWhen: () => !!extraProps?.isFromOffer,
          type: "text",
        },
        {
          dataName: "projectExternalId",
          itemType: "info",
          prefix: prefix,
          required: true,
          showWhen: () => !useProjectId && !extraProps?.isFromOffer,
          type: "text_input",
          label:
            "Project id" +
            (!useProjectId && selectedNumberSeries
              ? `${escape(
                  ` (${selectedNumberSeries?.label || ""}: ${
                    selectedNumberSeries?.prefix || ""
                  }${selectedNumberSeries?.firstNumber ?? ""} - ${
                    selectedNumberSeries?.prefix || ""
                  }${selectedNumberSeries?.lastNumber || ""})`,
                )}`
              : !useProjectId
                ? ""
                : " (Use id from project)"),
          getValue: (props) => {
            const series = getProjectNumberSeries({
              projectNumberSeries: extraProps?.company?.projectNumberSeries,
              projectNumberSeriesId:
                extraProps?.contractType?.projectNumberSeriesId,
            });

            return props.data?.projectExternalId
              ? getNumberPart(props.data?.projectExternalId, series)
              : "";
          },
          getErrorText: (props) => {
            const contractType = extraProps?.contractTypes?.find(
              (item) => item._id === props.data?.contractTypeId,
            );
            const project = extraProps?.projects
              ?.filter((p) => !p.hasContract)
              .find((item) => item._id === props.data?.projectId);

            return onErrorNumberSeries({
              newProjectExternalId: `${props?.data?.projectExternalId}`,
              currentProjectExternalId: project?.externalId,
              existingExternals: extraProps?.projectExternals,
              numberSeries: getProjectNumberSeries({
                projectNumberSeries: extraProps?.company?.projectNumberSeries,
                projectNumberSeriesId: contractType?.projectNumberSeriesId,
              }),
              existingContractNumbers: extraProps?.projectExternals
                ?.filter((p) => p.isContract)
                .map((p) => p.parts.number),
              isCreate: !props.data?.projectId,
            });
          },
          validate: (props) => {
            if (useProjectId) {
              return true;
            }

            // Incase at initial load, we have projectExternalId
            // but haven't set selectedNumberSeries yet
            if (props.data?.projectExternalId && !selectedNumberSeries) {
              const contractType = extraProps?.contractTypes?.find(
                (item) => item._id === props.data?.contractTypeId,
              );

              const series = getNumberSeriesWithFirstAvailableNumber({
                projectExternals: extraProps?.projectExternals,
                projectNumberSeries: extraProps?.company?.projectNumberSeries,
                projectNumberSeriesId: contractType?.projectNumberSeriesId,
              });

              setSelectedNumberSeries(series);
              const isValidate =
                props.data?.projectExternalId &&
                !extraProps?.projectExternals
                  ?.map((p) => p.id)
                  .includes(props.data?.projectExternalId) &&
                isWithinNumberSeries(
                  props?.data?.projectExternalId,
                  selectedNumberSeries,
                );

              return Boolean(isValidate);
            }
            const isValidate =
              props.data?.projectExternalId &&
              !extraProps?.projectExternals
                ?.map((p) => p.id)
                .includes(props.data?.projectExternalId) &&
              isWithinNumberSeries(
                props?.data?.projectExternalId,
                selectedNumberSeries,
              );

            return Boolean(isValidate);
          },
          gridProps: { md: 6 },
          setFormDataCustom: ({ data, value }) => {
            if (data) {
              setFormData({
                ...data,
                projectExternalId: `${prefix || ""}${value}`,
              });
            }
          },
        },
        {
          itemType: "info",
          type: "autocomplete_add",
          required: true,
          onClick: () => {
            extraProps?.setIsContactModalOpen(!extraProps?.isContactModalOpen);
          },
          dataName: "contactPersonId",
          label: "Customer contact person",
          options: ({ data }) =>
            getSelectOptions({
              data: extraProps?.contacts?.filter(
                (contact) => contact.customerId === data?.customerId,
              ),
              label: (c) => `${c.firstName} ${c.lastName}`,
              value: (c) => String(c._id),
            }),
          setFormData,
          gridProps: { md: 6 },
          // Able to select only if a customer is selected
          disabled: ({ data }) => !data?.customerId,
        },
        {
          itemType: "info_contract",
          required: true,
          type: "date",
          dataName: "startDate",
          label: "Start date",
          gridProps: { md: 6 },
          validate: (props) =>
            lessOrEqualThan(props.data?.startDate, props.data?.endDate),
          getErrorText: "Start needs come before end date",
          setFormDataCustom: ({ data, value }) => {
            setFormDataDatePair({
              data,
              otherKey: "endDate",
              otherAction: "to_end_of_month",
              value,
              valueKey: "startDate",
              setFormData,
            });
          },
        },
        {
          itemType: "info_contract",
          required: true,
          type: "date",
          dataName: "endDate",
          label: "End date",
          gridProps: { md: 6 },
          validate: (props) =>
            lessOrEqualThan(props.data?.startDate, props.data?.endDate),
          getErrorText: "End needs to come after start date",
          setFormDataCustom: ({ data, value }) => {
            setFormDataDatePair({
              data,
              otherKey: "startDate",
              otherAction: "to_start_of_month",
              value,
              valueKey: "endDate",
              setFormData,
            });
          },
        },
        {
          itemType: "info_contract",
          validate: (props) => {
            if (
              props.data?.name &&
              (props.data?.name.length <= 1 || props.data?.name.length > 49)
            ) {
              return false;
            } else {
              return true;
            }
          },
          getErrorText: (props) => {
            if (props.data?.name && props.data?.name.length <= 1) {
              return "To short";
            } else if (props.data?.name && props.data?.name.length > 49) {
              return "To long (max length 50 characters)";
            } else {
              return "";
            }
          },
          required: true,
          type: "text_input",
          dataName: "name",
          label: "Name",
          setFormData,
        },
        {
          itemType: "additional_contract_data",
          currencyCode: extraProps?.currencyCode,
          dataName: "contractFeatures.totalPrice",
          type: "number_input_currency",
          label: "Total price of contract",
          gridProps: { xs: 6 },
          showWhen: (props) =>
            Boolean(extraProps?.contractType?.contractTypeFeatures?.totalPrice),
          setFormData,
        },
        {
          itemType: "additional_contract_data",
          dataName: "contractFeatures.houseWork",
          type: "switch",
          label: "House work",
          gridProps: { xs: 6 },
          showWhen: (props) =>
            Boolean(extraProps?.contractType?.contractTypeFeatures?.houseWork),
          setFormData,
        },
        {
          itemType: "additional_contract_data",
          gridProps: { xs: 6 },
          type: "object",
          dataName: "endDateReminder",
          label: "Email reminder before end date",
          showWhen: (props) =>
            Boolean(
              extraProps?.contractType?.contractTypeFeatures?.endDateReminder &&
                extraProps?.contractType?.renewalTerms?.renewalType ===
                  "manual",
            ),
          items: [
            {
              gridProps: { xs: 3 },
              type: "number",
              dataName: "endDateReminder.number",
              validate: (props) => {
                if (props.data?.endDateReminder?.number) {
                  return props.data?.endDateReminder?.number > 0;
                } else {
                  return false;
                }
              },
              getErrorText: "Must be grater than zero",
              setFormData,
            },
            {
              gridProps: { xs: 9 },
              type: "selector",
              dataName: "endDateReminder.unit",
              options: getSelectOptionsDayWeekMonth(t),
              setFormData,
            },
          ],
        },
        ...dimensionItems,
        {
          itemType: "additional_contract_data",
          dataName: "contractFeatures.bkConnected",
          type: "switch",
          label: "BK connected",
          gridProps: { xs: 6 },
          showWhen: (props) =>
            Boolean(
              extraProps?.contractType?.contractTypeFeatures?.bkConnected,
            ),
          setFormData,
        },
        {
          itemType: "additional_contract_data",
          gridProps: { xs: 6 },
          type: "switch",
          label: "Service order contract",
          dataName: "contractFeatures.serviceOrderContract",
          showWhen: ({ data }) =>
            Boolean(
              extraProps?.contractType?.contractTypeFeatures
                ?.serviceOrderContract,
            ) && !!data?.contractFeatures?.bkConnected,
          setFormData,
        },
        {
          itemType: "info_contract_offer",
          validate: (props) => {
            if (
              props.data?.name &&
              (props.data?.name.length <= 1 || props.data?.name.length > 49)
            ) {
              return false;
            } else {
              return true;
            }
          },
          getErrorText: (props) => {
            if (props.data?.name && props.data?.name.length <= 1) {
              return "To short";
            } else if (props.data?.name && props.data?.name.length > 49) {
              return "To long (max length 50 characters)";
            } else {
              return "";
            }
          },
          required: true,
          type: "text_input",
          dataName: "name",
          label: "Offer name",
          gridProps: { md: 6 },
          setFormData,
        },
        {
          itemType: "info_contract_offer",
          type: "date",
          dataName: "deadline",
          label: "Deadline",
          gridProps: { md: 6 },
          setFormData,
        },
        {
          itemType: "info_contract_offer",
          type: "text_input",
          dataName: "description",
          label: "Description",
          minRows: 3,
          multiline: true,
          setFormData,
        },
      ],
    };

    return structure;
  };

  return { getStructure };
};

export const toData = ({ data }: { data?: Partial<Contract> | undefined }) => {
  if (data === undefined) {
    return undefined;
  }

  const tmpDimensionItems: TmpDimensionItems = listToDic<DimensionItemContract>(
    data?.dimensionItems || [],
    (v) => String(v.dimensionId),
  );

  return {
    ...data,
    tmpDimensionItems,
  } as ContractCreate;
};

export const toSubmitData = ({
  creationCategory,
  data,
}: {
  creationCategory: CreationCategory;
  data: ContractCreate | undefined;
}) => {
  if (!data) {
    return;
  }

  const submitData = clone<ContractCreate>(data);

  if (data === undefined) {
    return data;
  }

  if (submitData.tmpDimensionItems) {
    submitData.dimensionItems = dicToList(submitData.tmpDimensionItems);
    delete submitData.tmpDimensionItems;
    for (let item of submitData.dimensionItems) {
      //@ts-ignore
      delete item._id;
    }
  }

  if (
    submitData.projectId === _c.DEFAULT_CREATE_PROJECT._id ||
    creationCategory === "contract_offer"
  ) {
    //@ts-ignore
    delete submitData.projectId;
  }

  if (submitData?.name) {
    submitData.name = submitData?.name.slice(0, 49);
  }

  return submitData as ContractCreate;
};
