import _c from "src/constants/Constants";
import { Box } from "@mui/material";
import { ReportGetRequestQuery } from "src/accurasee-backend-types/app/report/report.types";
import { useContext, useEffect } from "react";
import { useGetReportsQuery } from "src/redux/services/ReportService";
import { useMUIDataTableOptions } from "src/hooks/useMUIDataTableOptions";
import { useParams } from "react-router-dom";
import { UserStateContext } from "src/context/UserStateProvider";
import BoxHeading from "src/components/Basic/Mixed/Headings/BoxHeading";
import BuildItems from "src/components/Builders/Container/BuildItems";
import ButtonSecondary from "src/components/Basic/Simple/Buttons/ButtonSecondary";
import getStructure, { getColumns } from "./InsightReportStructure";
import LinearProgress from "@mui/material/LinearProgress";
import MuiDataTableWrapper from "src/components/Basic/Mixed/Tables/MuiDataTableWrapper";
import toDay from "src/utils/date";
import useFormContainer from "src/hooks/useFormContainer";
import useTranslation from "src/hooks/useTranslationWrapper";

const InsightReport = ({
  reportTemplateId,
  reportTemplateName,
  baseFilter,
}: {
  reportTemplateId?: string;
  reportTemplateName: string;
  baseFilter: Partial<ReportGetRequestQuery>;
}) => {
  const [t] = useTranslation();
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth();
  const today = new Date();

  const { contractId } = useParams<{ contractId: string }>();

  const { userState, setUserState } = useContext(UserStateContext);

  const { formData, helperText, setFormData, structure } = useFormContainer<
    Partial<ReportGetRequestQuery>
  >({
    getStructure,
    initialFormData: {
      ...baseFilter,
      ...(contractId
        ? userState?.filter?.contractReports &&
          Object.keys(userState?.filter?.contractReports)?.includes(contractId)
          ? { ...userState?.filter?.contractReports[contractId] }
          : {}
        : { ...userState?.filter?.mainReport }),
    },
  });

  useEffect(() => {
    if (formData) {
      const newState = {
        ...userState,
        filter: {
          ...userState?.filter,
          ...(contractId
            ? {
                contractReports: {
                  ...userState?.filter?.contractReports,
                  [contractId]: {
                    ...formData,
                    startDate: toDay(formData.startDate),
                    endDate: toDay(formData.endDate),
                  },
                },
              }
            : {
                mainReport: {
                  ...userState?.filter?.mainReport,
                  ...formData,
                  startDate: toDay(formData.startDate),
                  endDate: toDay(formData.endDate),
                },
              }),
        },
      };
      setUserState(newState);
    }
  }, [formData]);

  const filter: any = {
    ...(reportTemplateId ? { reportTemplateId } : {}),
    isTranslated: true,
    startDate: toDay(formData?.startDate),
    endDate: toDay(formData?.endDate),
    resolution: formData?.resolution,
    ...(contractId ? { contractId } : {}),
  };
  const { data: dataQuery, isLoading } = useGetReportsQuery(filter);

  const reports = dataQuery?.data || [];
  const columns = getColumns({
    columns: dataQuery?.columns || [],
    data: reports,
    t,
  });

  const commonPropsBuildItem = { data: formData, helperText };
  const { options } = useMUIDataTableOptions({
    filterOptions: { selectableRows: "none" },
    pagination: reports && reports?.length > 10,
    customButton: () => {
      return (
        <Box sx={{ display: "inline-flex", columnGap: "var(--space-8px)" }}>
          <ButtonSecondary
            onClick={() => {
              // startOfMonth
              const startDate = new Date(currentYear, currentMonth - 6);
              // endOfMonth
              const endDate = new Date(currentYear, currentMonth + 1, 0);

              setFormData({
                ...formData,
                startDate,
                endDate,
              });
            }}
          >
            {t("Last 6 months")}
          </ButtonSecondary>
          <ButtonSecondary
            onClick={() => {
              const startDate = new Date(currentYear, 0);
              setFormData({
                ...formData,
                startDate,
                endDate: today,
              });
            }}
          >
            {t("Year to date")}
          </ButtonSecondary>
        </Box>
      );
    },
  });

  return (
    <>
      <BoxHeading
        label={reportTemplateName}
        rightBoxArea={BuildItems({
          items: structure.items,
          ...commonPropsBuildItem,
        })}
        rightBoxAreaSx={{
          display: "flex",
          columnGap: "var(--space-8px)",
        }}
      />
      {isLoading ? (
        <LinearProgress />
      ) : (
        <MuiDataTableWrapper
          columns={columns}
          data={reports}
          tableType="main-with-filter"
          options={{ ...options }}
          title=""
          noBackground
        />
      )}
    </>
  );
};

export default InsightReport;
