import BuilderColumnOptionsSingle from "src/components/Builders/Table/BuilderColumnOptionsSingle";
import ContractInsightsDateFilter from "./ContractInsightsDateFilter";
import MuiDataTableWrapper from "src/components/Basic/Mixed/Tables/MuiDataTableWrapper";
import Page from "src/components/Basic/Mixed/Pages/Page";
import toDay from "src/utils/date";
import useMUIDataTableOptionsServerSide from "src/hooks/useMUIDataTableOptionsServerSide";
import { ContractInsightWithPermissions } from "src/accurasee-backend-types/app/insight/insight.types";
import { ContractStatus } from "src/accurasee-backend-types/app/contracts/contract.types";
import { LinearProgress } from "@mui/material";
import { MUIDataTableColumnDef } from "mui-datatables";
import { getStructure } from "./ContractInsightsTableStructure";
import { useGetContractTypesQuery } from "src/redux/services/ContractTypesServices";
import { useGetInsightContractsQuery } from "src/redux/services/InsightService";
import { useState } from "react";

export type Filter = {
  status: ContractStatus[];
  sortBy: string;
  sort: "asc" | "desc";
  // Default is this fiscal year, for ex. from 1.1.2023 - today's date
  startDate: Date;
  endDate: Date;
};

interface ContractInsightsTableProps {
  filter: Filter;
}

const ContractInsightsTable = ({ filter }: ContractInsightsTableProps) => {
  const { data: contractTypesQuery, isLoading: isLoadingContractType } =
    useGetContractTypesQuery(undefined);
  const contractTypes = contractTypesQuery?.data || [];

  const [filterData, setFilterData] = useState({
    startDate: filter.startDate,
    endDate: filter.endDate,
  });

  const {
    options,
    data: contracts,
    isLoading,
    isFetching,
  } = useMUIDataTableOptionsServerSide<ContractInsightWithPermissions>({
    dataName: "contract insights",
    defaultPaginationOptions: [10, 15, 50],
    filterInit: {
      base: {
        pageSize: 15,
        sort: filter?.sort,
        sortBy: filter?.sortBy,
      },
      match: {
        endDate: toDay(filterData?.endDate),
        startDate: toDay(filterData?.startDate),
        status: filter.status,
      },
    },
    filterOptions: {
      selectableRows: "none",
    },
    triggerChangeOnFilterInit: ["endDate", "startDate"],
    useGetDataQuery: useGetInsightContractsQuery,
  });

  const structure = getStructure({
    extraProps: {
      contractTypes,
    },
  });

  let columns: MUIDataTableColumnDef[] = structure.items.map((item) =>
    BuilderColumnOptionsSingle({
      data: contracts,
      disabled: true,
      item,
    }),
  );

  if (isLoading || isLoadingContractType) return <LinearProgress />;

  return (
    <Page
      label="Contract insights"
      sx={{ borderRadius: "8px" }}
      breadcrumbs={[{ label: "Insights" }, { label: "Contract" }]}
      boxRight={
        <ContractInsightsDateFilter
          filterData={filterData}
          setFilterData={setFilterData}
        />
      }
    >
      <MuiDataTableWrapper
        data={contracts}
        columns={columns}
        isFetching={isFetching}
        options={options}
        tableType="main-with-filter"
        noBackground
      />
    </Page>
  );
};

export default ContractInsightsTable;
