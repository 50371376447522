/**
 *Created by Mikael Lindahl on 2023-02-22
 */
import React from "react";
import {
  CommonBuilderTableTypes,
  CommonColumnOptionTypes,
} from "./CommonBuilderTableTypes";
import { MUIDataTableColumnOptions, MUIDataTableMeta } from "mui-datatables";
import _c from "src/constants/Constants";

type GetColumnOptionsTProps = CommonColumnOptionTypes;
type GetColumnOptions = (props: GetColumnOptionsTProps) => {
  customBodyRender: (
    value: any,
    tableMeta: MUIDataTableMeta,
    updateValue: (value: string) => void,
  ) => string | React.ReactNode;
};

const getColumnOptions: GetColumnOptions = (props) => {
  const customBodyRender: MUIDataTableColumnOptions["customBodyRender"] = (
    value,
    tableMeta,
  ) => {
    // The field is highlighted if it is different from the initial value

    const itemProps: CommonBuilderTableTypes = {
      buttonType: props.buttonType,
      data: props.data,
      disabled: props.disabled,
      format: props.format,
      helperText: props.helperText,
      formatValueDisplayed: props.formatValueDisplayed,
      isClickable: props.isClickable,
      isCurrency: props.isCurrency,
      isTextWithPrefixBullet: props.isTextWithPrefixBullet,
      isTextWithPrefixIcon: props.isTextWithPrefixIcon,
      isTextWithOptionalBadge: props.isTextWithOptionalBadge,
      item: props.item,
      justifyContent: props.justifyContent,
      parse: props.parse,
      rowIndex: tableMeta.rowIndex,
      style: props.style,
      t: props.t,
      unit: props.unit,
    };

    return React.createElement(props.ColumnItem, itemProps);
  };

  return { customBodyRender };
};

export default getColumnOptions;
