/**
 *Created by Mikael Lindahl on 2022-09-28
 */

import { Types } from "mongoose";
import { RowDimensions } from "src/accurasee-backend-types/app/general/invoice.types";

type NumberSeriesRow = {
  id: string;
  label: string;
  firstNumber: string;
  lastNumber: string;
} & NumberSeriesIsActive;

type NumberSeriesIsActive = {
  id: string;
  isActive: boolean;
};

type ValidateLabel = (
  label: string,
  data: NumberSeriesRow[],
) => { error: string };

type ValidateNumberRelation = (
  firstNumber: string,
  lastNumber: string,
) => { error: string };

type ValidateNumberOverlap = (
  number: string,
  data: NumberSeriesRow[],
) => { error: string };

type ValidateIsNegative = (number: string) => { error: string };

export const validateLabel: ValidateLabel = (label, data) => {
  let isNotUnique = data
    .map((d) => d.label.toLowerCase())
    .includes(label.toLowerCase());

  return { error: isNotUnique ? "Please provide a unique label" : "" };
};

export const checkDimension = <T extends RowDimensions>(
  dimensionItemIds: Types.ObjectId[],
  object: T,
) => {
  let match = false;
  let arrIndex = -1;
  if (!dimensionItemIds || dimensionItemIds.length === 0)
    return { match: true, arrIndex }; // Nothing to match is considered a match

  const dimensionItemIdsOnObject = Object.keys(object)
    .filter((key) => key.includes("dimensionId") && object[key as keyof T])
    .map((key) => String(object[key as keyof RowDimensions]));

  if (dimensionItemIdsOnObject?.length === 0) return;

  dimensionItemIds?.forEach((itemId, index) => {
    if (dimensionItemIdsOnObject.includes(String(itemId))) {
      console.log("match", itemId, dimensionItemIdsOnObject);
      match = true;
      arrIndex = index;
    }
  });
  console.log("match", match, arrIndex);

  return { match, arrIndex };
};

export const validateNumberRelation: ValidateNumberRelation = (
  firstNumber,
  lastNumber,
) => {
  const isError =
    firstNumber !== "" &&
    lastNumber !== "" &&
    Number(firstNumber) >= Number(lastNumber);

  return {
    error: isError
      ? "First number cant be equal or larger than last number"
      : "",
  };
};

export const validateNumberOverlap: ValidateNumberOverlap = (number, data) => {
  let isOverlap = false;
  for (let d of data) {
    if (
      Number(d.firstNumber) <= Number(number) &&
      Number(number) <= Number(d.lastNumber)
    ) {
      isOverlap = true;
    }
  }

  return {
    error: isOverlap
      ? "Number is overlapping with existing series. Please correct it"
      : "",
  };
};
export const validateIsNegative: ValidateIsNegative = (number) => {
  return {
    error:
      number !== "" && Number(number) < 1
        ? "Number series needs to start from 1 or above"
        : "",
  };
};
