import { InvoicePlan } from "src/accurasee-backend-types/app/invoiceplan/invoiceplan.types";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import ButtonSubmitWithYesNoDialog from "src/components/Basic/Simple/Buttons/ButtonSubmitWithYesNoDialog";
import DialogContentWrapper from "src/components/Basic/Simple/Dialogs/DialogContentWrapper";
import plannedInvoiceApi from "src/redux/services/PlannedinvoiceService";
import useTranslation from "src/hooks/useTranslationWrapper";

type PeriodicPlannedInvoicesCreateDialogButtonTProps = {
  disabled: boolean;
  invoiceplan: InvoicePlan;
  contractId: string;
};

export default function PeriodicPlannedInvoicesCreateDialogButton(
  props: PeriodicPlannedInvoicesCreateDialogButtonTProps,
) {
  const [t] = useTranslation();
  const [createPlannedinvoicesFromInvoiceplan] =
    plannedInvoiceApi.useCreatePlannedInvoicesFromInvoiceplanMutation();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  return (
    <ButtonSubmitWithYesNoDialog
      dialogContent={
        <DialogContentWrapper title="Are you sure you want to generate invoices from the invoice plan?" />
      }
      disabled={props.disabled}
      label={"Create Planned invoices"}
      onSubmit={async () => {
        try {
          const result = await createPlannedinvoicesFromInvoiceplan({
            id: props.invoiceplan._id,
          });
          if ("error" in result) {
            throw result.error;
          }
          enqueueSnackbar(t("Created Planned invoices"), {
            variant: "success",
          });
          navigate(`/app/contracts/${props.contractId}/invoices`);
        } catch (error: any) {
          enqueueSnackbar(
            error.data.error.message
              ? error.data.error.message
              : t("Could not Create invoices"),
            {
              variant: "error",
            },
          );
        }
      }}
    />
  );
}
