import {
  LOGIN_USER,
  REGISTER_USER,
  LOGOUT_USER,
  GET_USER,
  UPDATE_USER,
  RECOVER_PASSWORD_USER,
} from "../actions/types";

import { setToken } from "../../general/Network";
import { ReturnUser } from "../../accurasee-backend-types/app/user/user.types";
import { RootState } from "./index";

let initialUserStorage = localStorage.getItem("user");

// Different browsers handle localStorage differently.
// Better to see if the user is parsable.
// If it is than there exists a user. Otherwise empty object.

let initialUser: Partial<ReturnUser> | null;
try {
  initialUser = initialUserStorage ? JSON.parse(initialUserStorage) : {};
} catch {
  initialUser = {};
}

const initialState = {
  isAuth: Object.keys(initialUser || {}).length > 0,
  user: initialUser,
  token: localStorage.getItem("token") || "",
  isInitialized: false,
};

function makeUserState(state: RootState["user"], payload: RootState["user"]) {
  localStorage.setItem("user", JSON.stringify(payload.user));
  localStorage.setItem("token", payload.token ? payload.token : "");

  setToken(payload.token);
  return {
    ...state,
    user: payload.user,
    token: payload.token,
    isAuth: payload.user ? true : false,
    isInitialized: true,
  };
}

const loginReducer = (
  state: RootState["user"] = initialState,
  action: {
    type:
      | "LOGIN_USER"
      | "REGISTER_USER"
      | "GET_USER"
      | "UPDATE_USER"
      | "LOGOUT_USER"
      | "RECOVER_PASSWORD_USER";
    payload: RootState["user"];
  },
) => {
  const { type, payload } = action;

  switch (type) {
    case LOGIN_USER:
      return makeUserState(state, payload);
    case REGISTER_USER:
      return makeUserState(state, payload);
    case GET_USER:
      return makeUserState(state, payload);
    case UPDATE_USER:
      return makeUserState(state, payload);
    case LOGOUT_USER || RECOVER_PASSWORD_USER:
      localStorage.removeItem("user");
      localStorage.removeItem("token");
      setToken(null);
      return {
        ...state,
        user: null,
        token: null,
        isAuth: false,
        isInitialized: true,
      };
    default:
      return state;
  }
};

export default loginReducer;
