/**
 *Created by Mikael Lindahl on 2023-03-13
 */

import { AdapterDateFns as DateAdapter } from "@mui/x-date-pickers/AdapterDateFns";
import { Box, Grid, LinearProgress } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { SxProps } from "@mui/system/styleFunctionSx";
import { Theme, ThemeProvider } from "@mui/material/styles";
import { useContext, useEffect } from "react";
import { UseMUIDataTableFilterStatesReturn } from "src/hooks/useMUIDataTableFilterStates";
import { UserStateContext } from "src/context/UserStateProvider";
import clone from "src/utils/clone";
import DraggableDataTable from "./DraggableDataTable";
import jsSHA from "jssha";
import MUIDataTable, {
  MUIDataTableColumn,
  MUIDataTableProps,
} from "mui-datatables";
import styles from "./MuiDataTableWrapperStyles";
import usePersistColumnVisibility from "src/hooks/usePersistColumnVisibility";
import useTableFilterSettings from "src/hooks/useTableFilterSettings";

const MuiDataTableWrapper = ({
  columns: columnsIn,
  draggable,
  fontSizeCells,
  nameSpace,
  lessPadding,
  isFetching,
  isLoading,
  tableType,
  marginTop,
  options,
  title,
  titlePosition = "middle",
  disabled,
  noBackground,
  setData,
  ...rest
}: Omit<MUIDataTableProps, "title"> & {
  draggable?: boolean;
  fontSizeCells?: string;
  nameSpace?: string;
  lessPadding?: boolean;
  isFetching?: boolean;
  isLoading?: boolean;
  marginTop?: string;
  options?: MUIDataTableProps["options"] & {
    onChangeAdditionalFilter?: UseMUIDataTableFilterStatesReturn["setAdditionalFilter"];
  };
  setData?: (v: any) => void;
  tableType?:
    | "compact"
    | "main-with-filter"
    | "main"
    | "main-nested-level-1-centered"
    | "main-nested-level-1"
    | "main-nested-level-2"; // nested subtable level 2 - not contain subtable
  title?: string;
  titlePosition?: "middle" | "top";
  disabled?: boolean;
  noBackground?: boolean;
}) => {
  const { userState } = useContext(UserStateContext);

  useEffect(() => {
    if (
      options?.onChangeAdditionalFilter &&
      userState?.filter?.tables?.hasOwnProperty(id)
    ) {
      options.onChangeAdditionalFilter({
        ...userState.filter.tables[id],
      });
    }
    if (
      options?.onChangeRowsPerPage &&
      userState?.rowsPerPage?.hasOwnProperty(id)
    ) {
      options.onChangeRowsPerPage(userState.rowsPerPage[id]);
    }
  }, []);

  // Remove columns that are not displayed
  const columns = clone(columnsIn).filter((c) =>
    typeof c !== "string" ? !!c?.options?.display : true,
  );

  const columnNames = columns.map((column) =>
    typeof column === "string" ? column : column.name,
  );

  const shaObj = new jsSHA("SHA-256", "TEXT");
  shaObj.update(columnNames.join(""));
  const id = shaObj.getHash("HEX");

  const { addColumnVisibility, onColumnViewChange } =
    usePersistColumnVisibility({
      columnNames,
      id,
    });

  const { onFilterChange, onSetFilterList, onChangeRowsPerPage } =
    useTableFilterSettings({
      id,
      onFilterChange: options?.onFilterChange,
      onChangeRowsPerPage: options?.onChangeRowsPerPage,
    });

  addColumnVisibility(columns);
  onSetFilterList(columns);

  options = options || {};
  options.onColumnViewChange = onColumnViewChange;
  options.onFilterChange = onFilterChange;
  options.onChangeRowsPerPage = onChangeRowsPerPage;

  let theme: (baseTheme: any) => Theme;
  switch (tableType) {
    case "compact":
      theme = styles.muiDataCompactTable({ fontSizeCells });
      break;
    case "main-with-filter":
      theme = styles.muiDataTableMainWithFilters({ fontSizeCells, disabled });
      break;
    case "main":
      theme = styles.muiDataTableMain({
        fontSizeCells,
      });
      break;
    case "main-nested-level-1-centered":
      theme = styles.muiDataTableNested({
        align: "center",
        fontSizeCells,
        titlePosition,
      });
      break;
    case "main-nested-level-1":
      theme = styles.muiDataTableNested({
        fontSizeCells,
        titlePosition,
      });
      break;
    case "main-nested-level-2":
      theme = styles.muiDataTableNested({
        fontSizeCells,
        fontStyle: "italic",
        hasBackground: true,
      });
      break;
    default:
      theme = styles.muiDataTableMain({
        fontSizeCells,
      });
      break;
  }

  let sx: SxProps = {
    width: "100%",
    backgroundColor: noBackground ? "transparent" : "var(--white-primary)",
    "& .MuiInput-root": {
      // this is for the input search
      backgroundColor: noBackground ? "transparent" : "var(--white-primary)",
      fontSize: "14px",
    },
  };

  if (marginTop) {
    sx.marginTop = marginTop;
  }

  if (options?.onRowClick) {
    sx = {
      ...sx,
      "& .MuiTableRow-root:hover": {
        cursor: "pointer",
      },
    };
  }

  return (
    <Grid item xs={12} paddingTop={0}>
      {isLoading ? (
        <Box
          width={"100%"}
          sx={{ height: "3rem", paddingTop: "3rem", paddingX: "1rem" }}
        >
          <LinearProgress />
        </Box>
      ) : (
        <LocalizationProvider dateAdapter={DateAdapter}>
          {isFetching && (
            <Box
              width={"100%"}
              sx={{ height: "3rem", paddingTop: "3rem", paddingX: "1rem" }}
            >
              <LinearProgress />
            </Box>
          )}
          <ThemeProvider theme={theme}>
            <Box className={nameSpace || ""} sx={sx}>
              {draggable && setData ? (
                <DraggableDataTable
                  columns={columns as MUIDataTableColumn[]}
                  setData={setData}
                  {...rest}
                  title={title}
                />
              ) : (
                <MUIDataTable
                  columns={columns}
                  options={{ ...options }}
                  {...rest}
                  title={title || ""}
                />
              )}
            </Box>
          </ThemeProvider>
        </LocalizationProvider>
      )}
    </Grid>
  );
};

export default MuiDataTableWrapper;
