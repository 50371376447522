import React, { createContext } from "react";
import { RootState } from "../redux/reducers";
import {
  initialUserState,
  UserState,
  updateUserState,
} from "../redux/slices/userState";
import { useSelector, useDispatch } from "react-redux";
import flattenObj from "src/utils/flattenObject";
import { getNestedObjectData, setNestedObjectData } from "src/utils/nestedData";

// Define the context type
export const UserStateContext = createContext<{
  userState: UserState;
  setUserState: (userState: UserState) => void;
}>({
  userState: initialUserState,
  setUserState: () => {},
});

// Context provider component with types
interface UserStateProviderProps {}

export const UserStateProvider: React.FC<UserStateProviderProps> = ({
  children,
}) => {
  const dispatch = useDispatch();
  const userState = useSelector<RootState, UserState>(({ userState }) => {
    const flattenedInitial = flattenObj(initialUserState);

    for (const key in flattenedInitial) {
      const value = flattenedInitial[key as keyof typeof flattenedInitial];
      const currentVal = getNestedObjectData({ data: userState, key });

      if (currentVal === undefined) {
        setNestedObjectData({ data: userState, key, value });
      }
    }
    return userState;
  });

  const setUserState = (userState: UserState) => {
    dispatch(updateUserState(userState));
  };

  return (
    <UserStateContext.Provider
      value={{
        userState,
        setUserState,
      }}
    >
      {children}
    </UserStateContext.Provider>
  );
};
