import { ContractWithPermissionsAndTotals } from "src/accurasee-backend-types/app/contracts/contract.types";
import { getStructure } from "./ContractTableStructure";
import { LinearProgress } from "@mui/material";
import { MUIDataTableColumnDef } from "mui-datatables";
import { useGetCompanyQuery } from "src/redux/services/CompanyService";
import { useGetContractTypesQuery } from "src/redux/services/ContractTypesServices";
import { useGetCompanyUsersQuery } from "src/redux/services/UserService";
import { useLocation } from "react-router-dom";
import { useSnackbar } from "notistack";
import BuilderColumnOptionsSingle from "src/components/Builders/Table/BuilderColumnOptionsSingle";
import MuiDataTableWrapper from "src/components/Basic/Mixed/Tables/MuiDataTableWrapper";
import useMUIDataTableOptionsServerSide from "src/hooks/useMUIDataTableOptionsServerSide";
import useOnSubmit from "src/hooks/useOnSubmit";
import useTranslation from "src/hooks/useTranslationWrapper";
import {
  useGetContractsQuery,
  useDeleteContractMutation,
} from "src/redux/services/ContractService";

interface ContractTableProps {
  contractFilter: any;
}

const ContractsTable = ({ contractFilter }: ContractTableProps) => {
  const [t] = useTranslation();
  const location = useLocation();

  const [deleteContract] = useDeleteContractMutation();
  const { data: companyQuery, isLoading: isLoadingCompany } =
    useGetCompanyQuery(undefined);
  const company = companyQuery?.company;

  const { data: contractTypesQuery, isLoading: isLoadingContractType } =
    useGetContractTypesQuery(undefined);
  const contractTypes = contractTypesQuery?.data || [];

  const { data: usersResponse, isLoading: isLoadingUsers } =
    useGetCompanyUsersQuery(undefined);
  const users = usersResponse?.data || [];

  const onSubmit = useOnSubmit({
    apiMutations: { delete: deleteContract },
    name: "contract",
  });

  const { enqueueSnackbar } = useSnackbar();

  const {
    options,
    data: contracts,
    isLoading: isLoadingContracts,
    isFetching,
  } = useMUIDataTableOptionsServerSide<ContractWithPermissionsAndTotals>({
    dataName: "contracts",
    defaultPaginationOptions: [10, 15, 50],
    filterInit: {
      base: {
        pageSize: 15,
        sort: contractFilter?.sort,
        sortBy: contractFilter?.sortBy,
      },
    },
    filterOptions: {
      selectableRows: "single",
      searchPlaceholder: `${t("Search for")} ${t(
        "Description",
      ).toLowerCase()}, ${t("Number").toLowerCase()}, ${t(
        "Status",
      ).toLowerCase()} ${t("or")} ${t("Customer name").toLowerCase()}...`,
      deleteFunction: (e: any): void | false => {
        const contract = contracts[e.data[0].dataIndex];
        if (!contract.permissions.deleteRights) {
          enqueueSnackbar(
            `${t("Could not delete - deletion rights are missing")}`,
            {
              variant: "error",
            },
          );
          return false;
        } else if (contract._id) {
          onSubmit({ data: { delete: contract._id } });
        }
      },
    },
    useGetDataQuery: useGetContractsQuery,
  });

  const structure = getStructure({
    extraProps: {
      contractTypes,
      currencyCode: company?.currencyCode,
      location: location as unknown as Location,
      users,
    },
    t,
  });

  let contractColumns: MUIDataTableColumnDef[] = structure.items.map((item) =>
    BuilderColumnOptionsSingle({
      data: contracts,
      disabled: true,
      item,
    }),
  );

  if (
    isLoadingCompany ||
    isLoadingContractType ||
    isLoadingContracts ||
    isLoadingUsers
  ) {
    return <LinearProgress />;
  }

  return (
    <MuiDataTableWrapper
      data={contracts || []}
      columns={contractColumns}
      isFetching={isFetching}
      options={options}
      tableType="main-with-filter"
      noBackground={true}
      fontSizeCells={"14px"}
    />
  );
};

export default ContractsTable;
