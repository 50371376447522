/**
 *Created by Mikael Lindahl on 2024-06-04
 */

import PizZipUtils from "pizzip/utils";
import ButtonPrimary from "../../Simple/Buttons/ButtonPrimary";
import { MicrosoftDriveService } from "../../../../context/MicrosoftDriveProvider/Lib/MicrosoftDriveService";
import { getProjectPath } from "../../../../context/MicrosoftDriveProvider/Utils/PathHelper";
import React, { useRef } from "react";
import useTranslation from "../../../../hooks/useTranslationWrapper";

type FileUploaderProps = {
  collectionName: "contract_offers" | "contracts";
  leafFolder?: string;
  invalidate: () => void;
  categories: { group: string; type: string };
  microsoftDriveClient: MicrosoftDriveService | null;
};

const ButtonMicrosoftDriveFileUpload = ({
  collectionName,
  categories,
  leafFolder,
  invalidate,
  microsoftDriveClient,
}: FileUploaderProps) => {
  const [t] = useTranslation();
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files?.item(0);
    if (!microsoftDriveClient || !leafFolder || !selectedFile) {
      return;
    }

    const projectPath = getProjectPath({
      collectionName,
      config: microsoftDriveClient.config,
      leafFolder,
    });

    microsoftDriveClient.getSaveLocation().then((savedLocation) => {
      const url = URL.createObjectURL(selectedFile);
      return new Promise((resolve, reject) => {
        PizZipUtils.getBinaryContent(
          url,
          (error: Error | null, content: string) => {
            if (error) {
              reject(error);
              return;
            }
            microsoftDriveClient
              .uploadFile(selectedFile.name, content, projectPath)
              .then((item) => {
                const metadata = {
                  description: encodeURIComponent(JSON.stringify(categories)),
                };
                microsoftDriveClient
                  .addMetadata({ id: item.id, metadata })
                  .then((data) => {
                    resolve(data);
                    invalidate();
                  })
                  .catch((e) => {
                    reject(e);
                  });
              })

              .catch((e) => {
                reject(e);
              });
          },
        );
      });
    });
  };

  const handleClick = () => {
    fileInputRef.current?.click();
  };

  return (
    <div>
      <ButtonPrimary onClick={handleClick}>{t("Add File")}</ButtonPrimary>
      <input
        type="file"
        ref={fileInputRef}
        style={{ display: "none" }}
        onChange={handleFileSelect}
      />
    </div>
  );
};

export default ButtonMicrosoftDriveFileUpload;
