import _c from "src/constants/Constants";
import { ReportTemplateYFilter } from "src/accurasee-backend-types/app/report_template_filter/report_template_filter.types";
import { Types } from "mongoose";
import { UserFormTableGetStructure } from "src/hooks/useFormTable";
import { YFilterIdTypeValues } from "src/accurasee-backend-types/app/report_template/report_template.types";
import getSelectOptions from "src/utils/getSelectOptions";
import { REPORT_TEMPLATE_CALCULATED } from "src/accurasee-backend-types/shared/utils/report.utils";

export interface GetStructureExtraProps {
  reportTemplateYFilters?: ReportTemplateYFilter[];
}
export interface ReportRowProps {
  filterType: YFilterIdTypeValues;
  filterId: Types.ObjectId;
}

export const getStructure: UserFormTableGetStructure<
  ReportRowProps,
  GetStructureExtraProps
> = ({ setFormData, extraProps, t }) => {
  return {
    items: [
      {
        type: "selector",
        dataName: "filterId",
        headerLabel: "Filter",
        options: ({ data, rowIndex }) => {
          const selectedfilterIds = data.map((i) => String(i.filterId));

          return getSelectOptions({
            data: [
              ...(extraProps?.reportTemplateYFilters?.map((filter) => ({
                label: filter.label,
                value: String(filter._id),
              })) || []),
              ...REPORT_TEMPLATE_CALCULATED.map(({ filterId, label }) => ({
                label: t(label),
                value: String(filterId),
              })),
            ]
              // filter to exclude selected options from other rows
              ?.filter(
                (e) =>
                  (e?.value && !selectedfilterIds.includes(e.value)) ||
                  e?.value === String(data[rowIndex]?.filterId),
              ),
            isSort: false,
          });
        },
        setFormDataCustom: (props) => {
          if (props.data) {
            let newData = [...props.data];
            const isCalculated = REPORT_TEMPLATE_CALCULATED.find(
              (i) => String(i.filterId) === props.value,
            );

            newData[props.rowIndex].filterId = props.value;
            newData[props.rowIndex].filterType = isCalculated
              ? "calculated"
              : "report_template_y_filter";

            setFormData(newData);
          }
        },
        sx: { minWidth: _c.TABLE_ITEMS_MIN_WIDTH.ITEM_DESCRIPTION },
      },
      {
        type: "text",
        dataName: "transactionType",
        headerLabel: "Type",
        getValue: ({ data, rowIndex }) => {
          let result = undefined;
          // If it is in REPORT_TEMPLATE_CALCULATED
          const isCalculated = REPORT_TEMPLATE_CALCULATED.find(
            (i) => String(i.filterId) === String(data[rowIndex].filterId),
          );
          if (isCalculated) {
            result = "calculated";
          } else {
            result = extraProps?.reportTemplateYFilters?.find(
              (filter) => filter._id === data[rowIndex].filterId,
            )?.transactionType;
          }
          return t(result);
        },
        sx: { textTransform: "capitalize" },
      },
      {
        type: "icon_button",
        iconType: "delete",
        dataName: "delete",
        headerLabel: "",
        onClick: ({ rowIndex, data }) => {
          let newFormData = [...data];
          newFormData.splice(rowIndex, 1);
          setFormData(newFormData);
        },
      },
    ],
  };
};

interface AddRowTProps {
  formData: ReportRowProps[];
  helperText: any[];
  setHelperText: (data: any) => void;
  setFormData: (data: ReportRowProps[]) => void;
}

export const addRow = (props: AddRowTProps) => {
  let row = { filterId: "", filterType: "empty" } as unknown as ReportRowProps;

  props.setHelperText([...props.helperText, {}]);
  props.setFormData([...props.formData, row]);
};
