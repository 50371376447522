import AdditionalInfo from "src/components/Complex/Containers/AdditionalInfo";
import BuildItems from "src/components/Builders/Container/BuildItems";
import BuilderColumnOptionsSingle from "src/components/Builders/Table/BuilderColumnOptionsSingle";
import ButtonBack from "../../Basic/Simple/Buttons/ButtonBack";
import ButtonGroup from "../../Basic/Simple/Buttons/ButtonGroup";
import ButtonPrimary from "../../Basic/Simple/Buttons/ButtonPrimary";
import ButtonSecondary from "../../Basic/Simple/Buttons/ButtonSecondary";
import InvoicesTotals from "src/components/Complex/Tables/InvoicesTotals";
import MuiDataTableWrapper from "src/components/Basic/Mixed/Tables/MuiDataTableWrapper";
import Page from "../../Basic/Mixed/Pages/Page";
import Section from "src/components/Basic/Simple/Sections/Section";
import SectionContainer from "../../Basic/Mixed/Sections/SectionContainer";
import SectionWithButtonContainer from "../../Basic/Mixed/Sections/SectionWithButtonContainer";
import useTranslation from "src/hooks/useTranslationWrapper";
import { AdditionalInfoData } from "src/components/Complex/Containers/AdditionalInfoGetStructure";
import { Article } from "src/accurasee-backend-types/app/article/article.types";
import { Box, Grid, Link } from "@mui/material";
import { CustomerInvoiceRow } from "src/accurasee-backend-types/app/customerinvoice/customerinvoice.types";
import { Link as RouterLink, useParams } from "react-router-dom";
import { MUIDataTableColumnDef } from "mui-datatables";
import { StructureItemContainer } from "../../Builders/Container/CommonBuilderContainerTypes";
import { StructureItemTable } from "src/components/Builders/Table/CommonBuilderTableTypes";
import { articleApi } from "src/redux/services/ArticleService";
import { escape } from "../../../utils/translate";
import { useEffect, useState } from "react";
import { useGetContractQuery } from "src/redux/services/ContractService";
import { useMUIDataTableOptions } from "src/hooks/useMUIDataTableOptions";
import { usePdfDownloader } from "src/general/Pdf";
import { useSnackbar } from "notistack";
import {
  getCustomerInvoicePdf,
  useGetCustomerInvoiceQuery,
  useBookPlannedInvoiceMutation,
} from "src/redux/services/CustomerInvoiceService";

const STRUCTURE: { items: StructureItemContainer[] } = {
  items: [
    {
      dataName: "invoiceDate",
      label: "Invoice date",
      type: "date",
      gridProps: { xs: 3 },
    },
    {
      dataName: "dueDate",
      label: "Due date",
      type: "date",
      gridProps: { xs: 3 },
    },
    {
      dataName: "customerName",
      label: "Customer",
      type: "text_input",
      gridProps: { xs: 3 },
    },
    {
      dataName: "contractName",
      label: "Contract",
      type: "text_input",
      gridProps: { xs: 3 },
    },
    {
      dataName: "files",
      filesAllowed: ["application/pdf"],
      gridProps: { lg: 6 },
      label: "Attachment",
      type: "auto_file",
    },
  ],
};

export const CustomerInvoiceBase = () => {
  const { contractId, id } = useParams<{ contractId: string; id: string }>();
  const { data: contract, isLoading: isLoadingContract } = useGetContractQuery(
    contractId,
    { skip: !contractId },
  );

  const [t] = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [bookPlannedInvoice] = useBookPlannedInvoiceMutation();
  const { data: customerInvoice, isLoading } = useGetCustomerInvoiceQuery(id, {
    skip: !id,
  });

  const [book, setBook] = useState(customerInvoice?.booked);
  useEffect(() => {
    setBook(customerInvoice?.booked);
  }, [customerInvoice]);

  const { data: articlesWithMetaData, isLoading: isLoadingArticles } =
    articleApi.useGetArticlesQuery(undefined);
  const articles = articlesWithMetaData?.data || [];
  const { downloadPdf, isFetchingPdf } = usePdfDownloader(
    () => id && getCustomerInvoicePdf(id),
    `kundfaktura-${customerInvoice?.invoiceNumber}.pdf`,
  );

  const onBook = async () => {
    const res: any = id && (await bookPlannedInvoice({ id }));
    if (!res?.error) {
      enqueueSnackbar("Invoice is booked successfully", {
        variant: "success",
      });
      setBook(true);
    } else {
      const msg = res?.error?.data?.error?.message?.message;
      enqueueSnackbar(msg ?? "Could not book invoice", {
        variant: "error",
      });
    }
  };

  const commonPropsBuildItem = {
    data: customerInvoice,
    helperText: {},
    disabled: true,
    id,
    vatTypeTooltip: {
      color: undefined,
      message: "",
    },
  };

  const customerInvoiceRowTableStructure: {
    items: StructureItemTable<CustomerInvoiceRow>[];
  } = {
    items: [
      {
        dataName: "invoiceNumber",
        getValue: ({ rowIndex }) => rowIndex + 1,
        headerLabel: "#",
        type: "text",
      },
      {
        dataName: "articleId",
        getValue: ({ data, rowIndex }) => {
          const value = data[rowIndex]?.articleId;
          if (articles?.length === 0) return value ?? "-";
          const foundArticle = articles?.find(
            (article: Article) => article._id === value,
          );
          return foundArticle?.name ?? "-";
        },
        headerLabel: "Article",
        type: "text",
      },
      {
        dataName: "itemDescription",
        headerLabel: "Description",
        type: "text",
      },
      {
        alignColumnContent: "end",
        dataName: "quantity",
        headerLabel: "Quantity",
        type: "number",
      },
      {
        alignColumnContent: "end",
        currencyCode: customerInvoice?.currencyCode,
        dataName: "pricePerUnit",
        headerLabel: "Price per unit",
        type: "text_currency",
      },
      {
        alignColumnContent: "end",
        dataName: "discount",
        headerLabel: "Discount",
        type: "number",
      },
      {
        alignColumnContent: "end",
        currencyCode: customerInvoice?.currencyCode,
        dataName: "totalExcludingVAT",
        headerLabel: "Subtotal",
        type: "text_currency",
      },
      {
        alignColumnContent: "end",
        dataName: "percentVAT",
        getValue: ({ data, rowIndex }) => {
          const value = data[rowIndex]?.percentVAT;
          return isNaN(value) ? "" : `${value * 100}%`;
        },
        headerLabel: "VAT",
        type: "text",
      },
      {
        alignColumnContent: "end",
        currencyCode: customerInvoice?.currencyCode,
        dataName: "total",
        headerLabel: "Total",
        type: "text_currency",
      },
    ],
  };

  let customerInvoiceRowColumns: MUIDataTableColumnDef[] =
    customerInvoiceRowTableStructure.items.map((item) =>
      BuilderColumnOptionsSingle({
        data: customerInvoice?.invoiceRows || [],
        disabled: true,
        item,
      }),
    );

  const { options } = useMUIDataTableOptions({
    pagination: customerInvoice && customerInvoice?.invoiceRows?.length >= 10,
  });

  const breadcrumbs = contractId
    ? [
        { label: "Contracts", link: "/app/contracts" },
        {
          label: `Contract${escape(` ${contract?.projectExternalId || ""}`)}`,
          link: `/app/contracts/${contractId}`,
        },
        {
          label: "Invoices",
          link: `/app/contracts/${contractId}/invoices/planned`,
        },
        {
          label: `Customer invoice${escape(
            ` ${customerInvoice?.invoiceNumber || ""}`,
          )}`,
        },
      ]
    : [
        { label: "Operations" },
        { label: "Invoices", link: "/app/operations/invoices/planned" },
        {
          label: `Customer invoice${escape(
            ` ${customerInvoice?.invoiceNumber || ""}`,
          )}`,
        },
      ];

  return (
    <Page
      isLoading={isLoading || isLoadingArticles || isLoadingContract}
      label={"Edit customer invoice"}
      breadcrumbs={breadcrumbs}
    >
      <SectionContainer>
        {customerInvoice && (
          <Section
            rightBoxArea={
              <ButtonGroup>
                {customerInvoice.exportedFromPlannedInvoiceId && (
                  <Link
                    component={RouterLink}
                    to={`/app/contracts/${customerInvoice.contractId}/invoices/planned/${customerInvoice.exportedFromPlannedInvoiceId}`}
                  >
                    Connected planned invoice
                  </Link>
                )}
                <ButtonSecondary
                  isLoading={isFetchingPdf}
                  onClick={downloadPdf}
                >
                  {t("Download PDF")}
                </ButtonSecondary>
                <ButtonPrimary
                  disabled={book}
                  onClick={onBook}
                  permissionType={"exportRights"}
                  permissions={customerInvoice?.permissions}
                >
                  {book ? "Booked" : "Book"}
                </ButtonPrimary>
              </ButtonGroup>
            }
            label={`${t("CUSTOMER INVOICE")}${escape(
              ` #${customerInvoice.invoiceNumber}`,
            )}`}
          >
            <Grid container spacing={1} sx={{ padding: 2 }}>
              {BuildItems({
                items: STRUCTURE.items,
                ...commonPropsBuildItem,
              })}
            </Grid>
          </Section>
        )}
        <AdditionalInfo
          invoicePlanInit={
            {
              remarks: customerInvoice?.remarks,
              _id: customerInvoice?._id,
            } as AdditionalInfoData
          }
          type="invoice"
          useExpand
        />
        {customerInvoice && (
          <Section>
            <MuiDataTableWrapper
              lessPadding={true}
              data={customerInvoice.invoiceRows || []}
              columns={customerInvoiceRowColumns}
              options={options}
              title=""
            />

            <Box sx={{ padding: 2, marginLeft: "auto", width: "fit-content" }}>
              <InvoicesTotals invoicePlan={customerInvoice} />
            </Box>
          </Section>
        )}
      </SectionContainer>
      <SectionWithButtonContainer buttonLeft>
        <ButtonBack />
      </SectionWithButtonContainer>
    </Page>
  );
};

export default CustomerInvoiceBase;
