/**
 *Created by Mikael Lindahl on 2023-02-21
 */

import ActionRowColumnItem from "./ColumnItems/ActionRowColumnItem";
import AutocompleteColumnItem from "./ColumnItems/AutocompleteColumnItem";
import BadgeColumnItem from "./ColumnItems/BadgeColumnItem";
import ButtonColumnItem from "./ColumnItems/ButtonColumnItem";
import DateInputColumnItem from "./ColumnItems/DateInputColumnItem";
import IconColumnItem from "./ColumnItems/IconColumnItem";
import LinkColumnItem from "./ColumnItems/LinkColumnItem";
import SelectorColumnItem from "./ColumnItems/SelectorColumnItem";
import SwitchColumnItem from "./ColumnItems/SwitchColumnItem";
import TextColumnItem from "./ColumnItems/TextColumnItem";
import TextInputColumnItem from "./ColumnItems/TextInputColumnItem";
import TextWithEditButtonColumnItem from "./ColumnItems/TextWithEditButtonColumnItem";
import TextWithLabelColumnItem from "./ColumnItems/TextWithLabelColumnItem";
import createStyles from "@mui/styles/createStyles";
import getColumnOptions from "./getColumnOptions";
import makeStyles from "@mui/styles/makeStyles";
import parseNumberInput from "../../../utils/parseNumberInput";
import toDate from "src/utils/dateslash";
import { MUIDataTableColumnDef } from "mui-datatables";
import { TFunction } from "src/hooks/useTranslationWrapper";
import {
  CommonTypes,
  HelperTextArray,
  StructureItemTable,
} from "./CommonBuilderTableTypes";
import formatValueDisplayedCurrency from "../Utils/formatValueDisplayedCurrency";

export type BuilderColumnOptionsBaseTProps<DataType = any> = {
  data: DataType[];
  disabled?: boolean;
  helperText?: HelperTextArray;
  item: StructureItemTable<DataType>;
  t: TFunction;
};

const useStyles = makeStyles((theme) =>
  createStyles({
    startHeader: {
      "& button": {
        display: "flex",
        justifyContent: "left",
        alignItems: "left",
        width: "100%",
        margin: "auto",
        paddingLeft: "0px",
        paddingRight: "0px",
      },
    },
    endHeader: {
      "& button": {
        display: "flex",
        justifyContent: "right",
        alignItems: "right",
        width: "100%",
        margin: "auto",
        paddingLeft: "0px",
        paddingRight: "0px",
      },
    },
    centerHeader: {
      "& button": {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        margin: "auto",
        paddingLeft: "0px",
        paddingRight: "0px",
      },
    },
  }),
);

const BuilderColumnOptionsBase: <DataType = any>(
  props: BuilderColumnOptionsBaseTProps<DataType>,
) => MUIDataTableColumnDef = (props) => {
  let options = {
    customFilterListOptions: props?.item?.customFilterListOptions || undefined,
    filter: props.item?.filter || false,
    filterType: props?.item?.filterType || undefined,
    filterList: props?.item?.filterList || undefined,
    filterOptions: props?.item?.filterOptions || undefined,
    sort: props.item?.sort || false,
    viewColumns: !!props.item.headerLabel,
    display:
      props.item.showColumnWhen === undefined
        ? true
        : typeof props.item.showColumnWhen === "function"
          ? props.item.showColumnWhen({ data: props.data, item: props.item })
          : props.item.showColumnWhen,
  };

  const classes = useStyles();

  const commonProps: CommonTypes = {
    data: props.data,
    disabled: props.disabled,
    helperText: props.helperText,
    item: props.item,
    t: props.t,
  };

  let alignColumnContent = props.item.alignColumnContent;

  switch (props.item.type) {
    case "action_row":
      options = {
        ...options,
        ...getColumnOptions({
          ColumnItem: ActionRowColumnItem,
          ...commonProps,
        }),
      };
      break;
    case "autocomplete":
      options = {
        ...options,
        ...getColumnOptions({
          ColumnItem: AutocompleteColumnItem,
          ...commonProps,
        }),
      };
      break;
    case "badge":
      options = {
        ...options,
        ...getColumnOptions({
          ColumnItem: BadgeColumnItem,
          ...commonProps,
        }),
      };
      break;
    case "button":
    case "button_primary":
    case "button_secondary":
      options = {
        ...options,
        ...getColumnOptions({
          ColumnItem: ButtonColumnItem,
          ...commonProps,
          buttonType: props.item.type,
        }),
      };
      break;
    case "date":
      options = {
        ...options,
        ...getColumnOptions({
          ColumnItem: TextColumnItem,
          ...commonProps,
          format: toDate,
        }),
      };
      break;
    case "date_input":
      options = {
        ...options,
        ...getColumnOptions({
          ColumnItem: DateInputColumnItem,
          ...commonProps,
        }),
      };
      break;
    case "link":
      options = {
        ...options,
        ...getColumnOptions({
          ColumnItem: LinkColumnItem,
          ...commonProps,
        }),
      };
      break;
    case "icon":
      options = {
        ...options,
        ...getColumnOptions({
          ColumnItem: IconColumnItem,
          ...commonProps,
        }),
      };
      break;
    case "icon_button":
      options = {
        ...options,
        ...getColumnOptions({
          ColumnItem: IconColumnItem,
          ...commonProps,
          isClickable: true,
        }),
      };
      break;
    case "number":
      options = {
        ...options,
        ...getColumnOptions({
          ColumnItem: TextColumnItem,
          ...commonProps,
          justifyContent: "flex-end",
        }),
      };
      break;
    case "number_input":
      options = {
        ...options,
        ...getColumnOptions({
          ColumnItem: TextInputColumnItem,
          ...commonProps,
          parse: (value) => parseNumberInput(value),
        }),
      };
      break;
    case "number_input_currency":
      options = {
        ...options,
        ...getColumnOptions({
          ColumnItem: TextInputColumnItem,
          ...commonProps,
          formatValueDisplayed: formatValueDisplayedCurrency,
          isCurrency: true,
          parse: (value) => parseNumberInput(value),
        }),
      };
      break;
    case "percent":
      options = {
        ...options,
        ...getColumnOptions({
          ColumnItem: TextColumnItem,
          ...commonProps,
          format: (val) => `${val} %`,
          justifyContent: alignColumnContent,
        }),
      };
      break;
    case "percent_input":
      options = {
        ...options,
        ...getColumnOptions({
          ColumnItem: TextInputColumnItem,
          ...commonProps,
          parse: (value) =>
            isNaN(value) || value === "" ? value : Number(value),
          style: { width: "90px" },
          unit: "%",
        }),
      };
      break;
    case "selector":
      options = {
        ...options,
        ...getColumnOptions({
          ColumnItem: SelectorColumnItem,
          ...commonProps,
        }),
      };
      break;
    case "switch":
      options = {
        ...options,
        ...getColumnOptions({
          ColumnItem: SwitchColumnItem,
          ...commonProps,
        }),
      };
      break;
    case "text":
      alignColumnContent = alignColumnContent || "start";
      options = {
        ...options,
        ...getColumnOptions({
          ColumnItem: TextColumnItem,
          ...commonProps,
          justifyContent: alignColumnContent,
        }),
      };
      break;
    case "text_currency":
      alignColumnContent = alignColumnContent || "end";
      options = {
        ...options,
        ...getColumnOptions({
          ColumnItem: TextColumnItem,
          ...commonProps,
          isCurrency: true,
          justifyContent: alignColumnContent,
        }),
      };
      break;
    case "text_currency_integer":
      alignColumnContent = alignColumnContent || "end";
      options = {
        ...options,
        ...getColumnOptions({
          ColumnItem: TextColumnItem,
          ...commonProps,
          parse: (value) => Math.round(value),
          isCurrency: true,
          justifyContent: alignColumnContent,
        }),
      };
      break;
    case "text_input":
      options = {
        ...options,
        ...getColumnOptions({
          ColumnItem: TextInputColumnItem,
          ...commonProps,
        }),
      };
      break;
    case "text_with_edit_button":
      options = {
        ...options,
        ...getColumnOptions({
          ColumnItem: TextWithEditButtonColumnItem,
          ...commonProps,
        }),
      };
      break;
    case "text_with_optional_badge":
      options = {
        ...options,
        ...getColumnOptions({
          ColumnItem: TextColumnItem,
          ...commonProps,
          justifyContent: alignColumnContent || "start",
          isTextWithOptionalBadge: true,
        }),
      };
      break;
    case "text_with_prefix_bullet":
      options = {
        ...options,
        ...getColumnOptions({
          ColumnItem: TextColumnItem,
          ...commonProps,
          justifyContent: alignColumnContent || "start",
          isTextWithPrefixBullet: true,
        }),
      };
      break;
    case "text_with_prefix_icon":
      options = {
        ...options,
        ...getColumnOptions({
          ColumnItem: TextColumnItem,
          ...commonProps,
          justifyContent: alignColumnContent || "start",
          isTextWithPrefixIcon: true,
        }),
      };
      break;
    case "text_with_label":
      options = {
        ...options,
        ...getColumnOptions({
          ColumnItem: TextWithLabelColumnItem,
          ...commonProps,
        }),
      };
      break;
    case "url_input":
      options = {
        ...options,
        ...getColumnOptions({
          ColumnItem: TextInputColumnItem,
          ...commonProps,
        }),
      };
      break;
  }

  return {
    name: props.item.dataName as string,
    label: props.item.headerLabel ? props.t(props.item.headerLabel) : " ",
    options: {
      setCellHeaderProps: () => ({
        style: {
          textAlign: alignColumnContent || "start",
        },
        // when header (button) is sortable
        className:
          alignColumnContent === "end"
            ? classes.endHeader
            : alignColumnContent === "center"
              ? classes.centerHeader
              : classes.startHeader,
      }),
      setCellProps: () => ({
        style: {
          // paddingLeft: "2px",
          textAlign: alignColumnContent || "start",
          whiteSpace: props.item?.whiteSpace || "normal",
        },
      }),
      ...options,
    },
  };
};

export default BuilderColumnOptionsBase;
