import Tooltip from "@mui/material/Tooltip";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import React from "react";

type UseMUIDatTableOptions = {
  additionalFilter: any;
  setAdditionalFilter: React.Dispatch<React.SetStateAction<any>> | undefined;
  ownButtonLabel?: string;
};

const FilterOwnContractsButtonTooltip = (props: UseMUIDatTableOptions) => {
  const [t] = useTranslation();
  const sortingOwnContracts = Boolean(props?.additionalFilter?.ownerIds);

  const userState: any = useSelector((data) => data);
  const userId = userState.user.user._id;

  return (
    <>
      <Tooltip title={"Sort own or all contracts"}>
        <Button
          color="primary"
          variant="outlined"
          disableRipple
          onClick={() => {
            const additionalFilter = {
              ...props?.additionalFilter,
              ownerIds: sortingOwnContracts ? null : userId,
            };
            if (props.setAdditionalFilter) {
              props.setAdditionalFilter(additionalFilter);
            }
          }}
        >
          {t(
            sortingOwnContracts
              ? `All ${props.ownButtonLabel ?? "contracts"}`
              : `Own ${props.ownButtonLabel ?? "contracts"}`,
          )}
        </Button>
      </Tooltip>
    </>
  );
};

export default FilterOwnContractsButtonTooltip;
