import ErrorPage from "../ErrorPage/ErrorPage";

const NotFoundBase = ({
  redirect,
  redirectLabel,
  title,
}: {
  redirect?: string;
  redirectLabel?: string;
  title?: string;
}) => {
  return (
    <ErrorPage
      title={`${title || "Pages"} not found`}
      statusCode={404}
      redirect={redirect}
      redirectLabel={redirectLabel || "Go to Dashboard"}
    />
  );
};
export default NotFoundBase;
