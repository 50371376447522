import { backendApi } from "../api";
import {
  docDeleteTags,
  docGetTag,
  docListTags,
  docMultipleListTag,
  docPatchTagNew,
} from "./utils";
import {
  ContractType,
  ContractTypeWithPermissions,
  ContractTypeWithTotals,
} from "src/accurasee-backend-types/app/contracttype/contracttype.types";

const CACHE_TYPE = "ContractTypes";
const contractTypesApi = backendApi.injectEndpoints({
  endpoints: (build) => ({
    getContractTypes: build.query<
      { data: ContractTypeWithPermissions[]; metadata: any },
      any
    >({
      query: (params) => ({ url: "/contracttypes", params }),
      providesTags: docMultipleListTag(CACHE_TYPE),
    }),
    getContractTypesTable: build.query<
      { data: ContractTypeWithTotals[]; metadata: any },
      any
    >({
      query: (params) => ({ url: "/contracttypes/table", params }),
      providesTags: docListTags(CACHE_TYPE),
    }),
    getContractType: build.query<ContractTypeWithPermissions, any>({
      query: (id) => ({ url: `/contracttypes/${id}` }),
      transformResponse: (response) => response.data,
      providesTags: docGetTag(CACHE_TYPE),
    }),
    createContractType: build.mutation<any, Partial<ContractType>>({
      query: (data) => ({ url: "/contracttypes", method: "POST", data }),
      invalidatesTags: docMultipleListTag(CACHE_TYPE, "Company"),
    }),
    patchContractType: build.mutation<any, ContractType & { id: string }>({
      query: ({ id, ...data }) => ({
        url: `/contracttypes/${id}`,
        method: "PATCH",
        data,
      }),
      invalidatesTags: docPatchTagNew(CACHE_TYPE), // Patch could change resulting pagination queries
    }),
    deleteContractType: build.mutation({
      query: (id) => ({ url: `/contracttypes/${id}`, method: "DELETE" }),
      invalidatesTags: docDeleteTags(CACHE_TYPE),
    }),
    getContractTypeCosts: build.query({
      query: (params) => ({ url: "/costs", params }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useCreateContractTypeMutation,
  useDeleteContractTypeMutation,
  useGetContractTypeQuery,
  useGetContractTypesQuery,
  useGetContractTypesTableQuery,
  usePatchContractTypeMutation,
} = contractTypesApi;

export default contractTypesApi;
