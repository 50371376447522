import { backendApi } from "../api";
import _ from "lodash";
import {
  docDeleteTags,
  docListTags,
  docPatchTag,
  docMultipleListTag,
  listTag,
  listTagMultiple,
} from "./utils";
import {
  Contract,
  ContractWithPermissionsAndTotals,
  GetSingleContractWithPermissions,
} from "src/accurasee-backend-types/app/contracts/contract.types";
import { IncomeWithPermissions } from "src/accurasee-backend-types/app/income/income.types";
import _c from "src/constants/Constants";
import { ContractArticle } from "src/accurasee-backend-types/app/contracttype/contracttype.types";

const CACHE_TYPE = "Contracts";
const CACHE_INCOME_TYPES = "Incomes";
const contractsApi = backendApi.injectEndpoints({
  endpoints: (build) => ({
    getContracts: build.query<
      { data: ContractWithPermissionsAndTotals[]; metadata: any },
      any
    >({
      query: (params) => ({ url: "/contracts", params }),
      providesTags: docListTags(CACHE_TYPE),
    }),
    getPocData: build.query<
      { data: ContractWithPermissionsAndTotals[]; metadata: any },
      any
    >({
      query: (params) => ({ url: "/contracts/pocdata", params }),
      providesTags: docListTags(CACHE_TYPE),
    }),
    getContract: build.query<GetSingleContractWithPermissions, any>({
      query: (id) => ({ url: `/contracts/${id}` }),
      transformResponse: (response) => response.data,
      providesTags: [CACHE_TYPE],
    }),
    getContractNumbers: build.query<number[], any>({
      query: () => ({ url: `/contracts/numbers` }),
      transformResponse: (response) => response.data,
      providesTags: [CACHE_TYPE],
    }),
    createContract: build.mutation<any, Contract>({
      query: (data) => {
        const modifiedContractArticles = data.contractArticles?.map(
          ({ _id, ...item }) => item,
        );
        data.contractArticles = modifiedContractArticles;
        return { url: "/contracts", method: "POST", data };
      },
      invalidatesTags: listTagMultiple(
        CACHE_TYPE,
        "Projects",
        "ContractOffers",
      ),
    }),
    patchContract: build.mutation<
      any,
      Partial<Contract> & { id: Contract["_id"] }
    >({
      query: ({ id, ...data }) => {
        // TODO: Should this be taken care of in the backend perhaps?
        const modifiedContractArticles = data.contractArticles?.map((value) =>
          _.pick(value, _c.ALLOWED_CONTRACT_ARTICLE_KEYS),
        );
        data.contractArticles = modifiedContractArticles as ContractArticle[];
        return { url: `/contracts/${id}`, method: "PATCH", data };
      },
      invalidatesTags: docMultipleListTag(
        CACHE_TYPE,
        "PlannedInvoices",
        "Invoiceplans",
      ),
    }),
    patchContracts: build.mutation<any, Partial<Contract>>({
      query: (data) => {
        return { url: "/contracts/bulk", method: "PATCH", data };
      },
      invalidatesTags: docPatchTag(CACHE_TYPE),
    }),
    patchPocContracts: build.mutation({
      query: (data) => ({
        url: "/contracts/pocdata",
        method: "PUT",
        data,
      }),
      invalidatesTags: listTag(CACHE_TYPE),
    }),
    contractArticles: build.mutation<
      any,
      Partial<Contract> & { id: String; priceQuantityUpdateDate: String }
    >({
      query: ({ id, ...data }) => {
        const modifiedContractArticles = data.contractArticles?.map((value) =>
          _.pick(value, [
            "articleId",
            "defaultArticle",
            "isIndex",
            "isPricePerUnitEstimate",
            "isQuantityEstimate",
            "price",
            "priceMin",
            "quantity",
            "quantityMin",
            "quantityMinValue",
          ]),
        );
        data.contractArticles = modifiedContractArticles;
        return {
          url: `/contracts/${id}/contractArticles`,
          method: "PATCH",
          data,
        };
      },
      invalidatesTags: docMultipleListTag(
        CACHE_TYPE,
        "PlannedInvoices",
        "Invoiceplans",
      ),
    }),
    deleteContract: build.mutation<any, string>({
      query: (id) => ({ url: `/contracts/${id}`, method: "DELETE" }),
      invalidatesTags: docDeleteTags(CACHE_TYPE),
    }),
    getContractIncomes: build.query<
      { data: IncomeWithPermissions[]; metadata: any },
      any
    >({
      query: (params) => ({ url: "/incomes", params }),
      providesTags: docListTags(CACHE_INCOME_TYPES),
    }),
    getNumberOfContracts: build.query<any, any>({
      query: (params) => ({
        url: "/contracts/number-of-active-contracts",
        params,
      }),
      transformResponse: (response) => response.data,
      providesTags: listTag(CACHE_TYPE),
    }),
  }),
  overrideExisting: false,
});

export const {
  useContractArticlesMutation,
  useCreateContractMutation,
  useDeleteContractMutation,
  useGetContractIncomesQuery,
  useGetContractNumbersQuery,
  useGetContractQuery,
  useGetContractsQuery,
  useGetNumberOfContractsQuery,
  useGetPocDataQuery,
  usePatchContractMutation,
  usePatchContractsMutation,
  usePatchPocContractsMutation,
} = contractsApi;

export default contractsApi;
