/**
 *Created by Mikael Lindahl on 2023-02-22
 */

import TextField from "@mui/material/TextField";
import _c from "src/constants/Constants";
import determineCurrencyStyling from "../../Utils/determineCurrencyStyling";
import get from "../Utils/get";
import getDisabled from "../Utils/getDisabled";
import getHelperText from "../Utils/getHelperText";
import getRequired from "../Utils/getRequired";
import getShowCellWhen from "../Utils/getShowCellWhen";
import getValue from "../Utils/getValue";
import parseValue from "../Utils/parseValue";
import setValue from "../Utils/setValue";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { CommonBuilderTableTypes } from "../CommonBuilderTableTypes";
import { InputAdornment } from "@mui/material";
import { Subject } from "rxjs";
import { debounceTime } from "rxjs/operators";

type TextColumnItemTProps = CommonBuilderTableTypes;

const TextInputColumnItem = (props: TextColumnItemTProps) => {
  const t = props.t;
  const commonCallbackProps = {
    item: props.item,
    data: props.data,
    rowIndex: props.rowIndex,
  };

  const getHelperTextProps = {
    item: props.item,
    helperText: props.helperText,
    rowIndex: props.rowIndex,
  };

  const value = getValue(commonCallbackProps);
  const [inputValue, setInputValue] = useState(
    value || (value === 0 ? value : ""),
  );

  const propsRef = useRef(props);
  propsRef.current = props;

  useEffect(() => {
    setInputValue(value || (value === 0 ? value : "")); // Update state when prop changes
  }, [props]);

  const setData = (value: any) => {
    let changeProps = {
      ...commonCallbackProps,
      data: propsRef.current.data,
      rowIndex: propsRef.current.rowIndex,
      value: parseValue({ value, parse: props.parse }),
    };
    if (props.item.setFormDataCustom) {
      props.item.setFormDataCustom(changeProps);
    } else {
      setValue({ ...changeProps });
    }
  };

  const inputSubjectRef = useRef(new Subject());

  useEffect(() => {
    const subscription = inputSubjectRef.current
      .pipe(
        debounceTime(_c.DEBOUNCE.TEXT_INPUT), // Debounce for 1 second
      )
      .subscribe((value) => {
        setData(value); // Call setData after 1 second of no input
      });

    return () => {
      subscription.unsubscribe(); // Cleanup subscription
      inputSubjectRef.current.complete(); // Complete the subject to clean up
    };
  }, []);

  const handleChange = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    const valueToSet = props?.formatValueDisplayed
      ? props?.formatValueDisplayed.reversed(event.target.value, currencyCode)
      : event.target.value;

    setInputValue(valueToSet);
    inputSubjectRef.current.next(valueToSet); // Emit value to the subject
  };

  const itemDisabled = props.disabled || getDisabled(commonCallbackProps);

  let extra = {};
  const unit = props.item.unit || props.unit;
  if (props.item.unit || props.unit) {
    extra = {
      ...extra,
      InputProps: {
        endAdornment: (
          <InputAdornment position="end">{unit ? t(unit) : ""}</InputAdornment>
        ),
      },
    };
  }

  const currencyCode = get("currencyCode", commonCallbackProps);

  if (props.item.currencyCode && props.isCurrency) {
    const { symbol, position, adornment } = determineCurrencyStyling(
      get("currencyCode", commonCallbackProps)!,
    );

    extra = {
      ...extra,
      InputProps: {
        [adornment]: (
          <InputAdornment position={position}>{symbol}</InputAdornment>
        ),
      },
    };
  }

  const inputRef = useRef<HTMLInputElement>();

  const handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter" && inputRef.current) {
      event.preventDefault(); // Prevents the default action
      inputRef.current.blur(); // Triggers blur event
    }
  };

  return getShowCellWhen(commonCallbackProps) ? (
    <TextField
      color={get("textInputColor", commonCallbackProps)} //>props.highlighted ? "success" : "primary"}
      disabled={itemDisabled}
      error={Boolean(getHelperText(getHelperTextProps))}
      focused={get("textInputFocused", commonCallbackProps)}
      helperText={getHelperText(getHelperTextProps)}
      hiddenLabel
      inputRef={inputRef}
      key={`${String(props.item.dataName)}-${props.rowIndex}`}
      label=""
      onChange={handleChange}
      onKeyDown={handleKeyPress}
      required={getRequired(commonCallbackProps)}
      size="small"
      style={props.style}
      sx={get("sx", commonCallbackProps)}
      value={
        props?.formatValueDisplayed
          ? props?.formatValueDisplayed.forward(inputValue, currencyCode)
          : inputValue
      }
      variant="outlined"
      {...extra}
    />
  ) : (
    <></>
  );
};

export default TextInputColumnItem;
