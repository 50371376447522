/**
 *Created by Julius Abelson on 2023-02-24
 */

import { Box, Button, IconButton, Tooltip } from "@mui/material";
import { CommonBuilderTableTypes } from "../CommonBuilderTableTypes";
import { useNavigate } from "react-router-dom";
import get from "../Utils/get";
import getLink from "../Utils/getLink";
import getShowCellWhen from "../Utils/getShowCellWhen";
import getValue from "../Utils/getValue";
import LaunchIcon from "@mui/icons-material/Launch";

type LinkColumnItemTProps = CommonBuilderTableTypes;

/**
 * Use this column item to link things
 * Add redirectLink on item to redirect to that link, {_id}, {contractId} etc
 * will be replaced by the property on the data object
 * @param props
 * @returns Link object
 */
const LinkColumnItem = (props: LinkColumnItemTProps) => {
  let navigate = useNavigate();

  const commonCallbackProps = {
    item: props.item,
    data: props.data,
    rowIndex: props.rowIndex,
  };

  const linkPath = getLink(commonCallbackProps);

  return getShowCellWhen(commonCallbackProps) ? (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        ...get("sx", commonCallbackProps),
      }}
    >
      <Tooltip title={props.data[props.rowIndex][props.item.dataName]} arrow>
        <Button
          onClick={(e: any) => {
            e.stopPropagation();
            if (props.item.onClick) {
              props.item.onClick(commonCallbackProps);
            } else {
              linkPath && navigate(linkPath);
            }
          }}
          sx={{
            textDecoration: "underline",
            color: "inherit",
            background: "none",
            border: "none",
            padding: 0,
            font: "inherit",
            cursor: "pointer",
            whiteSpace: "nowrap", // Keeps the text on a single line
            textTransform: "none", // Ensures text is always uppercase
            "&:hover": {
              textDecoration: "underline", // Ensures underline is not removed on hover
            },
          }}
        >
          {getValue({
            data: props.data,
            item: props.item,
            rowIndex: props.rowIndex,
          })}
        </Button>
      </Tooltip>
      <IconButton
        sx={{ color: "inherit" }}
        onClick={() => {
          if (props.item.onClick) {
            props.item.onClick(commonCallbackProps);
          } else {
            return linkPath && navigate(linkPath);
          }
        }}
      >
        {<LaunchIcon />}
      </IconButton>
    </Box>
  ) : (
    <></>
  );
};

export default LinkColumnItem;
