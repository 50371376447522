import {
  SubcontractWithCostAndIncomes,
  SubcontractWithPermissions,
} from "src/accurasee-backend-types/app/subcontracts/subcontract.types";
import { Box, CircularProgress } from "@mui/material";
import { CostWithPermissions } from "src/accurasee-backend-types/app/cost/cost.types";
import { escape } from "src/utils/translate";
import { MUIDataTableColumnDef } from "mui-datatables";
import { ReturnUser } from "src/accurasee-backend-types/app/user/user.types";
import { useGetArticlesQuery } from "src/redux/services/ArticleService";
import { useGetContractQuery } from "src/redux/services/ContractService";
import { useGetPlannedInvoicesQuery } from "src/redux/services/PlannedinvoiceService";
import { useGetTermsOfPaymentsQuery } from "src/redux/services/TermsofpaymentService";
import { useLocation, useParams } from "react-router-dom";
import { useMUIDatTableOptions } from "src/hooks/useMUIDataTableOptions";
import { useSelector } from "react-redux";
import { useState } from "react";
import BuilderColumnOptionsSingle from "src/components/Builders/Table/BuilderColumnOptionsSingle";
import ButtonGroup from "src/components/Basic/Simple/Buttons/ButtonGroup";
import ButtonPrimary from "src/components/Basic/Simple/Buttons/ButtonPrimary";
import CostsSubtable from "../Costs/components/CostSubtable";
import getStructure, {
  GetStructureExtraProps,
} from "./SubcontractsTableStructure";
import InvoiceRowsSubtable from "src/components/Complex/Tables/InvoiceRowsSubtable";
import MuiDataTableWrapper from "src/components/Basic/Mixed/Tables/MuiDataTableWrapper";
import Page from "src/components/Basic/Mixed/Pages/Page";
import subcontractApi from "src/redux/services/SubcontractService";
import SubTableContainer from "src/components/Basic/Mixed/Tables/SubTableContainer";
import useExportSubContracts from "src/hooks/useExportSubContracts";
import useFormTable from "src/hooks/useFormTable";
import useMarkSubcontractsAsInvoiced from "src/hooks/useMarkSubcontractsAsInvoiced";
import useTranslation from "src/hooks/useTranslationWrapper";

const SubcontractsTable = () => {
  let { contractId } = useParams<{ contractId: string }>();

  const { data: contract, isLoading: isLoadingContract } =
    useGetContractQuery(contractId);
  const [t] = useTranslation();
  const location = useLocation();

  const { user } = useSelector(
    (state: { user: { user: ReturnUser } }) => state.user,
  );

  const [selectedRows, setSelectedRows] = useState<
    SubcontractWithPermissions[]
  >([]);
  const [selectedRowsIndex, setSelectedRowsIndex] = useState<number[]>([]);

  // to get list of subcontract by contractId
  const { data: subcontractsQueryResult, isLoading } =
    subcontractApi.useGetSubcontractsTableQuery({
      contractId,
      sortBy: "number",
      sort: "desc",
    });
  const subcontracts = subcontractsQueryResult?.data || [];
  const count = subcontractsQueryResult?.metadata.count || 0;

  const {
    data: plannedInvoicesQueryResult,
    isLoading: isLoadingPlannedInvoices,
  } = useGetPlannedInvoicesQuery({ contractId });
  const plannedInvoices = plannedInvoicesQueryResult?.data || [];

  const {
    data: termsOfPaymentsQueryResult,
    isLoading: isLoadingTermsOfPayments,
  } = useGetTermsOfPaymentsQuery(undefined);
  const termsOfPayments = termsOfPaymentsQueryResult || [];

  const { isExportSubContractsLoading, onExportSubContracts } =
    useExportSubContracts({
      selectedSubcontracts: selectedRows,
      plannedInvoices,
      termsOfPayments,
    });

  const { isMarkSubcontractsAsInvoicedLoading, onMarkSubcontractsAsInvoiced } =
    useMarkSubcontractsAsInvoiced({
      selectedSubcontracts: selectedRows,
    });

  const { data: articlesQueryResult, isLoading: isLoadingArticles } =
    useGetArticlesQuery({});

  const articles = articlesQueryResult?.data || [];

  // Add include columns to filter out project
  const { options } = useMUIDatTableOptions({
    pagination: count > 10,
    filter: false,
    filterOptions: {
      isRowSelectable: (dataIndex: number) => {
        const { customerStatus, status, subcontractType } =
          subcontracts[dataIndex];
        return (
          ["upcoming", "ongoing", "partiallyinvoiced"].includes(status) &&
          customerStatus !== "declined" &&
          !isExportSubContractsLoading &&
          !isMarkSubcontractsAsInvoicedLoading &&
          subcontractType !== "rental"
        );
      },
      selectableRows: "multiple",
      selectFunction: (_, current: any) => {
        const newListIndex = current.map((c: any) => c.dataIndex);
        const newList = current.map((c: any) => subcontracts[c.dataIndex]);
        setSelectedRowsIndex(newListIndex);
        setSelectedRows(newList);
      },
      selectToolbarPlacement: "none",
      rowsSelected: selectedRowsIndex,
    },
    subtableFunction: (rowData: any, rowMeta: any) => {
      const subcontract = subcontracts[rowMeta.dataIndex];
      const costs = subcontract.costs as CostWithPermissions[];
      return (
        <SubTableContainer>
          <InvoiceRowsSubtable
            title={subcontract?.subcontractType !== "rental" ? "Incomes" : ""}
            titlePosition="top"
            currencyCode="SEK"
            invoiceRows={subcontract.incomes}
            useColumns={[
              "itemDescription",
              "quantity",
              "pricePerUnit",
              "discount",
              "totalExcludingVAT",
              "percentVAT",
              "total",
              "invoiceDate",
            ]}
          />
          <Box paddingY={1} />
          {subcontract?.subcontractType !== "rental" ? (
            <CostsSubtable
              articles={articles}
              subCosts={costs}
              title={"Costs"}
              titlePosition="top"
            />
          ) : (
            <></>
          )}
        </SubTableContainer>
      );
    },
  });

  const { structure } = useFormTable<
    SubcontractWithCostAndIncomes,
    GetStructureExtraProps
  >({
    extraProps: { location },
    getStructure,
  });

  let columns: MUIDataTableColumnDef[] = structure.items.map((item) =>
    BuilderColumnOptionsSingle<SubcontractWithCostAndIncomes>({
      data: subcontracts,
      item,
    }),
  );

  return (
    <Page
      label={"Subcontract list"}
      breadcrumbs={[
        { label: "Contracts", link: "/app/contracts" },
        {
          label: `Contract${escape(` ${contract?.projectExternalId || ""}`)}`,
          link: `/app/contracts/${contractId}`,
        },
        { label: "Subcontracts" },
      ]}
      isLoading={
        isLoadingContract ||
        isLoadingArticles ||
        isLoading ||
        isLoadingPlannedInvoices ||
        isLoadingTermsOfPayments
      }
      boxRight={
        <ButtonGroup>
          {user.isSuperAdmin && (
            <ButtonPrimary
              disabled={
                selectedRows.length === 0 || isMarkSubcontractsAsInvoicedLoading
              }
              onClick={async () => {
                await onMarkSubcontractsAsInvoiced();
                setSelectedRowsIndex([]);
              }}
            >
              {isMarkSubcontractsAsInvoicedLoading && (
                <CircularProgress
                  color="inherit"
                  size={24}
                  sx={{ marginRight: "var(--space-8px)" }}
                />
              )}
              {t("Mark as invoiced")}
            </ButtonPrimary>
          )}
          <ButtonPrimary
            key="invoice-subcontracts-button"
            disabled={selectedRows.length === 0 || isExportSubContractsLoading}
            onClick={async () => {
              await onExportSubContracts();
              setSelectedRowsIndex([]);
            }}
          >
            {isExportSubContractsLoading && (
              <CircularProgress
                color="inherit"
                size={24}
                sx={{ marginRight: "var(--space-8px)" }}
              />
            )}
            {t("Invoice subcontracts")}
          </ButtonPrimary>
        </ButtonGroup>
      }
    >
      <MuiDataTableWrapper
        data={subcontracts}
        columns={columns}
        isFetching={isLoading}
        options={options}
        title=""
        tableType="main-with-filter"
        noBackground={true}
      />
    </Page>
  );
};

export default SubcontractsTable;
