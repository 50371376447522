import { Link as RouterLink } from "react-router-dom";
import { Box, IconButton, InputBase, Link, Toolbar } from "@mui/material";
import { Menu as MenuIcon, Search as SearchIcon } from "@mui/icons-material";
import {
  SidebarState,
  updateSidebarState,
} from "src/redux/slices/sidebarState";
import { ReturnUser } from "src/accurasee-backend-types/app/user/user.types";
import { RootState } from "src/redux/reducers";
import { useDispatch, useSelector } from "react-redux";
import AppBar from "./AppBar";
import HeaderCompanies from "./HeaderCompanies";
import HeaderSettings from "./HeaderSettings";
import useHeaderHiddenComponents from "src/context/hooks/useHeaderHiddenComponents";
import useStyles from "./HeaderStyles";
import useTranslation from "src/hooks/useTranslationWrapper";
import WarningContainer from "src/components/Basic/Mixed/Warning/WarningContainer";

type HeaderTProps = {
  companies: { displayName: any; id: string }[];
  isSuperAdmin: boolean;
  user: ReturnUser;
};

export default function Header({
  companies,
  isSuperAdmin,
  user,
}: HeaderTProps) {
  const [t] = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();

  const headerHiddenComponents = useHeaderHiddenComponents();
  const headerComponentStatus = headerHiddenComponents.reduce(
    (res, currentValue) => ({
      ...res,
      [currentValue.name]: currentValue.status,
    }),
    {},
  );

  // global
  const sidebarState = useSelector<RootState, SidebarState>(
    ({ sidebarState }) => sidebarState,
  );
  const { isSidebarOpened } = sidebarState;

  return (
    <AppBar position="fixed" open={isSidebarOpened} className={classes.appBar}>
      <Toolbar className={classes.toolbar}>
        <Box className={classes.header}>
          <IconButton
            id="header-sandwich-button"
            color="primary"
            aria-label="open drawer"
            onClick={() =>
              dispatch(
                updateSidebarState({
                  ...sidebarState,
                  isSidebarOpened: !isSidebarOpened,
                }),
              )
            }
            edge="start"
            size="large"
            sx={{
              transition: "all 0.2s",
              "&:hover": {
                backgroundColor: "var(--green-10)",
              },
            }}
          >
            <MenuIcon />
          </IconButton>
          <Link
            component={RouterLink}
            to="/app/dashboard"
            sx={{
              height: "32px",
              padding: "var(--space-4px) var(--space-8px)",
              borderRadius: "8px",
              transition: "all 0.2s",
              "&:hover": {
                backgroundColor: "var(--green-10)",
              },
            }}
          >
            <img src="/Logo.png" alt="accurasee-logo" height={24} />
          </Link>
          <Box sx={{ flexGrow: 1 }} />
          {headerComponentStatus["Search"] && (
            <div className={classes.search}>
              <Box component="div" className={classes.searchIconWrapper}>
                <SearchIcon classes={{ root: classes.headerIcon }} />
              </Box>
              <InputBase
                id="header-search-input"
                className={classes.styledInputBase}
                color="secondary"
                placeholder={t("Search")}
                inputProps={{ "aria-label": "search" }}
              />
            </div>
          )}
          {headerComponentStatus["Help"] && (
            <Link className={classes.styledHelp}>{t("Help")}</Link>
          )}

          {/* Hide Notifications temporarily since it shows incorrect info - FLOW-1261 */}
          {/* {headerComponentStatus["Notifications"] && (
          <HeaderNotifications />
        )} */}

          {companies && (
            <HeaderCompanies
              activeCompanyId={user?.activeCompanyId}
              companies={companies}
            />
          )}
          <HeaderSettings isSuperAdmin={isSuperAdmin} user={user} />
        </Box>
        <WarningContainer />
      </Toolbar>
    </AppBar>
  );
}
