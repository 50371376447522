/**
 *Created by Mikael Lindahl on 2023-12-06
 */

import { StructureItemContainer } from "../components/Builders/Container/CommonBuilderContainerTypes";
import { StructureItemTable } from "../components/Builders/Table/CommonBuilderTableTypes";

const validNumber = <DataType = any>(
  value: any,
  itemComponentType?:
    | StructureItemContainer<DataType>["type"]
    | StructureItemTable<DataType>["type"],
) => {
  let isValidNumber = true;

  if (
    value !== undefined &&
    itemComponentType &&
    ["number_input_currency"].includes(itemComponentType)
  ) {
    isValidNumber = !isNaN(Number(value));
  }

  return isValidNumber;
};

export default validNumber;
