/**
 *Created by Mikael Lindahl on 2024-05-23
 */
import { AuthenticationProvider } from "@microsoft/microsoft-graph-client";
import { PublicClientApplication, SilentRequest } from "@azure/msal-browser";
import { IAuthenticateCommand } from "../../../accurasee-backend-types/app/general/microsoft_drive/microsoft_drive.types";

class MicrosoftAuthenticationProvider implements AuthenticationProvider {
  token: any;

  constructor(private app: PublicClientApplication) {}

  public getAccessToken(): Promise<string> {
    return this.getTokenWithScopes([
      "Files.Read.All",
      "Files.ReadWrite.All",
      "Sites.Read.All",
      "Sites.ReadWrite.All",
      "Mail.Send",
    ]);
  }

  public combine(...paths: string[]) {
    return paths
      .map((path) => path.replace(/^[\\|/]/, "").replace(/[\\|/]$/, ""))
      .join("/")
      .replace(/\\/g, "/");
  }

  getToken(command: IAuthenticateCommand): Promise<string> {
    return this.getTokenWithScopes([
      `${this.combine(command.resource, ".default")}`,
    ]);
  }

  getTokenWithScopes(
    scopes: string[],
    additionalAuthParams?: Omit<SilentRequest, "scopes">,
  ): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      let accessToken = "";
      const authParams = { scopes, ...additionalAuthParams };

      // see if we have already the idtoken saved
      this.app
        .acquireTokenSilent(authParams!)
        .then((resp) => {
          this.token = resp;
          accessToken = resp.accessToken;
          resolve(accessToken);
        })
        .catch((e) => {
          // per examples we fall back to popup
          this.app.loginPopup(authParams!).then((resp) => {
            this.app.setActiveAccount(resp.account);
            if (resp.idToken) {
              this.app
                .acquireTokenSilent(authParams!)
                .then((resp) => {
                  this.token = resp;
                  accessToken = resp.accessToken;
                  resolve(accessToken);
                })
                .catch((e) => {
                  reject(e);
                });
            } else {
              reject(e);
            }
          });
        });
    });
  }
}

export default MicrosoftAuthenticationProvider;
