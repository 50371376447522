import areArraysSame from "src/utils/areArraySame";
import BuilderColumnOptionsSingle from "src/components/Builders/Table/BuilderColumnOptionsSingle";
import MuiDataTableWrapper from "src/components/Basic/Mixed/Tables/MuiDataTableWrapper";
import SubcontractOfferTable from "./SubcontractOffers";
import SubTableContainer from "src/components/Basic/Mixed/Tables/SubTableContainer";
import useFormTable from "src/hooks/useFormTable";
import { ContractOffer } from "src/accurasee-backend-types/app/contract_offer/contract_offer.types";
import { MUIDataTableColumnDef } from "mui-datatables";
import { useGetCompanyUsersQuery } from "src/redux/services/UserService";
import { useGetCustomersQuery } from "src/redux/services/CustomerService";
import { useLocation } from "react-router-dom";
import { useState } from "react";
import useMUIDataTableOptionsServerSide, {
  UseMUIDataTableOptionsWithFiltersProps,
  UseQueryHook,
} from "src/hooks/useMUIDataTableOptionsServerSide";
import getStructure, {
  GetStructureExtraProps,
} from "./ContractOffersStructure";

const ContractOffers = ({
  familyId,
  query,
}: {
  familyId?: string | null;
  query: UseQueryHook<ContractOffer>;
}) => {
  const location = useLocation();
  const isActiveTab = location.pathname?.includes("/active");

  const [activeRowsExpanded, setActiveRowsExpanded] = useState<number[]>(
    familyId && isActiveTab ? [0] : [],
  );
  const [archivedRowsExpanded, setArchivedRowsExpanded] = useState<number[]>(
    familyId && !isActiveTab ? [0] : [],
  );

  const { data: customersRes, isLoading: isLoadingCustomers } =
    useGetCustomersQuery(undefined);
  const customers = customersRes?.data;
  const { data: usersResponse, isLoading: isLoadingUsers } =
    useGetCompanyUsersQuery(undefined);
  const users = usersResponse?.data || [];

  const filterInit: UseMUIDataTableOptionsWithFiltersProps["filterInit"] = {
    base: {
      pageSize: 15,
      sort: "desc",
      sortBy: "projectExternalId",
    },
    ...(familyId ? { match: { familyId } } : {}),
  };

  const {
    options,
    data: contractOffers,
    isLoading: isLoadingContractOffers,
  } = useMUIDataTableOptionsServerSide<ContractOffer>({
    dataName: "contract_offers",
    defaultPaginationOptions: [10, 15, 50],
    filterInit,
    triggerChangeOnFilterInit: ["familyId"],
    // Make the row expanded
    onRowExpansionChange: (currentRowsExpanded, allRowsExpanded) => {
      const dataIndices = allRowsExpanded.map((d) => d.dataIndex);
      if (isActiveTab) {
        if (!areArraysSame(activeRowsExpanded, dataIndices)) {
          setActiveRowsExpanded(dataIndices);
        }
      } else {
        if (!areArraysSame(archivedRowsExpanded, dataIndices)) {
          setArchivedRowsExpanded(dataIndices);
        }
      }
    },
    rowsExpanded: isActiveTab ? activeRowsExpanded : archivedRowsExpanded,
    isRowExpandable: (dataIndex: number) => {
      const contractOffer: ContractOffer = contractOffers[dataIndex];
      return !!contractOffer?.hasChildren;
    },
    subTableFunction: (rowData: any, rowMeta: any) => {
      const contractOffer = contractOffers[rowMeta.dataIndex];
      return (
        <SubTableContainer>
          <SubcontractOfferTable contractOffer={contractOffer} />
        </SubTableContainer>
      );
    },
    useGetDataQuery: query,
  });

  const grouping = contractOffers?.length
    ? contractOffers[0].groupingAll
    : undefined;

  const { structure } = useFormTable<ContractOffer, GetStructureExtraProps>({
    extraProps: {
      customers,
      grouping,
      users,
    },
    initialFormData: contractOffers,
    getStructure,
  });

  const columns: MUIDataTableColumnDef[] = structure.items.map((item) =>
    BuilderColumnOptionsSingle({
      data: contractOffers,
      disabled: true,
      item,
    }),
  );

  return (
    <MuiDataTableWrapper
      data={contractOffers}
      columns={columns}
      isFetching={
        isLoadingContractOffers || isLoadingCustomers || isLoadingUsers
      }
      options={options}
      tableType="main-with-filter"
      noBackground
    />
  );
};
export default ContractOffers;
