/**
 *Created by Mikael Lindahl on 2023-03-19
 */

import { Box, SxProps } from "@mui/material";
import { Outlet } from "react-router-dom";
import { Theme } from "@mui/material/styles";
import TabContext, { TabContextProps } from "@mui/lab/TabContext";

type CardSectionContainerTProps = {
  children?: JSX.Element | JSX.Element[];
  value: TabContextProps["value"];
  sx?: SxProps<Theme> | undefined;
};

const TabContainer = ({ children, value, sx }: CardSectionContainerTProps) => {
  return (
    <Box
      sx={{
        position: "relative",
        marginTop: "2rem",
        ...sx,
      }}
    >
      <TabContext value={value}>
        {children}
        <Outlet />
      </TabContext>
    </Box>
  );
};

export default TabContainer;
