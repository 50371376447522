/**
 *Created by Mikael Lindahl on 2023-11-28
 */

import { UserFormTableGetStructureOptional } from "src/hooks/useFormTable";
import {
  SupplierInvoice,
  SupplierInvoiceGetList,
} from "src/accurasee-backend-types/app/supplierinvoice/supplierinvoice.types";
import Constants from "../../../../constants/Constants";
import {
  getCustomMinMaxFilterListOptions,
  getCustomMinMaxfilterOptions,
} from "../../Containers/Filterhelper";
import fileDownload from "js-file-download";
import { OptionsObject, SnackbarKey, SnackbarMessage } from "notistack";
import { getAttachment } from "../../../../redux/services/AttachmentService";

interface GetStructureExtraProps {
  contractId?: string;
  fileDownloading: boolean;
  enqueueSnackbar: (
    message: SnackbarMessage,
    options?: OptionsObject | undefined,
  ) => SnackbarKey;
  setFileDownloading: (fileDownloading: boolean) => void;
}

export const filterSupplierInvoiceRows = ({
  contractId,
  supplierInvoice,
}: {
  contractId?: string;
  supplierInvoice: SupplierInvoice;
}) => {
  const supplierInvoiceRows = supplierInvoice.invoiceRows;
  return supplierInvoiceRows
    .filter((row) => {
      if (!row?.accountNumber || row.code === "VAT") return true;
      else
        return (
          (row?.accountNumber >= 4000 && row?.accountNumber <= 8899) ||
          (row?.accountNumber >= 40000 && row?.accountNumber <= 88999)
        );
    })
    .filter((row) => {
      // If contractId is undefined, return all rows, else returns rows that match contractId
      return contractId === undefined
        ? true
        : String(row.contractId) === contractId;
    });
};

export const getStructure: UserFormTableGetStructureOptional<
  SupplierInvoiceGetList,
  GetStructureExtraProps
> = (props) => {
  const t = props?.t ? props.t : (s: string) => s;

  return {
    items: [
      {
        dataName: "supplierInvoiceNumber",
        headerLabel: "Invoice number",
        type: "text",
        sort: true,
      },
      {
        type: "text",
        dataName: "projectExternalId",
        headerLabel: "Contract",
        sort: true,
        getValue: ({ data, rowIndex }) => {
          const invoice = data[rowIndex];
          if (invoice?.contractIds.length > 1) {
            return t("Assigned to multiple contracts");
          }
          if (
            invoice.projectNames.length === 1 &&
            invoice.projectExternalIds.length === 1
          ) {
            const { projectNames, projectExternalIds } = invoice;
            const name = projectExternalIds[0] + " - " + projectNames[0];
            const maxLength = Constants.MAX_WIDTH_NUMBER_OF_CHARS_IN_TABLE;
            return name.length > maxLength
              ? name.slice(0, maxLength) + "..."
              : name;
          } else return t("Not assigned to contract");
        },
      },
      {
        type: "text",
        dataName: "supplierName",
        headerLabel: "Supplier",
        sort: true,
        getValue: ({ data, rowIndex }) => {
          const name = data[rowIndex]?.supplierName || "";
          const maxLength = Constants.MAX_WIDTH_NUMBER_OF_CHARS_IN_TABLE;
          return name.length > maxLength
            ? name.slice(0, maxLength) + "..."
            : name;
        },
      },
      {
        type: "text",
        dataName: "name",
        headerLabel: "Invoice Plan",
        sort: true,
      },
      {
        type: "date",
        dataName: "dueDate",
        sort: true,
        headerLabel: "Due date",
        filter: true,
        filterType: "custom",
        customFilterListOptions: getCustomMinMaxFilterListOptions(
          t("Due date"),
        ),
        filterOptions: getCustomMinMaxfilterOptions(t("Due date"), "date"),
      },
      {
        type: "date",
        dataName: "invoiceDate",
        sort: true,
        headerLabel: "Invoice date",
        filter: true,
        filterType: "custom",
        customFilterListOptions: getCustomMinMaxFilterListOptions(
          t("Invoice date"),
        ),
        filterOptions: getCustomMinMaxfilterOptions(t("Invoice date"), "date"),
      },
      {
        type: "badge",
        dataName: "status",
        headerLabel: "Status",
        sort: true,
        filter: true,
        filterOptions: {
          names: [
            "Upcoming",
            "Cancelled",
            "Unpaid",
            "Unpaid overdue",
            "Fully paid",
          ],
        },
        alignColumnContent: "center",
      },
      {
        type: "text_currency",
        dataName: "totalExcludingVAT",
        headerLabel: "Tot Excl VAT",
        sort: true,
        getValue: ({ data, rowIndex }) => {
          const excludingVAT = filterSupplierInvoiceRows({
            contractId: props?.extraProps?.contractId,
            supplierInvoice: data[rowIndex],
          }).reduce(
            (accumulator, currentRow) =>
              currentRow.code !== "VAT"
                ? accumulator + currentRow.total
                : accumulator,
            0,
          );
          return excludingVAT;
        },
        filter: true,
        filterType: "custom",
        customFilterListOptions: getCustomMinMaxFilterListOptions(
          t("Total excl. VAT"),
        ),
        filterOptions: getCustomMinMaxfilterOptions(
          t("Total excl. VAT"),
          "number",
        ),
        alignColumnContent: "end",
      },
      {
        type: "text_currency",
        dataName: "totalVAT",
        headerLabel: "VAT",
        sort: true,
        getValue: ({ data, rowIndex }) => {
          const VAT = filterSupplierInvoiceRows({
            contractId: props?.extraProps?.contractId,
            supplierInvoice: data[rowIndex],
          }).reduce(
            (accumulator, currentRow) =>
              currentRow.code === "VAT"
                ? accumulator + currentRow.total
                : accumulator,
            0,
          );
          return VAT;
        },
        alignColumnContent: "end",
      },
      {
        type: "text_currency",
        dataName: "total",
        headerLabel: "Total",
        sort: true,
        getValue: ({ data, rowIndex }) => {
          const excludingVAT = filterSupplierInvoiceRows({
            contractId: props?.extraProps?.contractId,
            supplierInvoice: data[rowIndex],
          }).reduce(
            (accumulator, currentRow) => accumulator + currentRow.total,
            0,
          );
          return excludingVAT;
        },
        filter: true,
        filterType: "custom",
        customFilterListOptions: getCustomMinMaxFilterListOptions(
          t("Total amount"),
        ),
        filterOptions: getCustomMinMaxfilterOptions(
          t("Total amount"),
          "number",
        ),
        alignColumnContent: "end",
      },
      {
        type: "icon_button",
        dataName: "files",
        headerLabel: "File",
        iconBadge: ({ data, rowIndex }) =>
          data[rowIndex].attachments.length || undefined,
        iconType: "download",
        showCellWhen: ({ data, rowIndex }) =>
          data[rowIndex].attachments.length > 0,
        onClick: async ({ data, rowIndex }) => {
          try {
            props?.extraProps?.setFileDownloading(true);

            for (const attachment of data[rowIndex].attachments) {
              const fileRes = await getAttachment(attachment._id);

              // Get the 'content-disposition' header
              const contentDisposition = fileRes.headers["content-disposition"];

              // Extract the filename from the 'content-disposition' header
              const filename = contentDisposition
                .split("filename=")[1]
                .replace(/"/g, "");

              // Get the 'content-type' header
              const mimeType = fileRes.headers["content-type"];

              fileDownload(fileRes.data, filename, mimeType);
            }
          } catch (error) {
            console.error(error);
            props?.extraProps?.enqueueSnackbar(t("Could not download pdf"), {
              variant: "error",
            });
          } finally {
            props?.extraProps?.setFileDownloading(false);
          }
        },
        disabled: props?.extraProps?.fileDownloading,
      },
    ],
  };
};
