import { Article } from "src/accurasee-backend-types/app/article/article.types";
import {
  Cost,
  CostTableReturn,
} from "src/accurasee-backend-types/app/cost/cost.types";
import { StructureItemTable } from "src/components/Builders/Table/CommonBuilderTableTypes";
import { ExtentedCost } from "src/components/Complex/Modals/ForwardCostsModal";
import { TFunction } from "src/hooks/useTranslationWrapper";
import DownloadCostFile from "./DownloadCostFiles";

export function getCommonCostTableColumns(
  articles: Article[],
  fileDownloading: any,
  setFileDownloading: (data: String[]) => void,
  t: TFunction,
) {
  return [
    {
      type: "text",
      dataName: "_id",
      headerLabel: "#",
      filter: false,
      getValue: ({ rowIndex }) => rowIndex + 1,
    },
    {
      type: "text",
      dataName: "name",
      headerLabel: "DESCRIPTION",
    },
    // {
    //   type: "date",
    //   dataName: "transactionDate",
    //   headerLabel: "Transaction date",
    // },
    // {
    //   type: "text",
    //   dataName: "accountName",
    //   headerLabel: "NUMBER SERIES",
    // },
    {
      type: "text",
      dataName: "articleId",
      headerLabel: "ARTICLE",
      getValue: ({ data, rowIndex }) => {
        const articleId = data[rowIndex]?.articleId || "";
        const article = articles.find((a) => a._id === articleId);
        return article?.name;
      },
      showColumnWhen: ({ data }) => data.some((row) => row?.articleId),
    },
    {
      type: "text",
      dataName: "costType",
      headerLabel: "TYPE",
      filter: true,
      getValue: ({ data, rowIndex }) => {
        return t!(data[rowIndex]?.costType || "");
      },
    },
    {
      type: "icon_button",
      dataName: "files",
      headerLabel: "File",
      iconType: "download",
      showCellWhen: ({ data, rowIndex }) => {
        const files = data[rowIndex]?.files || [];
        return files.length > 0;
      },
      showColumnWhen: ({ data }) => {
        return data.some((cost) => !!cost.files);
      },
      onClick: async ({ data, rowIndex }) => {
        const files = data[rowIndex]?.files || [];
        for (let file of files) {
          await DownloadCostFile({
            file,
            supplierInvoiceId: String(data[rowIndex]?.supplierInvoiceId),
            setFileDownloading,
            fileDownloading,
          });
        }
      },
      disabled: ({ data, rowIndex }) => {
        const files = data[rowIndex]?.files || [];
        const anyIsDownLoading = files.some(
          (file) => fileDownloading[String(file._id)],
        );
        return anyIsDownLoading;
      },
    },
  ] as StructureItemTable<ExtentedCost | CostTableReturn | Cost>[];
}
