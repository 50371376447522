import BuilderColumnOptionsBase from "./BuilderColumnOptionsBase";
import useTranslation from "../../../hooks/useTranslationWrapper";
import { BuilderColumnOptionsBaseTProps } from "./BuilderColumnOptionsBase";
import { MUIDataTableColumnDef } from "mui-datatables";

const BuilderColumnOptionsSingle: <DataType = any>(
  props: Omit<BuilderColumnOptionsBaseTProps<DataType>, "t">,
) => MUIDataTableColumnDef = (props) => {
  const [t] = useTranslation();

  return BuilderColumnOptionsBase({ ...props, t });
};

export default BuilderColumnOptionsSingle;
